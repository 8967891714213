import {interval, Observable, of, race, throwError, timer} from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  flatMap,
  map,
  skipWhile,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import {activateVoIP, getGbsVoip, getVoIpPIStatus, getVoipServiceActivationCode, updateGbsVoip} from '../../services';
import {ActivationStatus} from '../../../../components/util/ActivationStatus';
import {
  getGbsVoIPAction,
  getVoipSacAction,
  restartAction,
  startGbsVoIPActivationAction,
  updateVoIPStatusAction,
} from '../../actions';
import {persistActivationField} from '../../activationHelpers';

import {FlowState} from '../../../../definitions/types';
import env from '../../../../utils/env';
import {combineEpics, ofType, StateObservable} from 'redux-observable';
import {Action} from 'redux';
import {generateMessage} from '../../../../shared-components/ErrorMessage';
import {UpdateGBSVoipParams} from '../../actionInterfaces';
import {handleActivationError} from '../appUtility/appUtilityEpic';
export const fetchVoIPActivationStatus = (delayInMs: number, voipPi: string, isActivating: boolean = true) => {
  return race(
    timer(0, Number(env.pollingInterval)).pipe(
      delay(delayInMs),
      flatMap(() => getVoIpPIStatus(voipPi)),
      map((response: any) => {
        if (response.response.status.toUpperCase() === ActivationStatus.ACTIVE) {
          persistActivationField('applicationFlowState', FlowState.ActivationFinished);
        }
        return {result: response.response, params: {}};
      }),
      skipWhile((response: any) => {
        return ![
          ActivationStatus.ACTIVE,
          ActivationStatus.ACTIVATING_ERROR,
          ...(!isActivating ? [ActivationStatus.ACTIVATING] : []), //Do not add ACTIVATING state if it is already activating
        ].includes(response.result.status.toUpperCase());
      }),
      catchError((err) => throwError({error: err, friendlyMessage: 'Could not activate', intlId: 'error.activation'})),
      take(1)
    ),
    interval(Number(env.qoiTimeout)).pipe(
      flatMap((err) => throwError({error: err, friendlyMessage: 'Timeout', intlId: 'error.qoi.timeout'}))
    )
  );
};
export const updateVoIPStatusEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(updateVoIPStatusAction.started.match),
    switchMap((action) => {
      return pollForVoipActivationStatus().pipe(
        map((response: any) => {
          persistActivationField('alianzaAgreementUrl', response.response.activationUrl);
          persistActivationField('voipPi', response.response.voipPI);
          //persistActivationField('applicationFlowState', FlowState.VoIPActivated);

          return updateVoIPStatusAction.done({result: response.response, params: {}});
        }),
        takeUntil(action$.pipe(ofType(restartAction))),
        catchError((thrownError) => {
          return of(
            updateVoIPStatusAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError),
              params: {},
            })
          );
        })
      );
    })
  );
export const getGbsVoIPStatusEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(getGbsVoIPAction.started.match),
    switchMap((action) => {
      return getGbsVoip().pipe(
        map((response: any) => {
          return getGbsVoIPAction.done({result: response.response, params: {}});
        }),
        catchError((thrownError) => {
          return of(
            getGbsVoIPAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError),
              params: {},
            })
          );
        })
      );
    })
  );

export const updateGbsVoIPEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(startGbsVoIPActivationAction.started.match),
    concatMap((action) => {
      const {macAddress, voipPi, isActivating} = action.payload as UpdateGBSVoipParams;
      return !isActivating
        ? updateGbsVoip(macAddress, voipPi).pipe(
            map((response: any) => {
              const status = response.response.status as ActivationStatus;
              return startGbsVoIPActivationAction.done({result: {status}, params: {macAddress, voipPi}});
            }),
            catchError((thrownError) => {
              return of(
                startGbsVoIPActivationAction.failed({
                  error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError),
                  params: {macAddress, voipPi},
                })
              );
            })
          )
        : checkGBSVoipStatus(voipPi, macAddress);
    })
  );
function pollForVoipActivationStatus() {
  return race(
    timer(0, Number(env.voipPollingDelay)).pipe(
      switchMap(() =>
        activateVoIP().pipe(
          catchError((err) => {
            if (!window.navigator.onLine) {
              return of({response: {success: false}}); //The modem is probably rebooting
            } else return handleActivationError(err);
          })
        )
      ),
      skipWhile((response: any) => !response.response.success),
      take(1),
      map((response) => response)
    ),
    interval(Number(env.modemActivationTimeout)).pipe(
      flatMap((err) =>
        throwError({error: err, friendlyMessage: 'Modem Activation Timeout', intlId: 'error.activation.timeout'})
      )
    )
  );
}
const checkGBSVoipStatus = (voipPi: string, macAddress: string) => {
  return fetchVoIPActivationStatus(0, voipPi, true).pipe(
    map((response: any) => {
      const status = response.result.status as ActivationStatus;
      return startGbsVoIPActivationAction.done({result: {status}, params: {macAddress, voipPi}});
    }),
    catchError((thrownError) => {
      return of(
        startGbsVoIPActivationAction.failed({
          error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError),
          params: {macAddress, voipPi},
        })
      );
    })
  );
};

export const getVoIPSACEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(getVoipSacAction.started.match),
    switchMap((action) => {
      return getVoipServiceActivationCode().pipe(
        map((response: any) => {
          return getVoipSacAction.done({result: response.response, params: {}});
        }),
        catchError((thrownError) => {
          return of(
            getVoipSacAction.failed({
              error: generateMessage(
                'Could not retrieve the Voice Service Activation Code',
                'voip.service.activation.code.error',
                thrownError
              ),
              params: {},
            })
          );
        })
      );
    })
  );

export const VoipEpic = combineEpics(getVoIPSACEpic, updateGbsVoIPEpic, updateVoIPStatusEpic, getGbsVoIPStatusEpic);
