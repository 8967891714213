import {connect} from 'react-redux';
import {addLocaleData, IntlProvider} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import esLocaleData from 'react-intl/locale-data/es';
import ptLocaleData from 'react-intl/locale-data/pt';
import translations from '../../i18n/locales';
import {IRootState} from '../../store';

addLocaleData(enLocaleData);
addLocaleData(esLocaleData);
addLocaleData(ptLocaleData);

export default connect((state: IRootState) => {
  const {locale, defaultLocale} = state.system;
  return {
    defaultLocale,
    locale,
    key: locale,
    messages: translations[locale],
  };
})(IntlProvider as any);
