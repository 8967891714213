import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from '../../../store';
import ValidateContactPage from './ValidateContactPage';
import {setApplicationFlowState} from '../../../store/activation/activationHelpers';
import Error from '../../common/Error';
import {CustomerDetails, FlowState, InstallationTypesCodes} from '../../../definitions/types';

const ValidateContactPageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    setContactValidated: (flowState) => {
      return dispatch(setApplicationFlowState(flowState) as any);
    },
  };
  const stateProps = useSelector((state: IRootState) => {
    return {
      party: state.activation.party as CustomerDetails,
      isBusy: state.activation.isBusy,
      error: state.activation.error,
      country: state.system.country,
      installationType: state.system.installationType as InstallationTypesCodes,
      installationSubtype: state.system.installationSubtype,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };

  const proceedToNextStep = () => {
    props.setContactValidated(FlowState.ContactValidated);
  };
  return props.error ? (
    <Error message={props.error} enableReload={true} />
  ) : (
    <ValidateContactPage {...props} proceedToNextStep={proceedToNextStep} />
  );
};

export default ValidateContactPageContainer;
