import {concat, Observable, of} from 'rxjs';
import {Action} from 'redux';
import {combineEpics, StateObservable} from 'redux-observable';
import {catchError, filter, flatMap, switchMap} from 'rxjs/operators';
import {trackEquipmentAction} from '../../actions';
import {setApplicationFlowState} from '../../activationHelpers';
import {TrackedEquipmentRequest} from '../../actionInterfaces';
import {EquipmentProductKind} from '../../../../components/util/EquipmentUtility';
import {trackEquipment} from '../../services';
import {FlowState} from '../../../../definitions/types';
import {generateErrorDescription, generateMessage} from '../../../../shared-components/ErrorMessage';
import {userSnackbarAction} from '../../../../utils/CommonActions';

export const trackEquipmentEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(trackEquipmentAction.started.match),
    switchMap((action) => {
      const {posSerialNumber, upsSerialNumber} = action.payload;
      const trackEquipments: TrackedEquipmentRequest[] = [
        {serialNumber: posSerialNumber, productKind: EquipmentProductKind.POS},
        {serialNumber: upsSerialNumber, productKind: EquipmentProductKind.UPS},
      ];
      return trackEquipment(trackEquipments).pipe(
        flatMap(() =>
          concat(
            of(
              trackEquipmentAction.done({
                result: {},
                params: action.payload,
              })
            ),
            of(setApplicationFlowState(FlowState.EquipmentTrackingSubmitted))
          )
        ),
        catchError((error) =>
          concat(
            of(
              trackEquipmentAction.failed({
                error: generateMessage('Could not submit equipment for tracking!', 'error.track.equipment', error),
                params: action.payload,
              })
            ),
            of(
              userSnackbarAction(
                {defaultMessage: 'Could not submit equipment for tracking', id: 'error.track.equipment'},
                {variant: 'error'},
                generateErrorDescription(error)
              )
            )
          )
        )
      );
    })
  );

export const EquipmentTrackingEpic = combineEpics(trackEquipmentEpic);
