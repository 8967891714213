import React from 'react';
import GenericSuccessComponent, {GenericSuccessComponentProps} from '../../common/GenericSuccessComponent';
import {PageContainer} from '../../common/AppLayout';
import {Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';

interface Props {
  finish: () => void;
}
const SwapEquipmentSuccessPage: React.FC<Props> = ({finish}) => {
  const successProps: GenericSuccessComponentProps = {
    descriptionMessage: {
      id: 'swap.equipment.success.description',
      defaultMessage: 'You have successfully replaced the equipment',
    },
    buttonActionMessage: {
      defaultMessage: 'Finish',
      id: 'button.finish',
    },
    onContinue: finish,
  };
  return (
    <PageContainer data-test-el="swap-equipment-success-page">
      <Title data-test-el="swap-equipment-success-heading">
        <FormattedMessage id="swap.equipment.success.title" defaultMessage={'Equipment replacement'} />
      </Title>
      <GenericSuccessComponent {...successProps} />
    </PageContainer>
  );
};

export default SwapEquipmentSuccessPage;
