import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`

	@font-face {
        /* Legacy iOS */
        font-style: normal;
        font-weight: normal;
        text-rendering: optimizeLegibility;
    }

  /****************************************************
  * Core page styles
  */

  * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    outline: none !important;
    box-sizing: border-box;
  }

  input:-webkit-autofill {
   -webkit-background-clip: text;
  }

  html {
    font-size: ${({theme}: any) => theme.fontSizes[2]}px;
    background-color: ${({theme}) => theme.colors.background};
    font-family: ${({theme}) => theme.fonts.body};
  }

`;

export default GlobalStyle;
