import React from 'react';
import {PageState} from '../../util/PageState';
import {PageContainer} from '../../common/AppLayout';
import {Box, Card, Progress, Text, Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import {FormattedHTMLMessage} from 'react-intl';
import GenericErrorComponent, {GenericErrorComponentProps} from '../../common/GenericErrorComponent';

export interface VoIPStatusUpdateProps {
  activationFailed: boolean;
  onRetry: () => void;
}

const generateErrorComponent = (retry: () => void) => {
  const errorProps: GenericErrorComponentProps = {
    headerDescriptionMessage: {
      id: 'voip.status.activation.error.description',
      defaultMessage: 'There seems to be a problem with the activation.<br/>Please try again.',
    },
    onRetry: retry,
  };
  return <GenericErrorComponent {...errorProps} />;
};

export const VoIPActivationPage: React.FC<VoIPStatusUpdateProps> = (props) => {
  const {onRetry, activationFailed} = props;
  let state = activationFailed ? PageState.ERROR : PageState.LOADING;
  return (
    <PageContainer data-test-el="voip-status-activation-page">
      <Title data-test-el="voip-status-activation-heading">
        <FormattedMessage id="voip.status.activation.title" defaultMessage={'Service Activation'} />
      </Title>
      {
        {
          LOADING: (
            <Card>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Text textAlign="center" mt={1} mb={2} display="block" data-test-el="installation-type-header">
                  <FormattedHTMLMessage
                    id="voip.status.activation.loading.message"
                    defaultMessage="Activating service, please wait.<br/> This may take several minutes…"
                  />
                </Text>

                <Progress mb={'230px'} mt={6} ml={'auto'} mr={'auto'} />
              </Box>
            </Card>
          ),
          ERROR: generateErrorComponent(onRetry),
        }[state]
      }
    </PageContainer>
  );
};
