import queryString from 'query-string';
import {CountryCodes, InstallationSubType} from '../../definitions/types';
import {countries} from '../../definitions/constants';
interface QueryParams {
  macAddress: string;
  serviceActivationCode: string;
  installerId: string;
  countryCode: string;
}

export const getQueryParams = (): QueryParams => {
  const queryParams = queryString.parse(window.location.search);
  return {
    macAddress: queryParams.n,
    serviceActivationCode: queryParams.customerCode,
    installerId: queryParams.installerId,
    countryCode: queryParams.countryCode,
  };
};
export const padWithZeros = (value: string, digitsCount: number) => {
  let str = '';
  while (str.length < digitsCount - value.length) {
    str = str + '0';
  }
  return str + value;
};

export const countryNeedsECA = (country: CountryCodes, installationSubtype: InstallationSubType): boolean => {
  const matchedCountryItem = countries.find((countryItem) => countryItem.code === country);
  return !!(matchedCountryItem && matchedCountryItem.needsECA.includes(installationSubtype));
};
