import es_mx from './translations/es-mx.json';
import pt_br from './translations/pt-br.json';
import en_us from './translations/en-us.json';
import * as langmap from 'langmap';

export const getLanguagesMap = () => {
  let languagesMap = langmap;
  Object.assign(languagesMap, {
    'es-GT': {
      nativeName: 'Español (Guatemala)',
      englishName: 'Spanish',
    },
  });

  return languagesMap;
};

// export all we support here
export default {en: en_us, 'es-mx': es_mx, 'pt-br': pt_br, 'es-gt': es_mx};
