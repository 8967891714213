import React from 'react';
import WifiPage from './WifiPage';
import {AccessPoint, FlowState} from '../../../definitions/types';
import {useDispatch, useSelector} from 'react-redux';
import {
  activateVciEquipmentAction,
  resetErrorAction,
  submitFiveGHzDataAction,
  submitTwoPointFourGHzDataAction,
} from '../../../store/activation/actions';
import LoaderComponent from '../../common/LoaderComponent';
import Error from '../../common/Error';
import {normaliseMac} from '../../util/regex';
import SwapWifiEquipmentPage from './swapWifiEquipmentPage';
import {EquipmentProductKind} from '../../util/EquipmentUtility';

const WifiPageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    submitTwoPointFourGHzData: (accessPoint: AccessPoint) =>
      dispatch(submitTwoPointFourGHzDataAction.started({accessPoint}) as any),
    submitFiveGHzData: (accessPoint: AccessPoint) => dispatch(submitFiveGHzDataAction.started({accessPoint}) as any),
    resetError: () => {
      dispatch(resetErrorAction());
    },
    activateVciEquipment: (
      productKind: EquipmentProductKind.NETWORK_CONTROLLER | EquipmentProductKind.ACCESS_POINT,
      accessPoint: AccessPoint
    ) => {
      dispatch(
        activateVciEquipmentAction.started({
          productKind,
          accessPoint,
        })
      );
    },
  };
  const stateProps = useSelector((state: any) => {
    return {
      flowState: state.activation.applicationFlowState,
      error: state.activation.error,
      isBusy: state.activation.isBusy,
      selectedSwapEquipment: state.activation.selectedProductKind,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };
  const isNetworkController = props.flowState === FlowState.TwoPointFourGHzSubmitted;

  const handleSubmit = (accessPoint: AccessPoint) => {
    const {serialNumber, macAddress} = accessPoint;
    const normalisedMacAddress = normaliseMac(macAddress);
    if (isNetworkController) {
      props.submitFiveGHzData({serialNumber, macAddress: normalisedMacAddress});
    } else {
      props.submitTwoPointFourGHzData({serialNumber, macAddress: normalisedMacAddress});
    }
  };
  const activateVciEquipment = (accessPoint: AccessPoint) => {
    let {serialNumber, macAddress} = accessPoint;
    macAddress = normaliseMac(macAddress);
    props.activateVciEquipment(props.selectedSwapEquipment, {serialNumber, macAddress});
  };
  const getWifiComponent = () => {
    return !!props.selectedSwapEquipment ? (
      <SwapWifiEquipmentPage onSubmit={activateVciEquipment} {...props} />
    ) : (
      <WifiPage onSubmit={handleSubmit} isFiveGHz={isNetworkController} isBusy={props.isBusy} />
    );
  };

  if (props.isBusy) {
    return <LoaderComponent />;
  }
  return props.error ? (
    <Error message={props.error} enableReload={true} onReload={props.resetError} />
  ) : (
    getWifiComponent()
  );
};

export default WifiPageContainer;
