import {
  activateHotspotAction,
  activateModemAction,
  activateModemSwapAction,
  activateQoiAction,
  activateQoiSwapAction,
  activateVciEquipmentAction,
  activateWifiAction,
  applicationFlowChangedAction,
  authorizeInstallerAction,
  validateFlowSelectionAndAuthoriseAction,
  checkContractStatusAction,
  checkHotspotActivationStatusAction,
  checkModemActivationStatusAction,
  checkQoiStatusAction,
  checkSwappedModemActivationStatusAction,
  checkWifiActivationStatusAction,
  fetchPartyAction,
  getAllEquipmentByPIAction,
  getAllResEquipmentAction,
  getAllVCIEquipmentAction,
  getContractInstanceAction,
  getGbsVoIPAction,
  getResSwapOrderStatusChangeAction,
  getVoipSacAction,
  promptForLegacyRedirectAction,
  provisionResSwapEquipmentAction,
  receiveInitActivation,
  resetErrorAction,
  resetSelectedSwapEquipmentAction,
  setInstallationTypeSelectedAction,
  setModemMacAddressAction,
  setSelectedReplacementOptionAction,
  setSelectedSwapEquipmentAction,
  startGbsVoIPActivationAction,
  submitFiveGHzDataAction,
  submitTwoPointFourGHzDataAction,
  swapTrackedEquipmentAction,
  trackEquipmentAction,
  updateVoIPStatusAction,
  validateCreditCardAction,
  validateExistingSwapEquipmentAction,
  validateSwapMacAddressAction,
} from './actions';
import {reducerWithInitialState} from 'typescript-fsa-reducers';
import {CountryCodes, FlowState, GbsVoipDetails, IActivationState} from '../../definitions/types';
import {UpdateGBSVoipParams} from './actionInterfaces';
import {ActivationStatus} from '../../components/util/ActivationStatus';
import {fetchConfigAction} from '../system/actions';

export const initialState: IActivationState = {
  isBusy: false,
  isModemOperationBusy: false,
  applicationFlowState: FlowState.NotAuthorized,
};

export default reducerWithInitialState(initialState)
  .case(
    receiveInitActivation,
    (
      state,
      {
        productInstanceStatus,
        party,
        commandId,
        qoiDetails,
        modemActivationStarted,
        installationTypeSelected,
        applicationFlowState,
        alianzaAgreementUrl,
        voIPPI,
        selectedProductKind,
        productInstanceId,
        modemMacAddress,
        orderId,
        selectedReplacementOption,
        swapMacAddressValidated,
      }
    ): IActivationState => {
      return {
        ...initialState,
        party,
        productInstanceStatus,
        commandId,
        qoiDetails,
        modemActivationStarted,
        installationTypeSelected,
        applicationFlowState,
        voipPi: voIPPI,
        alianzaAgreementUrl,
        selectedProductKind,
        productInstanceId,
        modemMacAddress,
        orderId,
        selectedReplacementOption,
        swapMacAddressValidated,
      };
    }
  )
  .case(
    setInstallationTypeSelectedAction,
    (state): IActivationState => ({
      ...state,
      installationTypeSelected: true,
    })
  )
  .case(
    resetErrorAction,
    (state): IActivationState => ({
      ...state,
      error: undefined,
    })
  )
  .case(
    setSelectedSwapEquipmentAction,
    (state, {selectedProductKind}): IActivationState => ({
      ...state,
      selectedProductKind,
    })
  )
  .case(
    setSelectedReplacementOptionAction,
    (state, selectedReplacementOption): IActivationState => ({
      ...state,
      selectedReplacementOption,
    })
  )
  .case(
    resetSelectedSwapEquipmentAction,
    (state): IActivationState => ({
      ...state,
      selectedProductKind: undefined,
    })
  )
  .case(applicationFlowChangedAction, (state, result) => ({
    ...state,
    applicationFlowState: result.flowState,
  }))

  .case(
    activateVciEquipmentAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isBusy: true,
    })
  )
  .case(
    activateVciEquipmentAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    activateVciEquipmentAction.done,
    (state): IActivationState => ({
      ...state,
      isBusy: false,
    })
  )

  .case(
    validateSwapMacAddressAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      swapMacAddressValidated: undefined,
      isBusy: true,
    })
  )
  .case(
    validateSwapMacAddressAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      swapMacAddressValidated: undefined,
      isBusy: false,
    })
  )

  .case(
    validateSwapMacAddressAction.done,
    (state, result): IActivationState => ({
      ...state,
      swapMacAddressValidated: result.result.success,
      isBusy: false,
    })
  )
  .case(
    provisionResSwapEquipmentAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isModemOperationBusy: true,
    })
  )
  .case(
    provisionResSwapEquipmentAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isModemOperationBusy: false,
    })
  )

  .case(
    provisionResSwapEquipmentAction.done,
    (state, {result: {orderId}}): IActivationState => ({
      ...state,
      orderId,
      isModemOperationBusy: false,
    })
  )
  .case(
    getResSwapOrderStatusChangeAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isModemOperationBusy: true,
    })
  )
  .case(
    getResSwapOrderStatusChangeAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isModemOperationBusy: false,
    })
  )

  .case(
    getResSwapOrderStatusChangeAction.done,
    (state): IActivationState => ({
      ...state,
      isModemOperationBusy: false,
    })
  )
  .case(
    authorizeInstallerAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isBusy: true,
    })
  )

  .case(
    authorizeInstallerAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    authorizeInstallerAction.done,
    (state, {result: {productInstanceStatus, currentCountry}}): IActivationState => ({
      ...state,
      productInstanceStatus,
      applicationFlowState: currentCountry === CountryCodes.NG ? FlowState.ContactValidated : FlowState.Authorized,
      isBusy: false,
    })
  )
  .case(
    validateFlowSelectionAndAuthoriseAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isBusy: true,
    })
  )
  .case(
    validateFlowSelectionAndAuthoriseAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    validateFlowSelectionAndAuthoriseAction.done,
    (state): IActivationState => ({
      ...state,
      isBusy: false,
    })
  )

  .case(
    setModemMacAddressAction,
    (state, {modemMacAddress}): IActivationState => ({
      ...state,
      modemMacAddress,
      error: undefined,
    })
  )
  .case(
    getAllEquipmentByPIAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isBusy: true,
    })
  )
  .case(
    getAllEquipmentByPIAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    getAllEquipmentByPIAction.done,
    (state, {result: upgradeEquipmentDetails}): IActivationState => ({
      ...state,
      upgradeEquipmentDetails,
      isBusy: false,
    })
  )
  .case(
    getVoipSacAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isBusy: true,
    })
  )
  .case(
    getVoipSacAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    getVoipSacAction.done,
    (state, {result: voipServiceActivationCode}): IActivationState => ({
      ...state,
      voipServiceActivationCode,
      isBusy: false,
    })
  )

  .case(
    getAllVCIEquipmentAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isBusy: true,
    })
  )
  .case(
    getAllVCIEquipmentAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    getAllVCIEquipmentAction.done,
    (state, {result: installedVCIEquipmentDetails}): IActivationState => ({
      ...state,
      installedVCIEquipmentDetails,
      isBusy: false,
    })
  )
  .case(
    fetchConfigAction.done,
    (state, {result: {activationUrl}}): IActivationState => ({
      ...state,
      activationUrl,
      isBusy: false,
    })
  )
  .case(
    fetchConfigAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
    })
  )
  .case(
    fetchConfigAction.failed,
    (state): IActivationState => ({
      ...state,
      isBusy: false,
    })
  )
  .case(
    validateExistingSwapEquipmentAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isBusy: true,
    })
  )
  .case(
    validateExistingSwapEquipmentAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    validateExistingSwapEquipmentAction.done,
    (state, {result: {replacementProductExists, openOrderExists}}): IActivationState => ({
      ...state,
      replacementProductExists,
      openOrderExists,
      isBusy: false,
    })
  )

  .case(
    getAllResEquipmentAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      isBusy: true,
    })
  )
  .case(
    getAllResEquipmentAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    getAllResEquipmentAction.done,
    (state, {result: {swapResReplacementOptions}}): IActivationState => ({
      ...state,
      installedResEquipmentDetails: swapResReplacementOptions,
      isBusy: false,
    })
  )
  .case(
    fetchPartyAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
    })
  )

  .case(
    fetchPartyAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    fetchPartyAction.done,
    (state, {result: {party}}): IActivationState => ({
      ...state,
      party,
      isBusy: false,
    })
  )
  .case(
    activateQoiAction.started,
    (state): IActivationState => ({
      ...state,
      isModemOperationBusy: true,
      error: undefined,
    })
  )
  .case(
    activateQoiAction.done,
    (state, {result: {commandId}}): IActivationState => ({
      ...state,
      commandId,
      isModemOperationBusy: false,
    })
  )
  .case(
    activateQoiAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isModemOperationBusy: false,
    })
  )
  .case(
    activateQoiSwapAction.started,
    (state): IActivationState => ({
      ...state,
      isModemOperationBusy: true,
      error: undefined,
    })
  )
  .case(
    activateQoiSwapAction.done,
    (state, {result: {commandId}}): IActivationState => ({
      ...state,
      commandId,
      isModemOperationBusy: false,
    })
  )
  .case(
    activateQoiSwapAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isModemOperationBusy: false,
    })
  )
  .case(
    checkQoiStatusAction.started,
    (state): IActivationState => ({
      ...state,
      isModemOperationBusy: true,
      error: undefined,
    })
  )
  .case(
    checkQoiStatusAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isModemOperationBusy: false,
    })
  )
  .case(
    checkQoiStatusAction.done,
    (state, {result: {qoiDetails}}): IActivationState => ({
      ...state,
      qoiDetails,
      isModemOperationBusy: false,
    })
  )
  .case(
    activateModemAction.started,
    (state): IActivationState => ({
      ...state,
      modemActivationStarted: false,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    activateModemAction.done,
    (state, {result: {success}}): IActivationState => ({
      ...state,
      modemActivationStarted: success,
      isBusy: false,
    })
  )
  .case(
    activateModemAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    activateModemSwapAction.started,
    (state): IActivationState => ({
      ...state,
      modemActivationStarted: false,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    activateModemSwapAction.done,
    (state, {result: {success, productInstanceId}}): IActivationState => ({
      ...state,
      modemActivationStarted: success,
      productInstanceId,
      isBusy: false,
    })
  )
  .case(
    activateModemSwapAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    checkModemActivationStatusAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    checkModemActivationStatusAction.done,
    (state, {result: {status, voipActivationStatus}}): IActivationState => ({
      ...state,
      modemActivationStatus: status,
      voipActivationStatus,
      isBusy: false,
    })
  )
  .case(
    checkModemActivationStatusAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )

  .case(
    checkSwappedModemActivationStatusAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    checkSwappedModemActivationStatusAction.done,
    (state, {result: {status}}): IActivationState => ({
      ...state,
      modemActivationStatus: status,
      isBusy: false,
    })
  )
  .case(
    checkSwappedModemActivationStatusAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    updateVoIPStatusAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      voipPi: undefined,
      voIPActivated: undefined,
      alianzaAgreementUrl: undefined,
      error: undefined,
    })
  )
  .case(
    updateVoIPStatusAction.done,
    (state, {result: {voipPi, success, activationUrl}}): IActivationState => ({
      ...state,
      voipPi: voipPi,
      voIPActivated: success,
      alianzaAgreementUrl: activationUrl,
      isBusy: false,
    })
  )
  .case(
    updateVoIPStatusAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    getGbsVoIPAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    getGbsVoIPAction.done,
    (state, {result: gbsVoipDetails}): IActivationState => ({
      ...state,
      isBusy: false,
      gbsVoipDetails,
    })
  )
  .case(
    getGbsVoIPAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    startGbsVoIPActivationAction.started,
    (state, payload): IActivationState => ({
      ...state,
      gbsVoipDetails: updateGbsLineLoadingState(payload.voipPi, state),
    })
  )
  .case(
    startGbsVoIPActivationAction.done,
    (state, {result: {status}, params}): IActivationState => ({
      ...state,
      gbsVoipDetails: updateGbsLineDetails(params, status, state),
    })
  )
  .case(
    startGbsVoIPActivationAction.failed,
    (state, result): IActivationState => ({
      ...state,
      gbsVoipDetails: updateGbsLineDetails(
        result.params,
        ActivationStatus.ACTIVATING_ERROR,
        state,
        result.error && result.error.internalError
          ? `${result.error.internalError.message} --- ${result.error.xBEPExecutionId}   ${
              '[' + result.error.status + ']'
            }`
          : result.error.friendlyMessage
      ),
    })
  )
  .case(
    swapTrackedEquipmentAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    swapTrackedEquipmentAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    swapTrackedEquipmentAction.done,
    (state): IActivationState => ({
      ...state,
      isBusy: false,
    })
  )
  .case(
    trackEquipmentAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    trackEquipmentAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    trackEquipmentAction.done,
    (state, result): IActivationState => ({
      ...state,
      isBusy: false,
    })
  )
  .case(
    validateCreditCardAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
      creditCardMatched: undefined,
    })
  )
  .case(
    validateCreditCardAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
      creditCardMatched: result.error.creditCardMatched,
    })
  )
  .case(
    validateCreditCardAction.done,
    (state, result): IActivationState => ({
      ...state,
      isBusy: false,
      creditCardMatched: true,
    })
  )
  .case(
    submitTwoPointFourGHzDataAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    submitTwoPointFourGHzDataAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    submitTwoPointFourGHzDataAction.done,
    (state, result): IActivationState => ({
      ...state,
      isBusy: false,
    })
  )
  .case(
    submitFiveGHzDataAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
    })
  )
  .case(
    submitFiveGHzDataAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    submitFiveGHzDataAction.done,
    (state, result): IActivationState => ({
      ...state,
      isBusy: false,
    })
  )
  .case(
    activateHotspotAction.started,
    (state, result): IActivationState => ({
      ...state,
      isBusy: true,
      hotspotActivationStarted: false,
      wifiActivationStarted: false,
      wifiActivationStatus: undefined,
    })
  )
  .case(
    activateHotspotAction.done,
    (state, result): IActivationState => ({
      ...state,
      isBusy: false,
      hotspotActivationStarted: result.result.success,
    })
  )
  .case(
    activateHotspotAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    checkHotspotActivationStatusAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
      hotspotActivationStatus: undefined,
    })
  )
  .case(
    checkHotspotActivationStatusAction.done,
    (state, {result: {status}}): IActivationState => ({
      ...state,
      hotspotActivationStatus: status,
      hotspotActivationStarted: false,
      isBusy: false,
    })
  )
  .case(
    checkHotspotActivationStatusAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    activateWifiAction.started,
    (state, result): IActivationState => ({
      ...state,
      isBusy: true,
      wifiActivationStarted: false,
    })
  )
  .case(
    activateWifiAction.done,
    (state, result): IActivationState => ({
      ...state,
      isBusy: false,
      wifiActivationStarted: result.result.success,
    })
  )
  .case(
    activateWifiAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    checkWifiActivationStatusAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
      wifiActivationStatus: undefined,
    })
  )
  .case(
    checkWifiActivationStatusAction.done,
    (state, {result: {status}}): IActivationState => ({
      ...state,
      wifiActivationStatus: status,
      wifiActivationStarted: false,
      isBusy: false,
    })
  )
  .case(
    checkWifiActivationStatusAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    getContractInstanceAction.started,
    (state): IActivationState => ({
      ...state,
      isBusy: true,
      error: undefined,
      contractDetails: {
        signerUrl: '',
        signedStatus: false,
        isBusyCheckingStatus: false,
        isContractUnsignedAfterCheck: false,
      },
    })
  )
  .case(
    getContractInstanceAction.done,
    (state, {result: {contractInfo}}): IActivationState => ({
      ...state,
      contractDetails: {
        ...state.contractDetails,
        signerUrl: contractInfo.signerUrl,
        signedStatus: contractInfo.signedStatus,
      },
      isBusy: false,
    })
  )
  .case(
    getContractInstanceAction.failed,
    (state, result): IActivationState => ({
      ...state,
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(
    checkContractStatusAction.started,
    (state): IActivationState => ({
      ...state,
      error: undefined,
      contractDetails: {
        ...state.contractDetails,
        isBusyCheckingStatus: true,
        isContractUnsignedAfterCheck: false,
      },
    })
  )
  .case(
    checkContractStatusAction.done,
    (state, {result: {contractInfo}}: any): IActivationState => ({
      ...state,
      contractDetails: {
        ...state.contractDetails,
        signerUrl: contractInfo.signerUrl || state.contractDetails!.signerUrl,
        signedStatus: contractInfo.signedStatus,
        isBusyCheckingStatus: false,
        isContractUnsignedAfterCheck: !contractInfo.signedStatus,
      },
    })
  )
  .case(
    checkContractStatusAction.failed,
    (state, result): IActivationState => ({
      ...state,
      contractDetails: {
        ...state.contractDetails,
        isBusyCheckingStatus: false,
        isContractUnsignedAfterCheck: true,
      },
      ...{error: result.error},
      isBusy: false,
    })
  )
  .case(promptForLegacyRedirectAction, (state, result) => ({
    ...state,
    promptForLegacyRedirect: result.shouldPromptForLegacyRedirect,
    redirectUrl: result.redirectUrl,
  }));

const updateGbsLineLoadingState = (voipPi: string, state: any): GbsVoipDetails[] => {
  return state.gbsVoipDetails.map((voipDetails: GbsVoipDetails) => {
    if (voipDetails.voipPi === voipPi) {
      return {...voipDetails, isLoading: true};
    }
    return voipDetails;
  });
};
const updateGbsLineDetails = (
  params: UpdateGBSVoipParams,
  status: string,
  state: any,
  error = ''
): GbsVoipDetails[] => {
  return state.gbsVoipDetails.map((voipDetails: GbsVoipDetails) => {
    if (voipDetails.voipPi === params.voipPi) {
      return {
        ...voipDetails,
        previousState: error ? voipDetails.status : undefined,
        error,
        status,
        isLoading: false,
      };
    }
    return voipDetails;
  });
};
