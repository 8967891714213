import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from '../../../store';
import {
  activateModemAction,
  activateModemSwapAction,
  checkModemActivationStatusAction,
  checkSwappedModemActivationStatusAction,
  resetErrorAction,
} from '../../../store/activation/actions';
import {setApplicationFlowState} from '../../../store/activation/activationHelpers';
import Error from '../../common/Error';
import ModemActivatePage from './ModemActivatePage';
import {CountryCodes, FlowState, InstallationSubType, InstallationTypesCodes} from '../../../definitions/types';
import {useInstallationFlowType} from '../../util/customHooks/useInstallationFlowType';
import VoipDisclaimerComponent from '../voIPStatusUpdate/voipDisclaimerComponent';
import {ActivationStatus} from '../../util/ActivationStatus';

const ModemActivatePageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    performModemActivation: (isSwap = false) =>
      dispatch(isSwap ? activateModemSwapAction.started({}) : activateModemAction.started({})),
    checkModemStatus: (isSwap = false) =>
      dispatch(
        isSwap ? checkSwappedModemActivationStatusAction.started({}) : checkModemActivationStatusAction.started({})
      ),
    setModemActivated: () => dispatch(setApplicationFlowState(FlowState.ModemActivated) as any),
    resetError: () => dispatch(resetErrorAction()),
  };
  const stateProps = useSelector((state: IRootState) => {
    return {
      isBusy: state.activation.isBusy,
      activationUrl: state.activation.activationUrl as string,
      error: state.activation.error,
      activationStarted: state.activation.modemActivationStarted as boolean,
      modemActivationStatus: state.activation.modemActivationStatus as string,
      installationType: state.system.installationType as InstallationTypesCodes,
      installationSubType: state.system.installationSubtype,
      country: state.system.country as CountryCodes,
      voipActivationStatus: state.activation.voipActivationStatus,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };
  const isSwap =
    props.installationSubType === InstallationSubType.RES_SWAP ||
    props.installationSubType === InstallationSubType.WHOLESALE_SWAP;
  const {activationStarted, error, resetError} = props;
  const activateModem = () => {
    return props.performModemActivation(isSwap);
  };
  const checkModemActivationStatus = () => {
    return props.checkModemStatus(isSwap);
  };
  // Activates modem on first load
  useEffect(() => {
    if (!activationStarted) {
      activateModem();
    } // eslint-disable-next-line
  }, []);

  // starts polling if activation reques has been sent and on every retry
  useEffect(() => {
    if (activationStarted) {
      checkModemActivationStatus();
    } // eslint-disable-next-line
  }, [activationStarted]);
  const [disclaimerShown, setDisclaimerShown] = useState(false);
  const onDisclaimerDismissed = () => {
    setDisclaimerShown(true);
  };
  const showUiComponent = () => {
    return props.voipActivationStatus === ActivationStatus.ACTIVE && !disclaimerShown ? (
      <VoipDisclaimerComponent onContinue={onDisclaimerDismissed} />
    ) : (
      <ModemActivatePage
        {...props}
        activateModem={activateModem}
        title={title}
        isVoipActivated={props.voipActivationStatus === ActivationStatus.ACTIVE}
      />
    );
  };
  const reset = () => {
    activationStarted ? checkModemActivationStatus() : activateModem();
    resetError();
  };
  const title = useInstallationFlowType(props.installationType, props.installationSubType, props.country);
  return error ? <Error message={error} onReload={reset} enableReload={true} /> : showUiComponent();
};

export default ModemActivatePageContainer;
