import React from 'react';
import {Card, Text} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import {InstallStepAlt as Icon} from '../../../common/InstallStep';
import {Class} from '@material-ui/icons';

const responsiveInfoHeights = {height: ['auto', 'auto', '80px', '80px', '80px']} as any;

interface Props {
  responsiveWidths: any;
}

const ContractContentDefault: React.FC<Props> = (props: Props) => {
  return (
    <Card minHeight="350px" mt={5} {...props.responsiveWidths}>
      <Text
        textAlign="center"
        mt={3}
        mb={4}
        display="block"
        {...responsiveInfoHeights}
        data-test-el="contract-header-text"
      >
        <FormattedMessage
          id="contract.subtitle"
          defaultMessage="To complete the installation, the customer needs to sign the ECA."
        />
      </Text>
      <Icon status="GRAY" icon={<Class />} label={{key: ' ', default: ' '}} />
      <Text
        textAlign="center"
        mt={6}
        mb={4}
        mr="auto"
        ml="auto"
        width={['auto', 'auto', '530px', '530px', '530px'] as any}
        display="block"
        fontWeight="bolder"
        fontSize={3}
        data-test-el="contract-info-text"
      >
        <FormattedMessage id="contract.info" defaultMessage="Click the button below to open the Customer Agreement." />
      </Text>
    </Card>
  );
};

export default ContractContentDefault;
