import React, {useEffect, useState} from 'react';
import {PageContainer, TextContainer} from '../../common/AppLayout';

import ButtonContainer from '../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl.macro';
import RestartButton from '../../common/RestartButton';
import {Button, Card, TextField, Title, ValidatorForm} from '@viasat/components';
import {macAddressRegex} from '../../util/regex';
import MacAddressValidationError from '../../../components-lib/form/MacAddressValidationError';
import GenericErrorComponent, {GenericErrorComponentProps} from '../../common/GenericErrorComponent';
import {PageState} from '../../util/PageState';
import {getQueryParams} from '../../util/appUtilities';

interface Props {
  setMacAddressAndProvisionModem: (macAddress: string) => void;
  macAddressValidated?: boolean;
}

const ResSwapMacAddressForm: React.FC<Props> = ({setMacAddressAndProvisionModem, macAddressValidated}) => {
  const hasError = (fieldId: string): boolean =>
    validationErrors.filter((e: {props: {id: string}}) => e.props.id === fieldId).length > 0;
  const [macAddress, setMacAddress] = useState(getQueryParams()['macAddress'] || '');
  const [validationErrors, setValidationError] = useState([]);
  const [pageState, setPageState] = useState(PageState.DEFAULT);
  const resetMacAddressForm = () => {
    setMacAddress('');
    setPageState(PageState.DEFAULT);
  };
  useEffect(() => {
    if (macAddressValidated === false) {
      setPageState(PageState.ERROR);
    }
  }, [macAddressValidated]);
  const getFormStateComponent = () => (
    <ValidatorForm
      onSubmit={submit}
      onError={(error) => {
        setValidationError(error);
      }}
    >
      <Card>
        <TextContainer textAlign={'center'}>
          <FormattedMessage
            id="res.swap.macAddress.configuration.form.title"
            defaultMessage="To configure, please populate the field below"
          />
        </TextContainer>

        <TextField
          id="macAddress"
          fullWidth={true}
          label={<FormattedMessage id="swap.mac.address.label" defaultMessage="New MAC Address" />}
          value={macAddress}
          onChange={({target}) => {
            setValidationError([]);
            setMacAddress(target.value.trim());
          }}
          mt={1}
          mb={3}
          useValidator={true}
          validators={[`matchRegexp:${macAddressRegex}`, 'required']}
          error={hasError('macAddress')}
          errorMessages={[
            <MacAddressValidationError />,
            <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
          ]}
        />
      </Card>
      <ButtonContainer>
        <RestartButton />
        <Button type="submit" data-test-el="swap-mac-address-form-continue-button">
          <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
        </Button>
      </ButtonContainer>
    </ValidatorForm>
  );
  const getErrorStateComponent = (resetError: () => void) => {
    const errorProps: GenericErrorComponentProps = {
      headerDescriptionMessage: {
        id: 'res.swap.macAddress.error.title',
        defaultMessage: 'Could not proceed with the activation',
      },
      onRetry: () => {
        resetError();
      },
      bodyMessage: {
        id: 'res.swap.macAddress.error.body',
        defaultMessage:
          'The mac address entered is currently in use by another service.</br>Please ensured that the MAC address entered is correct.',
      },
    };
    return <GenericErrorComponent {...errorProps} />;
  };
  function submit() {
    setMacAddressAndProvisionModem(macAddress);
  }

  return (
    <PageContainer data-test-el="swap-mac-address-form">
      <Title data-test-el="swap-mac-address-form-title">
        <FormattedMessage id="res.swap.macAddress.configuration.title" defaultMessage="Configure new modem" />
      </Title>
      <TextContainer mt={1} mb={5}>
        <FormattedMessage
          id="res.swap.macAddress.configuration.subtitle"
          defaultMessage="You can find the MAC Address on the device."
        />
      </TextContainer>
      {
        {
          DEFAULT: getFormStateComponent(),
          ERROR: getErrorStateComponent(resetMacAddressForm),
        }[pageState]
      }
    </PageContainer>
  );
};

export default ResSwapMacAddressForm;
