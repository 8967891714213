import React, {useEffect, useState} from 'react';
import {Text, Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import {PageContainer} from '../../common/AppLayout';
import IframeModal from '../../common/IframeModal/IframeModal';
import GenericSuccessComponent, {GenericSuccessComponentProps} from '../../common/GenericSuccessComponent';
import {PageState} from '../../util/PageState';
import {globalIntl} from '../../../utils/IntlGlobalProvider';
import {theme} from '../../../theme/theme';
export interface Props {
  isModalOpen: boolean;
  alianzaUrl: string;
  serviceActivationCode: string;
  closeModal: () => void;
  onFinish: () => void;
  onRetry: () => void;
}
export const AlianzaAgreementPage: React.FC<Props> = ({
  alianzaUrl,
  onFinish,
  isModalOpen,
  closeModal,
  serviceActivationCode,
}: Props) => {
  const modalInfoText = {
    id: 'alianza.modal.close.info',
    defaultMessage: 'Once you finish, click close to complete the activation',
  };
  const modalTitle = 'Alianza Portal';
  const getSacHeader = () => (
    <Text variant="secondary" fontSize={[0, 1, 2, 2, 2]}>
      {`${globalIntl.formatMessage({
        id: 'voip.banner.sac.text',
        defaultMessage: 'Voice Service Activation Code:',
      })} `}
      <Text fontWeight={theme.fontWeights.bold}>{serviceActivationCode}</Text>
    </Text>
  );
  const closeModalButtonText = {id: 'contract.modal.close.button', defaultMessage: 'Close'};
  const successProps: GenericSuccessComponentProps = {
    descriptionMessage: {
      id: 'voip.activation.success.description',
      defaultMessage: 'This completes the activation process. Please verify that the service is now active.',
    },
    buttonActionMessage: {
      id: 'button.finish',
      defaultMessage: 'Finish',
    },
    onContinue: onFinish,
  };
  const [pageState, setPageState] = useState(PageState.DEFAULT);
  useEffect(() => {
    if (!isModalOpen) {
      setPageState(PageState.SUCCESS);
    } else {
      setPageState(PageState.DEFAULT);
    }
  }, [isModalOpen]);

  const [pageHeight, setPageHeight] = useState('100vh');
  useEffect(() => {
    setPageHeight(`${window.innerHeight}px`);
    function handleResize() {
      setPageHeight(`${window.innerHeight}px`);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [pageHeight]);

  return (
    <>
      <PageContainer data-test-el="voip-alianza-agreement-page">
        <Title>
          <FormattedMessage id="voip.status.activation.title" defaultMessage={'Service Activation'} />
        </Title>
        {
          {
            [PageState.DEFAULT]: (
              <IframeModal
                height={pageHeight}
                isOpen={isModalOpen}
                closeModal={closeModal}
                iframeUrl={alianzaUrl}
                modalTitle={modalTitle}
                modalInfoText={modalInfoText}
                closeButtonText={closeModalButtonText}
                footerItems={getSacHeader()}
              />
            ),
            [PageState.SUCCESS]: <GenericSuccessComponent {...successProps} />,
          }[pageState]
        }
      </PageContainer>
    </>
  );
};
