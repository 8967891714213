import React from 'react';
import {Card, Text, Loading} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';

interface Props {
  responsiveWidths: any;
}

const ContractContentVerifying: React.FC<Props> = ({responsiveWidths}: Props) => {
  return (
    <Card minHeight="350px" mt={5} {...responsiveWidths} pt={'80px'} pb={'80px'} textAlign="center">
      <Loading p={0} m={4} size={127} />
      <Text
        fontWeight="bold"
        fontSize={3}
        width="auto"
        display="block"
        mr="auto"
        ml="auto"
        mt={3}
        textAlign="center"
        data-test-el="status-card-text"
      >
        <FormattedMessage id="contract.verifying" defaultMessage="Verifying the electric agreement status..." />
      </Text>
    </Card>
  );
};

export default ContractContentVerifying;
