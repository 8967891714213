import React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../store';

import {trackEquipmentAction} from '../../../store/activation/actions';
import EquipmentTrackingPage from './EquipmentTrackingPage';
import {ErrorAPIMessage} from '../../../definitions/types';

export interface StateProps {
  isBusy: boolean;
  error?: ErrorAPIMessage;
  equipmentTrackingStatus: string;
  trackEquipment: () => void;
  country: string;
}
export interface DispatchProps {
  trackEquipment: () => void;
}
export type Props = StateProps & DispatchProps;

const EquipmentTrackingPageContainer: React.FC<Props> = (props) => {
  return <EquipmentTrackingPage {...props} />;
};

export default connect(
  (state: IRootState) => ({
    isBusy: state.activation.isBusy,
    error: state.activation.error,
    installationType: state.system.installationType,
    country: state.system.country,
  }),
  (dispatch) => ({
    trackEquipment: (posSerialNumber: string, upsSerialNumber: string) =>
      dispatch(trackEquipmentAction.started({posSerialNumber, upsSerialNumber}) as any),
  })
)(EquipmentTrackingPageContainer as any) as React.ComponentType<Partial<Props>>;
