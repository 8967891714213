import React from 'react';
import {FormattedMessage} from 'react-intl';
import CheckCircle from '@material-ui/icons/CheckCircle';
import {withTheme} from 'styled-components';
import {StyledProps} from '../../../../theme/theme';
import {StatusCard} from '@viasat/components';
interface Props {
  isVoipActivated?: boolean;
}
const ModemActivationSuccess: React.FunctionComponent<StyledProps & Props> = ({theme, isVoipActivated}) => {
  return (
    <>
      <StatusCard
        body={
          <>
            <FormattedMessage id="service.activation.success.header" defaultMessage="Congratulations!" />
            <br />
            <FormattedMessage
              id={isVoipActivated ? 'service.activation.with.voip.success.body' : 'service.activation.success.body'}
              defaultMessage={
                isVoipActivated
                  ? 'The Internet and VoIP services are now active.'
                  : 'The Internet service is now active.'
              }
            />
          </>
        }
        icon={<CheckCircle style={{height: '140px', width: '140px', color: theme.colors.TEAL_500}} />}
      />
    </>
  );
};

export default withTheme(ModemActivationSuccess);
