import * as React from 'react';
import styled from 'styled-components';
import {
  space,
  fontSize,
  color,
  shadow,
  ShadowProps,
  border,
  BorderProps,
  ColorProps,
  FontSizeProps,
  SpaceProps,
} from 'styled-system';
import {FormattedMessage} from 'react-intl';
import {QoiStepState} from '../../definitions/types';
const QoiStepStyleMap = {
  WHITE: {
    bg: 'WHITE',
    color: 'MID_GRAY',
    borderColor: 'MID_GRAY',
    boxShadow: 'none',
  },
  GREEN: {
    bg: 'LIGHTEST_GREEN',
    color: 'WHITE',
    borderColor: 'LIGHTEST_GREEN',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  RED: {
    bg: 'RED',
    color: 'WHITE',
    borderColor: 'RED',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  GRAY: {
    bg: 'LIGHT_GRAY',
    color: 'WHITE',
    borderColor: 'LIGHT_GRAY',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
  },
};
const InstallStepLine = styled.div<ColorProps>`
  ${color}
  height: 3px;
  position: absolute;
  top: 27px;
  left: 50%;
  width: 100%;
`;
InstallStepLine.defaultProps = {
  bg: 'MID_GRAY',
};
export const InstallStepContainer = styled.div<SpaceProps>`
  ${space}
  display:flex;
  justify-content: space-evenly;
  @media screen and (max-width: 600px) {
    /* TODO get from theme */
    flex-direction: column;
    ${InstallStepLine} {
      display: none;
    }
  }
  & div:last-child {
    ${InstallStepLine} {
      display: none;
    }
  }
`;
InstallStepContainer.defaultProps = {};
const InstallStepIcon = styled.div<BorderProps & ColorProps & ShadowProps>`
  border-radius: 900px;
  height: 56px;
  width: 56px;
  overflow: visible;
  ${shadow}
  display: flex;
  margin-bottom: ${({theme}) => theme.space[3]}px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  ${color}
  .material-icons {
    ${color}
  }
  ${border}
  border-width: 3px;
  border-style: solid;
`;
InstallStepIcon.defaultProps = {
  bg: 'WHITE',
  color: 'MID_GRAY',
  borderColor: 'MID_GRAY',
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
};
interface Props extends SpaceProps, FontSizeProps, ColorProps {
  className?: string;
  label: {key: string; default: string};
  icon: JSX.Element;
  status: QoiStepState;
}
const InstallStep: React.FC<Props> = ({className, label, icon, status}: Props) => {
  const stateStyles = QoiStepStyleMap[status];
  return (
    <div className={className}>
      <InstallStepLine bg={stateStyles.borderColor} />
      <InstallStepIcon {...stateStyles}>{icon}</InstallStepIcon>
      <label>
        <FormattedMessage id={label.key} defaultMessage={label.default} />
      </label>
    </div>
  );
};
const InstallStepWithoutLine: React.FC<Props> = ({className, label, icon, status}: Props) => {
  const stateStyles = QoiStepStyleMap[status];
  return (
    <div className={className}>
      <InstallStepIcon {...stateStyles}>{icon}</InstallStepIcon>
      <label>
        <FormattedMessage id={label.key} defaultMessage={label.default} />
      </label>
    </div>
  );
};
export const InstallStepAlt = styled(InstallStepWithoutLine)<Props>`
  ${space}
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  label {
    ${color}
    ${fontSize}
    font-weight: bolder;
    letter-spacing: 0.5px;
  }
`;
InstallStepAlt.defaultProps = {
  mt: 0,
  mr: 0,
  ml: 0,
  mb: [3, 3, 0, 0, 0],
  fontSize: [0, 0, 1, 1, 1],
  color: 'GRAY_800',
};
const InstallStepStyled = styled(InstallStep)<Props>`
  ${space}
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  overflow: visible;
  min-width: 125px;
  width: 100%;
  label {
    ${color}
    ${fontSize}
    max-width: 125px;
    min-height: 32px;
    font-weight: bolder;
    letter-spacing: 0.5px;
    line-height: 14px;
    text-align: center;
  }
`;
InstallStepStyled.defaultProps = {
  mt: 0,
  mr: 0,
  ml: 0,
  mb: [3, 3, 0, 0, 0],
  fontSize: [0, 0, 1, 1, 1],
  color: 'GRAY_800',
};
export default InstallStepStyled;
