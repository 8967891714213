import ButtonContainer from '../../common/ButtonContainer';
import MacAddressValidationError from '../../../components-lib/form/MacAddressValidationError';
import React, {useState} from 'react';
import RestartButton from '../../common/RestartButton';
import {AccessPoint} from '../../../definitions/types';
import {apSerialFormat, macAddressRegex, ncSerialFormat} from '../../util/regex';
import {Button, Card, TextField, Title, ValidatorForm} from '@viasat/components';
import {EquipmentProductKind} from '../../util/EquipmentUtility';
import {FormattedMessage} from 'react-intl.macro';
import {PageContainer, TextContainer} from '../../common/AppLayout';

export interface Props {
  isBusy: boolean;
  selectedSwapEquipment: EquipmentProductKind;
  onSubmit: (accessPoint: AccessPoint) => void;
}

const SwapWifiEquipmentPage: React.FC<Props> = ({isBusy, onSubmit, selectedSwapEquipment}) => {
  const [validationErrors, setValidationError] = useState([]);
  const [serialNumber, setSerialNumber] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const isNetworkController = selectedSwapEquipment === EquipmentProductKind.NETWORK_CONTROLLER;

  const hasError = (fieldId: string): boolean =>
    validationErrors.filter((e: {props: {id: string}}) => e.props.id === fieldId).length > 0;

  return (
    <PageContainer data-test-el="swap-wifi-equipment-page">
      <Title data-test-el="swap-wifi-equipment-heading">
        {isNetworkController ? (
          <FormattedMessage id="swap.network.controller.title" defaultMessage="Replace Network Controller" />
        ) : (
          <FormattedMessage id="swap.access.point.title" defaultMessage="Replace Access Point" />
        )}
      </Title>

      <ValidatorForm
        onSubmit={() => onSubmit({serialNumber, macAddress})}
        onError={(error) => {
          setValidationError(error);
        }}
      >
        <Card>
          <TextContainer mt={3} mb={5} textAlign={'center'}>
            <FormattedMessage
              id="swap.wifi.equipment.text"
              defaultMessage="Please connect the new equipment and enter the following details:"
            />
          </TextContainer>

          <TextField
            id="serialnumber"
            fullWidth={true}
            label={
              isNetworkController ? (
                <FormattedMessage
                  id="swap.nc.serial.number.label"
                  defaultMessage="New Network Controller serial number"
                />
              ) : (
                <FormattedMessage id="swap.ap.serial.number.label" defaultMessage="New Access Point serial number" />
              )
            }
            onChange={({target}) => {
              setValidationError([]);
              setSerialNumber(target.value.trim());
            }}
            value={serialNumber}
            mt={1}
            mb={3}
            useValidator={true}
            validators={[`matchRegexp:${isNetworkController ? ncSerialFormat : apSerialFormat}`, 'required']}
            error={hasError('serialnumber')}
            errorMessages={[
              <FormattedMessage
                id={isNetworkController ? 'serial.number.nc.validation.error' : 'serial.number.ap.validation.error'}
                defaultMessage="The serial number must match the following format: 12 alpha numeric characters followed by a slash followed by 3 digits. Eg: 1234567890sn/123"
              />,
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
            ]}
          />
          <TextField
            id="macaddress"
            fullWidth={true}
            label={<FormattedMessage id="swap.mac.address.label" defaultMessage="New MAC Address" />}
            value={macAddress}
            onChange={({target}) => {
              setValidationError([]);
              setMacAddress(target.value.trim());
            }}
            mt={1}
            mb={3}
            useValidator={true}
            validators={[`matchRegexp:${macAddressRegex}`, 'required']}
            error={hasError('macaddress')}
            errorMessages={[
              <MacAddressValidationError />,
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
            ]}
          />
        </Card>
        <ButtonContainer>
          <RestartButton />
          <Button type="submit" disabled={isBusy} data-test-el="wifi-continue-button">
            <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
          </Button>
        </ButtonContainer>
      </ValidatorForm>
    </PageContainer>
  );
};

export default SwapWifiEquipmentPage;
