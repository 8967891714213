import React from 'react';
import {PageContainer} from '../../common/AppLayout';
import ModemActivationError from './modemActivatePageComponents/ModemActivationError';
import ModemActivationSuccess from './modemActivatePageComponents/ModemActivationSuccess';
import ModemActivationLoading from './modemActivatePageComponents/ModemActivationLoading';
import ButtonContainer from '../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl';
import {Button, Title} from '@viasat/components';
import RestartButton from '../../common/RestartButton';
import env from '../../../utils/env';
import {InstallationTypesCodes} from '../../../definitions/types';
import {ActivationStatus} from '../../util/ActivationStatus';

export interface Props {
  activateModem: () => void;
  modemActivationStatus: string;
  isBusy: boolean;
  activationStarted: boolean;
  installationType: string;
  setModemActivated: () => void;
  activationUrl: string;
  title: string;
  isVoipActivated: boolean;
}

const ModemActivationPage: React.FC<Props> = ({
  activateModem,
  modemActivationStatus,
  activationStarted,
  isBusy,
  installationType,
  setModemActivated,
  activationUrl,
  isVoipActivated,
}) => {
  let state = modemActivationStatus && !isBusy ? modemActivationStatus.toUpperCase() : 'LOADING';

  if (!isBusy && !activationStarted) {
    state = ActivationStatus.ACTIVATING_ERROR;
  }

  const isCwf = installationType === InstallationTypesCodes.VCI;
  const SurfOrContinue = () =>
    isCwf ? (
      <Button onClick={setModemActivated} data-test-el="modem-activate-success-button">
        <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
      </Button>
    ) : (
      <Button href={activationUrl || env.activationUrl} data-test-el="modem-activate-success-button">
        <FormattedMessage id="service.activation.continue.button" defaultMessage="Let's Surf" />
      </Button>
    );
  return (
    <PageContainer data-test-el="modem-activate-page">
      <Title>
        <FormattedMessage id="service.activation.status.title" defaultMessage="Service Activation Status" />
      </Title>
      {
        {
          LOADING: <ModemActivationLoading />,
          [ActivationStatus.ACTIVE]: <ModemActivationSuccess isVoipActivated={isVoipActivated} />,
          [ActivationStatus.ACTIVATING_ERROR]: <ModemActivationError />,
          [ActivationStatus.PROCESSED]: <ModemActivationSuccess />,
          [ActivationStatus.PROCESSING_ERROR]: <ModemActivationError />,
        }[state]
      }
      <ButtonContainer>
        {state !== ActivationStatus.ACTIVE && state !== ActivationStatus.PROCESSED ? (
          <RestartButton />
        ) : (
          <SurfOrContinue />
        )}
        {(state === ActivationStatus.ACTIVATING_ERROR || state === ActivationStatus.PROCESSING_ERROR) && (
          <Button onClick={activateModem} data-test-el="modem-activate-retry">
            <FormattedMessage id="page.retry.button" defaultMessage="Retry" />
          </Button>
        )}
      </ButtonContainer>
    </PageContainer>
  );
};

export default ModemActivationPage;
