import ButtonContainer from '../../common/ButtonContainer';
import React from 'react';
import {Button, Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import {PageContainer} from '../../common/AppLayout';
import ContractContentDefault from './contractPageComponents/ContractContentDefault';
import ContractContentVerifying from './contractPageComponents/ContractContentVerifying';
import ContractContentFailure from './contractPageComponents/ContractContentFailure';
import ContractContentSuccess from './contractPageComponents/ContractContentSuccess';
import IframeModal from '../../common/IframeModal/IframeModal';
import {ErrorAPIMessage} from '../../../definitions/types';

const responsiveWidthVals = ['auto', 'auto', 'auto', '760px', '760px'] as any;
const responsiveWidths = {width: responsiveWidthVals, minWidth: responsiveWidthVals};

export interface Props {
  isBusy: boolean;
  error?: ErrorAPIMessage;
  closeContractModal: () => void;
  openContractModal: () => void;
  isModalOpen: boolean;
  contractUrl: string;
  signedStatus: boolean;
  isBusyCheckingStatus: boolean;
  isContractUnsignedAfterCheck: boolean;
  onFinish: () => void;
}
enum PageStates {
  DEFAULT = 'DEFAULT',
  VERIFYING = 'VERIFYING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}
const ContractPage: React.FC<Props> = ({
  isBusy,
  isModalOpen,
  closeContractModal,
  openContractModal,
  contractUrl,
  signedStatus,
  isBusyCheckingStatus,
  isContractUnsignedAfterCheck,
  onFinish,
}: Props) => {
  const modalInfoText = {id: 'contract.modal.close.info', defaultMessage: 'Once you finish signing, click Close'};
  const modalTitle = 'sertifi contract';
  const closeModalButtonText = {id: 'contract.modal.close.button', defaultMessage: 'Close'};
  let state = PageStates.DEFAULT;
  if (isBusyCheckingStatus) state = PageStates.VERIFYING;
  if (isContractUnsignedAfterCheck) state = PageStates.FAILED;
  if (signedStatus === true) state = PageStates.SUCCESS;

  return (
    <>
      <IframeModal
        isOpen={isModalOpen}
        closeModal={closeContractModal}
        iframeUrl={contractUrl}
        modalTitle={modalTitle}
        modalInfoText={modalInfoText}
        closeButtonText={closeModalButtonText}
      />
      <PageContainer data-test-el="contract-page">
        <Title>
          <FormattedMessage id="contract.title" defaultMessage="Electronic Customer Agreement" />
        </Title>
        {
          {
            [PageStates.DEFAULT]: <ContractContentDefault responsiveWidths={responsiveWidths} />,
            [PageStates.VERIFYING]: <ContractContentVerifying responsiveWidths={responsiveWidths} />,
            [PageStates.FAILED]: <ContractContentFailure responsiveWidths={responsiveWidths} />,
            [PageStates.SUCCESS]: <ContractContentSuccess responsiveWidths={responsiveWidths} />,
          }[state]
        }
        <ButtonContainer style={{whiteSpace: 'nowrap'}} {...responsiveWidths}>
          <Button
            mr={3}
            variant="secondary"
            disabled={isBusy}
            onClick={openContractModal}
            data-test-el="open-eca-button"
          >
            <FormattedMessage id="contract.button.open" defaultMessage="Open ECA" />
          </Button>
          <Button disabled={!signedStatus} onClick={onFinish} data-test-el="finish-button">
            <FormattedMessage id="button.activate" defaultMessage="Activate" />
          </Button>
        </ButtonContainer>
      </PageContainer>
    </>
  );
};

export default ContractPage;
