import ButtonContainer from '../../common/ButtonContainer';
import LoaderComponent from '../../common/LoaderComponent';
import React, {useState} from 'react';
import RestartButton from '../../common/RestartButton';
import trackedEquipmentPageConfig from './trackedEquipmentPageConfig';
import {Button, Card, TextField, Title, ValidatorForm} from '@viasat/components';
import {CountryCodes, ErrorAPIMessage} from '../../../definitions/types';
import {EquipmentProductKind} from '../../util/EquipmentUtility';
import {FormattedMessage} from 'react-intl.macro';
import {PageContainer, TextContainer} from '../../common/AppLayout';

interface Props {
  trackEquipment: (serialNumber: string, equipmentKind: EquipmentProductKind) => void;
  isBusy: boolean;
  error?: ErrorAPIMessage;
  selectedEquipment: EquipmentProductKind;
  country: string;
}

const SwapTrackedEquipmentPage: React.FC<Props> = ({isBusy, trackEquipment, selectedEquipment, country}) => {
  const [validationErrors, setValidationError] = useState([]);
  const [serialNumber, setSerialNumber] = useState('');
  const countryCode = country !== CountryCodes.BR ? 'default' : country;

  const hasError = (fieldId: string): boolean =>
    validationErrors.filter((e: {props: {id: string}}) => e.props.id === fieldId).length > 0;

  if (isBusy) {
    return <LoaderComponent />;
  }

  return (
    <PageContainer data-test-el="swap-tracked-equipment-page" maxWidth="800px">
      <Title data-test-el="swap-tracked-equipment-heading" m={0}>
        <FormattedMessage
          id={trackedEquipmentPageConfig[selectedEquipment].equipment_title.id}
          defaultMessage={trackedEquipmentPageConfig[selectedEquipment].equipment_title.defaultMessage}
        />
      </Title>

      <ValidatorForm
        onSubmit={() => trackEquipment(serialNumber, selectedEquipment)}
        onError={(error) => {
          setValidationError(error);
        }}
      >
        <Card>
          <TextContainer mt={3} mb={5} textAlign={'center'}>
            <FormattedMessage
              id={trackedEquipmentPageConfig[selectedEquipment].equipment_text.id}
              defaultMessage={trackedEquipmentPageConfig[selectedEquipment].equipment_text.defaultMessage}
            />
          </TextContainer>
          <TextField
            id="serialNumber"
            fullWidth={true}
            label={
              <FormattedMessage
                id={trackedEquipmentPageConfig[selectedEquipment].equipment_label.id}
                defaultMessage={trackedEquipmentPageConfig[selectedEquipment].equipment_label.defaultMessage}
              />
            }
            onChange={({target}) => {
              setValidationError([]);
              setSerialNumber(target.value.trim());
            }}
            value={serialNumber}
            mt={1}
            mb={3}
            useValidator={true}
            validators={[
              `matchRegexp:${trackedEquipmentPageConfig[selectedEquipment].validator[countryCode]}`,
              'required',
            ]}
            error={hasError('serialNumber')}
            errorMessages={[
              <FormattedMessage
                id={trackedEquipmentPageConfig[selectedEquipment].error_message_serial_number[countryCode].id}
                defaultMessage={
                  trackedEquipmentPageConfig[selectedEquipment].error_message_serial_number[countryCode].defaultMessage
                }
              />,
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
            ]}
          />
        </Card>
        <ButtonContainer>
          <RestartButton />
          <Button type="submit" disabled={isBusy} data-test-el="track-equipment-continue-button">
            <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
          </Button>
        </ButtonContainer>
      </ValidatorForm>
    </PageContainer>
  );
};

export default SwapTrackedEquipmentPage;
