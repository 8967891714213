import React, {useState} from 'react';
import {PageContainer, TextContainer} from '../../common/AppLayout';

import ButtonContainer from '../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl.macro';
import RestartButton from '../../common/RestartButton';
import {Button, Card, Checkbox, FormControlLabel, Text, TextField, Title} from '@viasat/components';
import {UpgradeEquipmentDetails} from '../../../store/activation/actionInterfaces';
import {EquipmentProductKind} from '../../util/EquipmentUtility';
import {PageState} from '../../util/PageState';
import GenericErrorComponent, {GenericErrorComponentProps} from '../../common/GenericErrorComponent';
import {path} from 'ramda';
export interface Props {
  isBusy: boolean;
  proceedToNextStep: () => void;
  upgradeEquipmentDetails: UpgradeEquipmentDetails[];
  restartAction: () => void;
}

const getEquipmentByProductKind = (
  upgradeEquipmentDetails: UpgradeEquipmentDetails[],
  productKind: EquipmentProductKind
): UpgradeEquipmentDetails | undefined => {
  return upgradeEquipmentDetails.find((item) => {
    return item.psmProductKind === productKind;
  });
};
const ValidateEquipmentDetailsPage: React.FC<Props> = ({
  proceedToNextStep,
  isBusy,
  upgradeEquipmentDetails,
  restartAction,
}) => {
  const [isDataConfirmed, setDataConfirmed] = useState(false);
  let state = upgradeEquipmentDetails && upgradeEquipmentDetails.length ? PageState.SUCCESS : PageState.ERROR;
  const idu = getEquipmentByProductKind(upgradeEquipmentDetails, EquipmentProductKind.SI_IDU);
  const odu = getEquipmentByProductKind(upgradeEquipmentDetails, EquipmentProductKind.SI_ODU);
  const errorProps: GenericErrorComponentProps = {
    headerDescriptionMessage: {
      id: 'error.equipment.details.empty',
      defaultMessage:
        'There were no equipment found that require an upgrade. Please ensure you have selected the right installation type',
    },
    retryButtonText: {
      id: 'page.exit.button',
      defaultMessage: 'Exit',
    },
    hideCancel: true,
    onRetry: restartAction,
  };
  const SuccessComponent = () => {
    return (
      <>
        <TextContainer>
          <FormattedMessage
            id="upgrade.equipment.details.intro"
            defaultMessage="It’s important that you finish physical installation of the IDU/ODU before you continue."
          />
        </TextContainer>

        <Card>
          <Text textAlign="center" mt={3} mb={4} display="block">
            <FormattedMessage
              id="equipment.details.validate.instructions"
              defaultMessage="Please confirm you have installed the following equipment:"
            />
          </Text>
          <TextField
            id="idu"
            fullWidth={true}
            label={<FormattedMessage id="equipment.details.idu.label" defaultMessage="IDU" />}
            value={path(['deviceModel'], idu)}
            mt={1}
            mb={3}
            disabled={true}
          />

          <TextField
            id="odu"
            fullWidth={true}
            label={<FormattedMessage id="equipment.details.odu.label" defaultMessage="ODU" />}
            value={path(['deviceModel'], odu)}
            mt={1}
            mb={3}
            disabled={true}
          />

          <FormControlLabel
            mb={3}
            control={
              <Checkbox
                checked={isDataConfirmed}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDataConfirmed(event.target.checked)}
                value="confirmed"
              />
            }
            label={
              <FormattedMessage
                id="equipment.details.confirm.check"
                defaultMessage="I confirm the equipment has been installed"
              />
            }
          />
        </Card>
        <ButtonContainer>
          <RestartButton />
          <Button
            disabled={!isDataConfirmed || isBusy}
            onClick={proceedToNextStep}
            data-test-el="validate-equipment-details-continue-button"
          >
            <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
          </Button>
        </ButtonContainer>
      </>
    );
  };

  return (
    <PageContainer data-test-el="validate-equipment-details-page">
      <Title data-test-el="validate-equipment-details-title">
        <FormattedMessage id="upgrade.equipment.details.title" defaultMessage="Service Upgrade Details" />
      </Title>
      {
        {
          [PageState.SUCCESS]: <SuccessComponent />,
          [PageState.ERROR]: <GenericErrorComponent {...errorProps} />,
        }[state]
      }
    </PageContainer>
  );
};

export default ValidateEquipmentDetailsPage;
