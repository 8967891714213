import 'react-app-polyfill/ie11';

import {render} from 'react-dom';
import * as React from 'react';
import {browserHistory, initStore} from './utils/StoreUtils';
import App from './App';
import {Helmet} from 'react-helmet';
import AppProviders from './components/util/AppProviders';

import * as serviceWorker from './serviceWorker';
import '../node_modules/normalize.css/normalize.css';
import {initSentry} from './config/sentry';
import {init} from './store/system/systemHelpers';
import env from './utils/env';

console.info('********************************************');
console.log('Installer Portal Version: ' + process.env.REACT_APP_VERSION);
console.info('Environment: ', process.env.NODE_ENV);
console.info('Default Locale: ', env.defaultLocale);
console.info('********************************************');
initSentry({
  dsn: env.sentryDNS,
  environment: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION || '',
});
const store = initStore();

const AppWrapper = (props) => (
  <AppProviders store={props.store} history={props.history}>
    <React.Fragment>
      <Helmet titleTemplate="Viasat - %s" defaultTitle="Viasat" />
      {props.children}
    </React.Fragment>
  </AppProviders>
);

store.dispatch(init() as any).then(() => {
  render(
    <AppWrapper store={store} history={browserHistory}>
      <App />
    </AppWrapper>,
    document.getElementById('root')
  );
});

serviceWorker.unregister();
