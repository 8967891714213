import {Card, InfoBox, StatusIcon} from '@viasat/components';
import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {withTheme} from 'styled-components';
import {StyledProps} from '../../../theme/theme';
import {FormattedMessage} from 'react-intl';
import {ActivationStatus} from '../../util/ActivationStatus';
import {globalIntl} from '../../../utils/IntlGlobalProvider';
import {CheckCircle} from '@material-ui/icons';
import Error from '@material-ui/icons/Error';
import {GbsVoipDetails} from '../../../definitions/types';
import {GbsLineForm} from '../../pages/gbsVoipActivation/gbsLineForm';
import Collapse from '@material-ui/core/Collapse';

export interface ActivationStatusCardProps {
  voipDetail: GbsVoipDetails;
  index: number;
  startGbsVoipActivation: (voipPi: string, macAddress: string, isActivating: boolean) => void;
  lineNumber: string;
}

const getActivationStatus = (status) => {
  return `${globalIntl.formatMessage({
    id: 'voip.line.status.' + status.toLowerCase(),
    defaultMessage: status.charAt(0) + status.substr(1).toLowerCase(),
  })}`;
};

const ActivationStatusCard: React.FC<ActivationStatusCardProps & StyledProps> = (props) => {
  const [isExpanded, setExpanded] = useState(true);
  useEffect(() => {
    if (props.voipDetail.status === ActivationStatus.ACTIVE) {
      setExpanded(false);
    } // eslint-disable-next-line
  }, [props.voipDetail.status]);
  return (
    <Card textAlign="center">
      <Grid
        container
        spacing={1}
        {...(props.voipDetail.status === ActivationStatus.ACTIVE
          ? {
              onClick: () => {
                setExpanded(!isExpanded);
              },
            }
          : {})}
      >
        <Grid container item direction="row" justify="space-between" alignItems="center" xs>
          <InfoBox theme={{align: 'left'}}>
            <div>
              <FormattedMessage id="voip.line.info.label" defaultMessage="LINE" />
              {` #${props.index}`}
            </div>
            {props.voipDetail.lineNumber && <div>{props.voipDetail.lineNumber}</div>}
          </InfoBox>

          <InfoBox theme={{align: 'right'}}>
            <div>
              <FormattedMessage id="voip.line.info.status" defaultMessage="Status" />
            </div>
            <div data-test-el={`voip-line${props.index}-status`}>{getActivationStatus(props.voipDetail.status)}</div>
          </InfoBox>
        </Grid>
        {
          {
            [ActivationStatus.ACTIVATING_ERROR]: (
              <StatusIcon>
                <Error style={{height: '48px', width: '48px', color: props.theme.colors.RED}} />
              </StatusIcon>
            ),
            [ActivationStatus.ACTIVE]: (
              <StatusIcon>
                <CheckCircle style={{height: '48px', width: '48px', color: props.theme.colors.primary}} />
              </StatusIcon>
            ),
          }[props.voipDetail.status]
        }
      </Grid>
      <Collapse in={isExpanded}>
        <GbsLineForm
          voipDetail={props.voipDetail}
          index={props.index}
          startGbsVoipActivation={props.startGbsVoipActivation}
          lineNumber={props.lineNumber}
          theme={props.theme}
        />
      </Collapse>
    </Card>
  );
};
export default withTheme<React.FC<ActivationStatusCardProps & StyledProps>>(ActivationStatusCard);
