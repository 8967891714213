import React from 'react';
import {PageContainer} from '../../common/AppLayout';
import {Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import {GbsVoipDetails} from '../../../definitions/types';
import {PageState} from '../../util/PageState';
import GenericSuccessComponent, {GenericSuccessComponentProps} from '../../common/GenericSuccessComponent';
import {GbsVoipActivationDefaultState} from './gbsVoipDefaultActivationState';

export interface GbsVoipActivationPageProps {
  voipDetails: GbsVoipDetails[];
  proceedToSuccess: (proceed: boolean) => void;
  openConfirmationDialog: () => void;
  onFinish: () => void;
  isDialogOpen: boolean;
  startGbsVoipActivation: (voipPi: string, macAddress: string, isActivating: boolean) => void;
  pageState: PageState;
}

export const GbsVoipActivationPage: React.FC<GbsVoipActivationPageProps> = (props) => {
  const successProps: GenericSuccessComponentProps = {
    descriptionMessage: {
      id: 'voip.activation.success.description',
      defaultMessage: 'This completes the activation process.',
    },
    buttonActionMessage: {
      id: 'button.finish',
      defaultMessage: 'Finish',
    },
    onContinue: props.onFinish,
  };
  return (
    <PageContainer data-test-el="voip-status-activation-page">
      <Title data-test-el="voip-status-activation-heading">
        <FormattedMessage id="voip.status.activation.title" defaultMessage={'Service Activation'} />
      </Title>
      {
        {
          [PageState.DEFAULT]: <GbsVoipActivationDefaultState {...props} />,
          [PageState.SUCCESS]: <GenericSuccessComponent {...successProps} />,
        }[props.pageState]
      }
    </PageContainer>
  );
};
