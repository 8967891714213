import {InstallationSubType, InstallationTypesCodes} from '../../../../definitions/types';

export const useInstallationType = (
  installationSubtype: InstallationSubType,
  installationType: InstallationTypesCodes
) => {
  const isVci = installationType === InstallationTypesCodes.VCI;
  const isWholeSale = installationSubtype === InstallationSubType.WHOLESALE;
  const isVoIP = installationSubtype === InstallationSubType.VOIP;
  const isResidential = installationType === InstallationTypesCodes.RES;
  const isPartnerVci = installationSubtype === InstallationSubType.PARTNER_VCI;
  const isResidentialUpgrade = isResidential && installationSubtype === InstallationSubType.UPGRADE;
  const isResidentialNewInstall = isResidential && installationSubtype === InstallationSubType.SATELITE_INTERNET;
  const isVciSwap = isVci && installationSubtype === InstallationSubType.VCI_SWAP;
  const isResSwap = isResidential && installationSubtype === InstallationSubType.RES_SWAP;
  const isWholeSaleSwap =
    installationType === InstallationTypesCodes.WHOLESALE && installationSubtype === InstallationSubType.WHOLESALE_SWAP;

  return {
    isResSwap,
    isVciSwap,
    isResidentialUpgrade,
    isResidentialNewInstall,
    isPartnerVci,
    isVoIP,
    isWholeSale,
    isVci,
    isResidential,
    isWholeSaleSwap,
  };
};
