import {Action} from 'redux';
import {Observable, of} from 'rxjs';
import {map, filter, catchError, switchMap} from 'rxjs/operators';
import {changeLocale, setInstallationTypeAction, fetchConfigAction, INSTALLATION_TYPE_COUNTRY_KEY} from './actions';
import {StateObservable} from 'redux-observable';
import {fetchConfig} from '../activation/services';
import {loadConfig} from '../../utils/env';
import {generateMessage} from '../../shared-components/ErrorMessage';
import {persistField, persistLocale} from './systemHelpers';

export const changeLocaleEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(changeLocale.started.match),
    map(({payload}) => {
      const {locale} = payload;
      persistLocale(locale);
      return changeLocale.done({
        params: {locale},
      });
    })
  );

export const setInstallationTypeEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(setInstallationTypeAction.started.match),
    map(({payload}) => {
      const {installationType, country, installationSubtype} = payload;
      persistField(INSTALLATION_TYPE_COUNTRY_KEY, {installationType, country, installationSubtype});
      return setInstallationTypeAction.done({
        result: {},
        params: {installationType, country, installationSubtype},
      });
    })
  );

export const fetchConfigActionEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(fetchConfigAction.started.match),
    switchMap(() => {
      return fetchConfig().pipe(
        map((response) => {
          loadConfig(response.response);
          return fetchConfigAction.done({
            result: response.response,
            params: {},
          });
        }),
        catchError((thrownError) =>
          of(
            fetchConfigAction.failed({
              error: generateMessage('Could not load configuration!', 'error.config', thrownError),
              params: {},
            })
          )
        )
      );
    })
  );
