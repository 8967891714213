import React from 'react';
import {PageContainer, TextContainer} from '../../common/AppLayout';

import ButtonContainer from '../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl.macro';
import RestartButton from '../../common/RestartButton';
import {Button, Card, Title} from '@viasat/components';
import {UpgradeEquipmentDetails} from '../../../store/activation/actionInterfaces';

import {FormControl, FormControlLabel} from '@material-ui/core';
import {RadioBox, RadioBoxGroup} from '../../../shared-components/RadioBox';
import {EquipmentProductKind} from '../../util/EquipmentUtility';
import {globalIntl} from '../../../utils/IntlGlobalProvider';

export interface Props {
  proceedToNextStep: (productKind: EquipmentProductKind) => void;
  vciEquipmentDetails: UpgradeEquipmentDetails[];
}

const SwapEquipmentSelectionPage: React.FC<Props> = ({proceedToNextStep, vciEquipmentDetails}) => {
  const [selectedEquipment, setSelectedEquipment] = React.useState();
  const handleChange = (event) => {
    setSelectedEquipment(event.target.value.trim());
  };

  const EquipmentSelectionComponent = () => {
    return (
      <>
        <Card pt={5} pb={5}>
          <TextContainer textAlign={'center'}>
            <FormattedMessage
              id="vci.swap.equipment.details.intro"
              defaultMessage="Please select the equipment to be swapped:"
            />
          </TextContainer>

          <FormControl component="fieldset">
            <RadioBoxGroup
              aria-label="installedEquipment"
              name="installedEquipment"
              value={selectedEquipment}
              onChange={handleChange}
            >
              {vciEquipmentDetails &&
                vciEquipmentDetails.map((equipment) => {
                  const id = `swap.equipment.key.${equipment.psmProductKind}`;
                  return (
                    <FormControlLabel
                      key={equipment.psmProductKind}
                      value={equipment.psmProductKind}
                      control={<RadioBox />}
                      label={`${globalIntl.formatMessage({
                        id,
                        defaultMessage: equipment.psmProductKind,
                      })} - ${equipment.offerName}`}
                    />
                  );
                })}
            </RadioBoxGroup>
          </FormControl>
        </Card>
        <ButtonContainer>
          <RestartButton />
          <Button
            disabled={!selectedEquipment}
            onClick={() => {
              proceedToNextStep(selectedEquipment);
            }}
            data-test-el="swap-equipment-selection-continue-button"
          >
            <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
          </Button>
        </ButtonContainer>
      </>
    );
  };

  return (
    <PageContainer data-test-el="swap-equipment-selection-page">
      <Title data-test-el="swap-equipment-selection-title">
        <FormattedMessage id="vci.swap.equipment.details.title" defaultMessage="Equipment Currently Installed Onsite" />
      </Title>
      <EquipmentSelectionComponent />
    </PageContainer>
  );
};

export default SwapEquipmentSelectionPage;
