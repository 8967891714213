import React from 'react';
import {PageContainer} from '../../common/AppLayout';
import {FormattedMessage} from 'react-intl.macro';
import {ErrorItemsByCategory, InstallationSubType, QoiDetails} from '../../../definitions/types';
import InstallationSteps from './qoiPageComponents/InstallationSteps';
import QoiPageButtons from './qoiPageComponents/QoiPageButtons';
import QoiPageFooter from './qoiPageComponents/QoiPageFooter';
import QoiPageHeader from './qoiPageComponents/QoiPageHeader';
import {Card, Title} from '@viasat/components';

export const responsiveWidthVals = ['auto', 'auto', 'auto', '760px', '760px'] as any;
export const responsiveWidths = {width: responsiveWidthVals, minWidth: responsiveWidthVals};

export interface Props {
  isQoiBusy: boolean;
  installationType: string;
  performQoiActivation: () => void;
  proceedToNextStep: () => void;
  qoiDetails: QoiDetails;
  retryCount: number;
  qoiErrors: ErrorItemsByCategory[];
  country: string;
  installationSubtype: InstallationSubType;
  retry: () => void;
}

const ValidateContactPage: React.FC<Props> = ({
  performQoiActivation,
  isQoiBusy,
  qoiDetails,
  retryCount,
  proceedToNextStep,
  qoiErrors,
  installationType,
  retry,
}) => {
  const status = qoiDetails ? qoiDetails.status : undefined;
  const categories = qoiDetails ? qoiDetails.categories : undefined;

  return (
    <PageContainer data-test-el="validate-qoi-page">
      <Title>
        <FormattedMessage id="quality.validate.title" defaultMessage="Quality of Installation" />
      </Title>
      <Card minHeight="370px" mt={5} {...responsiveWidths}>
        <QoiPageHeader status={status} isBusy={isQoiBusy} />
        <InstallationSteps categories={categories} />
        <QoiPageFooter errors={qoiErrors} status={status} retryCount={retryCount} isBusy={isQoiBusy} />
      </Card>
      <QoiPageButtons
        status={status}
        isBusy={isQoiBusy}
        onValidate={performQoiActivation}
        onRetry={retry}
        onContinue={proceedToNextStep}
        installationType={installationType}
      />
    </PageContainer>
  );
};

export default ValidateContactPage;
