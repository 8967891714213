import {Card, Progress, Text} from '@viasat/components';
import {FormattedMessage} from 'react-intl';
import React from 'react';

export interface ProgressBarComponentInterface {
  variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  value?: number;
  descriptionMessage: {
    id: string;
    defaultMessage: string;
  };
  titleMessage: {
    id: string;
    defaultMessage: string;
  };
}
const ProgressBarComponent: React.FunctionComponent<ProgressBarComponentInterface> = (props) => {
  return (
    <>
      <Card data-test-el="progress-bar-component">
        <Text textAlign="center" mt={3} mb={6} display="block">
          <FormattedMessage id={props.titleMessage.id} defaultMessage={props.titleMessage.defaultMessage} />
          <br />
          <FormattedMessage id={props.descriptionMessage.id} defaultMessage={props.descriptionMessage.defaultMessage} />
          {props.variant ? (
            <Progress mb={'230px'} mt={6} ml={'auto'} mr={'auto'} variant={props.variant} value={props.value} />
          ) : (
            <Progress mb={'230px'} mt={6} ml={'auto'} mr={'auto'} />
          )}
        </Text>
      </Card>
    </>
  );
};
export default ProgressBarComponent;
