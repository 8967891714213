import styled from 'styled-components';

export const ListItem = styled.ul`
  margin-top: 0;
  margin-bottom: 24px;
  > li {
    text-align: left;
  }
`;
export const ErrorMessageStyled = styled.div`
  word-wrap: break-word;
  margin: ${({theme}) => theme.space[2]}px 0;
  opacity: 0.5;
  font-style: italic;
`;
