/*
Get access to internationalized strings from inside store:
usage: globalIntl.formatMessage({id: "..."})
*/

import {intlShape, InjectedIntl} from 'react-intl';

let globalIntl: InjectedIntl;

const IntlGlobalProvider = (props: any, context: any) => {
  globalIntl = context.intl;
  return props.children;
};

IntlGlobalProvider.contextTypes = {
  intl: intlShape.isRequired,
};

export {globalIntl};
export default IntlGlobalProvider;
