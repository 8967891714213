import {IInitActivationData, SwapResReplacementOption} from './actionInterfaces';
import {FlowState, IPersistedFlowState, SelectedEquipmentParams} from '../../definitions/types';
import {asPromise, fetchParty} from './services';
import {
  applicationFlowChangedAction,
  receiveInitActivation,
  setInstallationTypeSelectedAction,
  setModemMacAddressAction,
  setSelectedReplacementOptionAction,
  setSelectedSwapEquipmentAction,
} from './actions';

const LOCALSTORE_ACTIVITATION_KEY = 'VIASAT_ACTIVATION';
export const storeFlowState = (flowState: IPersistedFlowState) => {
  localStorage.setItem(LOCALSTORE_ACTIVITATION_KEY, JSON.stringify(flowState));
};

export const persistActivationField = (field: string, value: any) => {
  const onGoingActivation: IPersistedFlowState | undefined = getOngoingActivation();

  if (onGoingActivation) {
    onGoingActivation[field] = value;
    storeFlowState(onGoingActivation);
  }
};

export const getOngoingActivation = (): IPersistedFlowState => {
  let ongoingActivation: IPersistedFlowState = {};

  try {
    const persistedActivation = localStorage.getItem(LOCALSTORE_ACTIVITATION_KEY);
    if (persistedActivation) {
      const activation = JSON.parse(persistedActivation);
      ongoingActivation = activation;
    }
  } catch {
    ongoingActivation = {};
  }

  return ongoingActivation;
};

export function initApp() {
  return async (dispatch: any) => {
    const onGoingActivation = getOngoingActivation();
    let initData: IInitActivationData = {
      installationTypeSelected: onGoingActivation.installationTypeSelected,
      applicationFlowState: FlowState.NotAuthorized,
    };

    if (onGoingActivation.applicationFlowState === FlowState.NotAuthorized) {
      storeFlowState(initData);
    } else {
      // ongoing authorized activation, attempt to get customers details
      if (!!Object.keys(onGoingActivation).length) {
        try {
          const party = await asPromise(fetchParty());

          if (onGoingActivation.applicationFlowState === FlowState.ActivationFinished) {
            storeFlowState({});
          } else {
            initData.applicationFlowState = onGoingActivation.applicationFlowState
              ? onGoingActivation.applicationFlowState
              : FlowState.NotAuthorized;
            initData.party = party;
            initData.commandId = onGoingActivation.commandId;
            initData.selectedProductKind = onGoingActivation.selectedProductKind;
            initData.alianzaAgreementUrl = onGoingActivation.alianzaAgreementUrl;
            initData.voIPPI = onGoingActivation.voipPi;
            initData.modemActivationStarted = onGoingActivation.modemActivationStarted;
            initData.productInstanceStatus = onGoingActivation.productInstanceStatus;
            initData.productInstanceId = onGoingActivation.productInstanceId;
            initData.modemMacAddress = onGoingActivation.modemMacAddress;
            initData.orderId = onGoingActivation.orderId;
            initData.selectedReplacementOption = onGoingActivation.selectedReplacementOption;
            initData.swapMacAddressValidated = onGoingActivation.swapMacAddressValidated;
          }
        } catch {
          // something went wrong, just assume this is a new activation
          storeFlowState({});
        }
      }
    }
    dispatch(receiveInitActivation(initData));
  };
}

export function setInstallationTypeSelected() {
  return (dispatch: any) => {
    persistActivationField('installationTypeSelected', true);
    dispatch(setInstallationTypeSelectedAction());
  };
}

export function setSelectedSwapEquipment(payload: SelectedEquipmentParams) {
  return (dispatch: any) => {
    persistActivationField('selectedProductKind', payload.selectedProductKind);
    dispatch(setSelectedSwapEquipmentAction(payload));
  };
}
export function setApplicationFlowState(flowState: FlowState) {
  return (dispatch: any) => {
    persistActivationField('applicationFlowState', flowState);
    dispatch(applicationFlowChangedAction({flowState}));
  };
}
export function setModemMacAddress(modemMacAddress: string) {
  return (dispatch: any) => {
    persistActivationField('modemMacAddress', modemMacAddress);
    dispatch(setModemMacAddressAction({modemMacAddress}));
  };
}

export function setSelectedReplacementOption(selectedReplacementOption: SwapResReplacementOption) {
  return (dispatch: any) => {
    persistActivationField('selectedReplacementOption', selectedReplacementOption);
    dispatch(setSelectedReplacementOptionAction(selectedReplacementOption));
  };
}
