import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

const MacValidationMessage: React.FC = ({className}: any) => {
  return (
    <span className={className}>
      <FormattedMessage
        id="invalid.macaddress"
        defaultMessage="A MAC Address must contain 12 digits, typically in one of these formats:"
      />
      <span className="list-item">11:22:33:44:55:66</span>
      <span className="list-item">11-22-33-44-55-66</span>
      <span className="list-item">1122.3344.5566</span>
    </span>
  );
};

export default styled(MacValidationMessage)`
  span:nth-of-type(2) {
    margin-top: 14px;
  }
  .list-item {
    line-height: 18px;
    display: list-item;
    margin-left: 40px;
  }
`;
