import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../store';
import {AlianzaAgreementPage} from './AlianzaAgreementPage';
import {getVoipSacAction, resetErrorAction} from '../../../store/activation/actions';
import {setApplicationFlowState} from '../../../store/activation/activationHelpers';
import Error from '../../common/Error';
import LoaderComponent from '../../common/LoaderComponent';
import {ErrorAPIMessage, FlowState} from '../../../definitions/types';

interface StateProps {
  isBusy: boolean;
  alianzaAgreementUrl: string;
  voipPi: string;
  error?: ErrorAPIMessage;
  voipServiceActivationCode: string;
}
interface DispatchProps {
  resetError: () => void;
  getVoipSac: () => void;
  onActivationFinish: () => void;
}
type Props = StateProps & DispatchProps;

const AlianzaAgreementPageContainer: React.FC<Props> = (props) => {
  const [modalVisibility, setModalVisibility] = useState<boolean>(true);
  const finish = () => {
    props.onActivationFinish();
    window.location.reload();
  };

  useEffect(() => {
    if (!props.voipServiceActivationCode) props.getVoipSac();
    // eslint-disable-next-line
  }, [props.voipServiceActivationCode]);
  const handleRetry = () => {
    props.getVoipSac();
  };
  const closeModal = () => {
    setModalVisibility(false);
  };
  if (props.isBusy) {
    return <LoaderComponent />;
  }

  return props.error ? (
    <Error
      message={props.error}
      enableReload={true}
      onReload={() => {
        props.resetError();
        handleRetry();
      }}
    />
  ) : (
    <AlianzaAgreementPage
      {...props}
      onRetry={handleRetry}
      isModalOpen={modalVisibility}
      alianzaUrl={props.alianzaAgreementUrl}
      closeModal={closeModal}
      onFinish={finish}
      serviceActivationCode={props.voipServiceActivationCode}
    />
  );
};

export default connect(
  (state: IRootState) => ({
    isBusy: state.activation.isBusy,
    error: state.activation.error,
    alianzaAgreementUrl: state.activation.alianzaAgreementUrl,
    voipServiceActivationCode: state.activation.voipServiceActivationCode,
    voipPi: state.activation.voipPi,
  }),
  (dispatch) => ({
    resetError: () => dispatch(resetErrorAction()),
    getVoipSac: () => dispatch(getVoipSacAction.started({})),
    onActivationFinish: () => dispatch(setApplicationFlowState(FlowState.ActivationFinished) as any),
  })
)(AlianzaAgreementPageContainer as React.FC) as React.ComponentType<Partial<Props>>;
