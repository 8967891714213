import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../store';
import {resetErrorAction, updateVoIPStatusAction} from '../../../store/activation/actions';
import {setApplicationFlowState} from '../../../store/activation/activationHelpers';

import {ErrorAPIMessage, FlowState} from '../../../definitions/types';
import Error from '../../common/Error';
import {VoIPActivationPage} from './voIPActivationPage';

interface StateProps {
  isBusy: boolean;
  error?: ErrorAPIMessage;
  voIPActivated: boolean;
  voipPi: string;
}

interface DispatchProps {
  updateVoIPStatus: () => void;
  resetError: () => void;
  setVoIPStatusUpdated: () => void;
}

type VoIPStatusUpdatePageProps = StateProps & DispatchProps;
const VoIPActivationPageContainer: React.FC<VoIPStatusUpdatePageProps> = (props) => {
  const handleRetry = () => {
    props.updateVoIPStatus();
  };
  useEffect(() => {
    if (!props.isBusy) {
      props.updateVoIPStatus();
    } // eslint-disable-next-line
  }, [1]);
  useEffect(() => {
    if (!props.isBusy && props.voIPActivated) {
      props.setVoIPStatusUpdated();
    } // eslint-disable-next-line
  }, [props.voIPActivated]);
  const {error} = props;
  return error ? (
    <Error
      message={error}
      enableReload={true}
      onReload={() => {
        props.resetError();
        handleRetry();
      }}
    />
  ) : (
    <VoIPActivationPage onRetry={handleRetry} activationFailed={props.voIPActivated === false} />
  );
};

export default connect(
  (state: IRootState) => ({
    isBusy: state.activation.isBusy,
    error: state.activation.error,
    voIPActivated: state.activation.voIPActivated,
    voipPi: state.activation.voipPi,
  }),
  (dispatch) => ({
    resetError: () => dispatch(resetErrorAction()),
    updateVoIPStatus: () => dispatch(updateVoIPStatusAction.started({})),
    setVoIPStatusUpdated: () => {
      dispatch(setApplicationFlowState(FlowState.VoIPActivated) as any);
    },
  })
)(VoIPActivationPageContainer as any) as React.ComponentType<Partial<VoIPStatusUpdatePageProps>>;
