import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Card, Text, Progress} from '@viasat/components';

const ModemActivationLoading: React.FunctionComponent = () => {
  return (
    <>
      <Card data-test-el="activate-page-loading-card">
        <Text textAlign="center" mt={3} mb={6} display="block">
          <FormattedMessage
            id="service.activation.info1"
            defaultMessage="Activating services, please wait while the modem reboots,"
          />
          <br />
          <FormattedMessage id="service.activation.info2" defaultMessage="This may take several minutes…" />
          <Progress mb={'230px'} mt={6} ml={'auto'} mr={'auto'} />
        </Text>
      </Card>
    </>
  );
};

export default ModemActivationLoading;
