import React from 'react';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

import {withTheme} from 'styled-components';
import {StyledProps} from '../../theme/theme';
import {Button, Card} from '@viasat/components';
import ButtonContainer from './ButtonContainer';
import {TextContainer} from './AppLayout';
import Error from '@material-ui/icons/Error';
import RestartButton from './RestartButton';
export interface GenericErrorComponentProps {
  hideCancel?: boolean;
  retryButtonText?: {
    id?: string;
    defaultMessage: string;
  };
  onRetry?: () => void;
  headerDescriptionMessage: {
    id?: string;
    defaultMessage: string;
  };
  bodyMessage?: {
    id?: string;
    defaultMessage: string;
  };
}
const GenericErrorComponent: React.FC<GenericErrorComponentProps & StyledProps> = ({
  theme,
  onRetry,
  headerDescriptionMessage,
  bodyMessage,
  hideCancel,
  retryButtonText,
}) => {
  return (
    <>
      <Card pt={'80px'} pb={'80px'} textAlign="center">
        {<Error style={{height: '140px', width: '140px', color: theme.colors.RED}} />}

        <TextContainer
          data-test-el="generic-error-header-text"
          fontWeight="bold"
          fontSize={3}
          mr="auto"
          ml="auto"
          mt={3}
          mb={2}
          textAlign="center"
        >
          <FormattedHTMLMessage
            id={headerDescriptionMessage.id || 'id'}
            defaultMessage={headerDescriptionMessage.defaultMessage}
          />
        </TextContainer>
        {bodyMessage && (
          <TextContainer>
            <FormattedHTMLMessage id={bodyMessage.id || 'id'} defaultMessage={bodyMessage.defaultMessage} />
          </TextContainer>
        )}
      </Card>

      <ButtonContainer>
        {!hideCancel && <RestartButton />}

        {onRetry && (
          <Button onClick={onRetry} data-test-el="generic-error-component-retry-button">
            <FormattedMessage
              id={retryButtonText && retryButtonText.id ? retryButtonText.id : 'page.retry.button'}
              defaultMessage={
                retryButtonText && retryButtonText.defaultMessage ? retryButtonText.defaultMessage : 'Retry'
              }
            />
          </Button>
        )}
      </ButtonContainer>
    </>
  );
};

export default withTheme(GenericErrorComponent);
