import React from 'react';
import {BodyContainer} from '../AppLayout';
import {Footer, Header} from '@viasat/components';
import logoImage from '../../../images/viasat-logo.svg';
import styled from 'styled-components';
import {IframeModalHeader, IframeModalHeaderProps} from './IframeModalHeader';
import {theme} from '../../../theme/theme';

interface IframeModalInterface {
  className: string;
  isOpen: boolean;
  closeModal: () => void;
  iframeUrl: string;
  modalTitle: string;
  height?: string;
}
type IframeModalProps = IframeModalHeaderProps & IframeModalInterface;
const footerHeight = '58px';
const IframeModal: React.FC<IframeModalProps> = ({
  className,
  isOpen,
  closeModal,
  modalInfoText,
  iframeUrl,
  modalTitle,
  closeButtonText,
  footerItems,
}: IframeModalProps) => {
  return (
    <div className={`modal ${className} ${isOpen ? 'visible' : ''}`} data-test-el="iframe-modal">
      <BodyContainer>
        <Header
          logoImage={logoImage}
          actions={
            <IframeModalHeader
              closeModal={closeModal}
              closeButtonText={closeButtonText}
              modalInfoText={modalInfoText}
            />
          }
        />
        {isOpen && <iframe title={modalTitle} className="content" src={iframeUrl} />}
        {footerItems && (
          <Footer className={'modal-footer'} bg={theme.colors.WHITE} height={'58px'} content={footerItems} />
        )}
      </BodyContainer>
    </div>
  );
};

const styledIframetModal = styled(IframeModal)`
  &.visible {
    display: block;
  }
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 4;
  width: 100vw;
  .content {
    background-color: white;
    height: ${({footerItems, height}) =>
      footerItems ? `calc(${height} - 84px - ${footerHeight})` : `calc(${height} - 84px)`};
    width: 100vw;
    box-sizing: unset;
    border: none;
  }
  .modal-footer {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    > div {
    margin-left: 0;
  }
`;

styledIframetModal.defaultProps = {
  height: '100vh',
};

export default styledIframetModal as any;
