import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from '../../../store';
import ValidateDetailsPage from './ValidateDetailsPage';
import {activateQoiAction, fetchPartyAction, resetErrorAction} from '../../../store/activation/actions';
import {setApplicationFlowState} from '../../../store/activation/activationHelpers';
import Error from '../../common/Error';
import LoaderComponent from '../../common/LoaderComponent';
import {CustomerDetails, FlowState, InstallationTypesCodes} from '../../../definitions/types';

const ValidateDetailsPageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    activateQoi: () => dispatch(activateQoiAction.started({})),
    validateDetails: () => dispatch(setApplicationFlowState(FlowState.DetailsValidated) as any),
    fetchCustomer: () => dispatch(fetchPartyAction.started({})),
    resetError: () => dispatch(resetErrorAction()),
  };
  const stateProps = useSelector((state: IRootState) => {
    return {
      party: state.activation.party as CustomerDetails,
      isBusy: state.activation.isBusy,
      error: state.activation.error,
      productInstanceStatus: state.activation.productInstanceStatus as string,
      installationType: state.system.installationType as InstallationTypesCodes,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };

  useEffect(() => {
    props.fetchCustomer();
  }, []);

  const retry = () => {
    props.fetchCustomer();
    props.resetError();
  };
  if (props.isBusy) {
    return <LoaderComponent />;
  }

  if (props.error) {
    return <Error message={props.error} enableReload={true} onReload={retry} />;
  }

  return <ValidateDetailsPage {...props} />;
};

export default ValidateDetailsPageContainer;
