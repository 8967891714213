import {Dispatch, Middleware} from 'redux';
import {
  activateQoiAction,
  getContractInstanceAction,
  ACTION_NAMESPACE,
  FETCH_PARTY_ACTION,
} from '../activation/actions';
import {CountryCodes, InstallationSubType} from '../../definitions/types';
import {countryNeedsECA} from '../../components/util/appUtilities';

const activationMiddleware: Middleware = (store: any) => (next: Dispatch) => async (action: any) => {
  if (action.type !== `${ACTION_NAMESPACE}/${FETCH_PARTY_ACTION}_STARTED`) {
    return next(action);
  }

  next(action);

  const state = store.getState();
  const installationSubtype = state.system.installationSubtype;
  const installationCountry = state.system.country as CountryCodes;

  const isSwap =
    installationSubtype === InstallationSubType.VCI_SWAP || installationSubtype === InstallationSubType.RES_SWAP;

  if (installationSubtype !== InstallationSubType.VOIP && !isSwap) {
    next(activateQoiAction.started({}));
  }

  if (countryNeedsECA(installationCountry, installationSubtype)) {
    next(getContractInstanceAction.started({}));
  }
};

export default activationMiddleware;
