import React from 'react';
import {PageContainer, TextContainer} from '../../common/AppLayout';
import {Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import {AuthorizationForm} from './serviceActivateComponents/AuthorizationForm';
import {ErrorAPIMessage, InstallationSubType, InstallationTypesCodes} from '../../../definitions/types';
import {ConfirmationDialog} from '../../../shared-components/ConfirmationDialog';
export interface Props {
  isBusy: boolean;
  error?: ErrorAPIMessage;
  onSubmit: (
    serviceActivationCode: string,
    installerId?: string,
    macAddress?: string,
    siteId?: string,
    dealerId?: string
  ) => void;
  installationType: InstallationTypesCodes;
  installationSubtype: InstallationSubType;
  promptForLegacyRedirect?: boolean;
  redirectToLegacyPortal: (proceed: boolean) => void;
  title: string;
}

const ServiceActivatePage: React.FC<Props> = ({
  isBusy,
  onSubmit,
  installationSubtype,
  installationType,
  promptForLegacyRedirect,
  redirectToLegacyPortal,
}) => {
  const isVci = installationType === InstallationTypesCodes.VCI;
  return (
    <PageContainer data-test-el="service-activate-page">
      <Title>
        <FormattedMessage id="service.activate.title" defaultMessage="Welcome to Service Activation" />
      </Title>
      <TextContainer>
        {isVci ? (
          <FormattedMessage
            id="vci.service.activate.info"
            defaultMessage="The necessary information required below can be found in the Work Order."
          />
        ) : (
          <FormattedMessage
            id="service.activate.info"
            defaultMessage={`The necessary information required below can be found in the Work Order.`}
          />
        )}
      </TextContainer>
      <AuthorizationForm
        isBusy={isBusy}
        onSubmit={onSubmit}
        installationType={installationType}
        installationSubtype={installationSubtype}
      />
      {promptForLegacyRedirect && (
        <ConfirmationDialog
          disableBackdropClick={true}
          handleClose={(proceed: boolean) => {
            redirectToLegacyPortal(proceed);
          }}
          open={promptForLegacyRedirect}
          title={{
            id: 'error.authorize.noProductInstance.title',
            defaultMessage: 'No product instance found',
          }}
          body={{
            id: 'error.authorize.noProductInstance',
            defaultMessage:
              'No product instance was found for the Service Activation Code/Work Order ID entered. Please reattempt in the Legacy Portal or contact Installer Relations.',
          }}
        />
      )}
    </PageContainer>
  );
};

export default ServiceActivatePage;
