import React from 'react';
import {QoiCategory} from '../../../../definitions/types';
import InstallStep, {InstallStepContainer} from '../../../common/InstallStep';
import Settings from '@material-ui/icons/Settings';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import RouterIcon from '@material-ui/icons/Router';
import {qoiActivationSteps} from '../../../../definitions/constants';
export interface Props {
  categories?: QoiCategory[];
}

const InstallationSteps: React.FunctionComponent<Props> = ({categories}) => {
  const getColor = (array: QoiCategory[]) => (val: string[]) => {
    let item = array.find((item) => val.includes(item.name));
    return item ? item.color : 'WHITE';
  };

  const getColorInCategories = getColor(categories || []);

  return (
    <InstallStepContainer>
      <InstallStep
        label={{key: 'quality.validate.swversion.label', default: 'SOFTWARE VERSION'}}
        icon={<Settings />}
        status={categories ? getColorInCategories(qoiActivationSteps[0]) : 'WHITE'}
      />
      <InstallStep
        label={{key: 'quality.validate.oduequipment.label', default: 'ODU EQUIPMENT CABLE & ANTENNA'}}
        icon={<SettingsInputAntennaIcon />}
        status={categories ? getColorInCategories(qoiActivationSteps[1]) : 'WHITE'}
      />
      <InstallStep
        label={{key: 'quality.validate.antennapointing.label', default: 'ANTENNA POINTING'}}
        icon={<GpsFixedIcon />}
        status={categories ? getColorInCategories(qoiActivationSteps[2]) : 'WHITE'}
      />
      <InstallStep
        label={{key: 'quality.validate.installeddevices.label', default: 'INSTALLED DEVICES'}}
        icon={<RouterIcon />}
        status={categories ? getColorInCategories(qoiActivationSteps[3]) : 'WHITE'}
      />
    </InstallStepContainer>
  );
};

export default InstallationSteps;
