import React, {useState} from 'react';
import {path} from 'ramda';
import {PageContainer, TextContainer} from '../../common/AppLayout';

import ButtonContainer from '../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl.macro';
import {CustomerDetails, InstallationTypesCodes} from '../../../definitions/types';
import RestartButton from '../../common/RestartButton';
import {Button, Card, TextField, Title, Text, FormControlLabel, Checkbox} from '@viasat/components';

export interface Props {
  party: CustomerDetails;
  installationType: InstallationTypesCodes;
  isBusy: boolean;
  productInstanceStatus: string;
  validateDetails: () => void;
}

const getCustomerAddress = (party: CustomerDetails) => {
  const addressLines = path(['pii', 'address', 'lines'], party);
  return addressLines ? (addressLines as string[]).join('\n') : addressLines;
};

const ValidateDetailsPage: React.FC<Props> = ({
  validateDetails,
  party,
  isBusy,
  productInstanceStatus,
  installationType,
}) => {
  const [isDataConfirmed, setDataConfirmed] = useState(false);

  return (
    <PageContainer data-test-el="validate-details-page">
      <Title data-test-el="validate-details-title">
        {installationType === InstallationTypesCodes.GBS ? (
          <FormattedMessage id="details.validate.title.gbs" defaultMessage="Business Details" />
        ) : (
          <FormattedMessage id="details.validate.title" defaultMessage="Customer Details" />
        )}
      </Title>
      <TextContainer>
        <FormattedMessage
          id="details.validate.intro"
          defaultMessage="If Customer details below are incorrect, please contact Installer Relations."
        />
      </TextContainer>
      {party && (
        <Card>
          <>
            <Text textAlign="center" mt={3} mb={4} display="block">
              <FormattedMessage
                id="details.validate.instructions"
                defaultMessage="Please validate and confirm the customer details and location below."
              />
            </Text>
            <TextField
              id="fullname"
              fullWidth={true}
              label={<FormattedMessage id="details.validate.name.label" defaultMessage="Name" />}
              value={path(['pii', 'fullName'], party)}
              mt={1}
              mb={3}
              disabled={true}
            />
            <TextField
              id={`addressline`}
              fullWidth={true}
              label={<FormattedMessage id="details.validate.address.label" defaultMessage="Address" />}
              value={getCustomerAddress(party)}
              mt={1}
              mb={3}
              disabled={true}
              multiline={true}
            />
            <TextField
              id="postalcode"
              fullWidth={true}
              label={<FormattedMessage id="details.validate.zip.label" defaultMessage="ZIP / Postal Code" />}
              value={path(['pii', 'address', 'postCode'], party)}
              mt={1}
              mb={3}
              disabled={true}
            />
            <TextField
              id="latitude"
              fullWidth={true}
              label={<FormattedMessage id="details.validate.latitude.label" defaultMessage="Latitude" />}
              value={path(['pii', 'address', 'coordinates', 'latitude'], party)}
              mt={1}
              mb={3}
              disabled={true}
            />
            <TextField
              id="longitude"
              fullWidth={true}
              label={<FormattedMessage id="details.validate.longitude.label" defaultMessage="Longitude" />}
              value={path(['pii', 'address', 'coordinates', 'longitude'], party)}
              mt={1}
              mb={3}
              disabled={true}
            />
            <TextField
              id="modemtype"
              fullWidth={true}
              label={<FormattedMessage id="details.validate.modemtype.label" defaultMessage="Modem Equipment Type" />}
              value={path(['modemType'], party)}
              mt={1}
              mb={3}
              disabled={true}
            />
            <FormControlLabel
              mb={3}
              control={
                <Checkbox
                  checked={isDataConfirmed}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDataConfirmed(event.target.checked)}
                  value="confirmed"
                />
              }
              label={
                <FormattedMessage
                  id="details.validate.confirm.check"
                  defaultMessage="I confirm the customer details are correct"
                />
              }
            />
          </>
        </Card>
      )}
      <ButtonContainer>
        <RestartButton />
        <Button
          disabled={!isDataConfirmed || isBusy}
          onClick={validateDetails}
          data-test-el="validate-details-continue-button"
        >
          <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
        </Button>
      </ButtonContainer>
    </PageContainer>
  );
};

export default ValidateDetailsPage;
