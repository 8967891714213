import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {createBrowserHistory, History} from 'history';
import createRootReducer, {rootEpic} from '../store';
import {createEpicMiddleware} from 'redux-observable';
import {ajax as rxAjax} from 'rxjs/ajax';
import {routerMiddleware} from 'connected-react-router';
import env from './env';
import {InstallationSubType} from '../definitions/types';
import activationMiddleware from '../store/activation/middleware';

declare let process: any;
const COOKIE_XSRF_TOKEN = 'xsrf-token';
const HEADER_XSRF_TOKEN = 'x-xsrf-token';
let store: any = null;
const {NODE_ENV} = process.env;
export const browserHistory: History = createBrowserHistory();
const router = routerMiddleware(browserHistory);
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const initStore = () => {
  const store = configureStore({});
  storeAccess.set(store);
  return store;
};

export const storeAccess = {
  set: (s: any) => (store = s),
  get: () => store,
};
const getCookie = (cookieName) => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieAttributeAndValue = decodedCookie.split(';');
  for (let i = 0; i < cookieAttributeAndValue.length; i++) {
    let cookieValue = cookieAttributeAndValue[i];
    while (cookieValue.charAt(0) === ' ') {
      cookieValue = cookieValue.substring(1);
    }
    if (cookieValue.indexOf(name) === 0) {
      return cookieValue.substring(name.length, cookieValue.length);
    }
  }
  return '';
};
const getApiUrl = (url: string, isMock: boolean) => {
  return env.apiEndpoint + url;
};
const subTypeMap = {
  ...InstallationSubType,
  [InstallationSubType.VCI_SWAP]: 'SWAP',
  [InstallationSubType.RES_SWAP]: 'SWAP',
  [InstallationSubType.WHOLESALE_SWAP]: 'SWAP',
  [InstallationSubType.VCI_SATELITE_INTERNET]: 'SAT',
};
function getEnumKeyByEnumValue(enumObj: any, enumValue: string) {
  let key = Object.keys(enumObj).find((x) => enumObj[x] === enumValue);
  return key || '';
}
export const getApiHeaders = () => {
  const csrfToken = getCookie(COOKIE_XSRF_TOKEN);
  let headers = {
    'Content-Type': 'application/vnd.viasat.oc.v1.0+json',
    Accept: 'application/vnd.viasat.oc.v1.0+json',
  };

  if (csrfToken) {
    headers[HEADER_XSRF_TOKEN] = csrfToken;
  }
  let locale = 'en';
  let installationType = 'res';
  let country = 'us';
  let installationSubType =
    subTypeMap[getEnumKeyByEnumValue(InstallationSubType, InstallationSubType.SATELITE_INTERNET as string)];

  if (storeAccess.get()) {
    let state = storeAccess.get().getState();
    locale = state.system.locale;

    if (state.system.installationType && state.system.country && state.system.installationSubtype) {
      installationType = state.system.installationType;
      country = state.system.country;
      installationSubType = subTypeMap[getEnumKeyByEnumValue(InstallationSubType, state.system.installationSubtype)];
    }
  }

  Object.assign(headers, {
    'Accept-Language': locale,
    Tenancy: `${installationType.toUpperCase()},${country.toUpperCase()},${installationSubType.toUpperCase()}`,
  });
  return headers;
};

export const ajax = {
  post: (url: string, body: any, isMock: boolean = false) =>
    rxAjax({
      method: 'POST',
      url: getApiUrl(url, isMock),
      body: body ? JSON.stringify(body) : undefined,
      headers: getApiHeaders(),
      withCredentials: true,
    }),
  get: (url: string, isMock: boolean = false) =>
    rxAjax({
      method: 'GET',
      url: getApiUrl(url, isMock),
      headers: getApiHeaders(),
      withCredentials: true,
    }),
  put: (url: string, body: any, isMock: boolean = false) =>
    rxAjax({
      method: 'PUT',
      url: getApiUrl(url, isMock),
      headers: getApiHeaders(),
      body: body ? JSON.stringify(body) : undefined,
      withCredentials: true,
    }),
  patch: (url: string, body: any, isMock: boolean = false) =>
    rxAjax({
      method: 'PATCH',
      url: getApiUrl(url, isMock),
      headers: getApiHeaders(),
      body: body ? JSON.stringify(body) : undefined,
      withCredentials: true,
    }),
  delete: (url: string, body: any, isMock: boolean = false) =>
    rxAjax({
      method: 'DELETE',
      url: getApiUrl(url, isMock),
      body: {},
      withCredentials: true,
    }),
};
export const deleteCsrfCookie = () => {
  window.document.cookie = COOKIE_XSRF_TOKEN + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
export default function configureStore(initialState: any) {
  const epicMiddleware = createEpicMiddleware();
  const middlewares = [activationMiddleware, epicMiddleware, thunk, router];

  const store = createStore(
    createRootReducer(browserHistory),
    initialState,
    NODE_ENV === 'production' || NODE_ENV === 'test'
      ? applyMiddleware(...middlewares)
      : composeEnhancers(applyMiddleware(...middlewares))
  );

  epicMiddleware.run(rootEpic);

  return store;
}
