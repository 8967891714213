import React, {useState} from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../store';
import ContractPage from './ContractPage';
import {
  getContractInstanceAction,
  checkContractStatusAction,
  resetErrorAction,
} from '../../../store/activation/actions';
import {setApplicationFlowState} from '../../../store/activation/activationHelpers';
import {LoaderComponent} from '@viasat/components';
import {ErrorAPIMessage, FlowState, IContractDetails} from '../../../definitions/types';
import {pathOr} from 'ramda';
import Error from '../../common/Error';
export interface StateProps {
  contractDetails: IContractDetails;
  isBusy: boolean;
  error?: ErrorAPIMessage;
}
export interface DispatchProps {
  getContractInstance: () => void;
  checkContractStatus: () => void;
  continueToNextStep: () => void;
  resetError: () => void;
}
type Props = StateProps & DispatchProps;

const ContractPageContainer: React.FC<Props> = (props) => {
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const openContractModal = () => {
    setModalVisibility(true);
  };

  const closeContractModal = () => {
    setModalVisibility(false);
    props.checkContractStatus();
  };

  const retry = () => {
    props.getContractInstance();
    props.resetError();
  };

  if (props.isBusy) {
    return <LoaderComponent />;
  }

  return props.error ? (
    <Error message={props.error} enableReload={true} onReload={retry} />
  ) : (
    <ContractPage
      {...props}
      openContractModal={openContractModal}
      closeContractModal={closeContractModal}
      isModalOpen={modalVisibility}
      contractUrl={pathOr('', ['contractDetails', 'signerUrl'], props)}
      signedStatus={pathOr(false, ['contractDetails', 'signedStatus'], props)}
      isBusyCheckingStatus={pathOr(false, ['contractDetails', 'isBusyCheckingStatus'], props)}
      isContractUnsignedAfterCheck={pathOr(false, ['contractDetails', 'isContractUnsignedAfterCheck'], props)}
      onFinish={props.continueToNextStep}
    />
  );
};

export default connect(
  (state: IRootState) => ({
    contractDetails: state.activation.contractDetails,
    isBusy: state.activation.isBusy,
    error: state.activation.error,
  }),
  (dispatch) => ({
    getContractInstance: () => dispatch(getContractInstanceAction.started({})),
    checkContractStatus: () => dispatch(checkContractStatusAction.started({})),
    continueToNextStep: () => dispatch(setApplicationFlowState(FlowState.QoiValidated) as any),
    resetError: () => dispatch(resetErrorAction()),
  })
)(ContractPageContainer as React.FC) as React.ComponentType<Partial<Props>>;
