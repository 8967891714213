import {ActivationStatus} from '../components/util/ActivationStatus';
import {EquipmentProductKind} from '../components/util/EquipmentUtility';
import {SwapResReplacementOption, UpgradeEquipmentDetails} from '../store/activation/actionInterfaces';
import {InternalErrorAPIMessage} from '../shared-components/ErrorMessage';

declare global {
  interface Window {
    env: any;
  }
}

export interface CustomerDetails {
  pii: CustomerPersonalDetails;
  modemType: string;
}

export interface ErrorAPIMessage {
  friendlyMessage: string;
  internalError?: InternalErrorAPIMessage;
  status?: number;
  xBEPExecutionId?: number;
  intlId?: string;
}

export interface IActivationState extends IPersistedFlowState {
  readonly isBusy: boolean;
  readonly isModemOperationBusy: boolean;
  readonly error?: ErrorAPIMessage;
  readonly party?: CustomerDetails;
  readonly contractDetails?: IContractDetails;
  readonly promptForLegacyRedirect?: boolean;
  readonly redirectUrl?: string;
}

export interface IPersistedFlowState {
  productInstanceStatus?: string;
  installationTypeSelected?: boolean;
  commandId?: string;
  qoiDetails?: QoiDetails;
  modemActivationStarted?: boolean;
  modemActivationStatus?: string;
  voipPi?: string;
  alianzaAgreementUrl?: string;
  voIPActivated?: boolean;
  voipActivationStatus?: ActivationStatus;
  applicationFlowState?: FlowState;
  hotspotActivationStarted?: boolean;
  hotspotActivationStatus?: string;
  wifiActivationStarted?: boolean;
  wifiActivationStatus?: string;
  creditCardMatched?: boolean;
  gbsVoipDetails?: GbsVoipDetails[];
  upgradeEquipmentDetails?: UpgradeEquipmentDetails[];
  installedVCIEquipmentDetails?: UpgradeEquipmentDetails[];
  installedResEquipmentDetails?: SwapResReplacementOption[];
  replacementProductExists?: boolean;
  selectedProductKind?: EquipmentProductKind;
  modemMacAddress?: string;
  productInstanceId?: string;
  voipServiceActivationCode?: string;
  swapMacAddressValidated?: boolean;
  activationUrl?: string;
  orderId?: string;
  swapOrderGenerationTimestamp?: number;
  selectedReplacementOption?: SwapResReplacementOption;
  openOrderExists?: boolean;
}
export interface CustomerPersonalDetails {
  fullName: string;
  address: CustomerAddress;
  contactDetails: CustomerContact;
}

export interface CustomerCoordinates {
  latitude: string;
  longitude: string;
}

export interface CustomerAddress {
  lines: string[];
  postCode: string;
  coordinates: CustomerCoordinates;
}

export interface CustomerContact {
  emailAddress: string;
  phoneNumber: string;
  mobileNumber: string;
}

export interface QoiDetails {
  status: QoiStatus;
  categories: QoiCategory[];
}
export enum ILocale {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
}
export interface UiConfigResult {
  defaultLocale: ILocale;
  activationUrl: string;
  qoiActivationSteps: string[];
}

export interface QoiCategory {
  id: string;
  name: string;
  color: QoiStepState | QoiStepStateEnum;
  explanations: string[];
}
export interface ErrorItemsByCategory {
  name: string;
  isExpanded: boolean;
  explanations: string[];
}

export interface GbsVoipDetails {
  status: ActivationStatus;
  previousState: ActivationStatus;
  voipPi: string;
  lineNumber: string;
  isLoading: boolean;
  error: string;
}

export enum FlowState {
  NotAuthorized = 'NotAuthorized',
  Authorized = 'Authorized',
  DetailsValidated = 'DetailsValidated',
  ContactValidated = 'ContactValidated',
  QoiValidated = 'QoiValidated',
  ModemActivated = 'ModemActivated',
  ActivationFinished = 'ActivationFinished',
  TwoPointFourGHzSubmitted = 'TwoPointFourGHzSubmitted',
  FiveGHzSubmitted = 'FiveGHzSubmitted',
  EquipmentTrackingSubmitted = 'EquipmentTrackingSubmitted',
  WifiActivated = 'WifiActivated',
  CreditCardValidationNeeded = 'CreditCardValidationNeeded',
  ECANeeded = 'ECANeeded',
  VoIPActivated = 'VoIPActivated',
  EquipmentDetailsValidated = 'EquipmentDetailsValidated',
  CreditCardValidated = 'CreditCardValidated',
  SwapUPS = 'SwapUPS',
  SwapPOS = 'SwapPOS',
  SwapNetworkController = 'SwapNetworkController',
  SwapAccessPoint = 'SwapAccessPoint',
  SwapCreated = 'SwapCreated',
  SwapDone = 'SwapDone',
}

export interface ApplicationFlowStep {
  component: React.FC<any>;
  guard: (state: IActivationState) => boolean | undefined;
}

export interface ApplicationFlowStepMap {
  [key: string]: ApplicationFlowStep;
}
export enum EquipmentTypeEnum {
  ODU = 'ODU',
  IDU = 'IDU',
}

export interface AccessPoint {
  serialNumber: string;
  macAddress: string;
}

export interface Equipment {
  posSerialNumber: string;
  upsSerialNumber: string;
}
export interface ActivateVciEquipmentParams {
  productKind: EquipmentProductKind.NETWORK_CONTROLLER | EquipmentProductKind.ACCESS_POINT;
  accessPoint: AccessPoint;
}
export interface SwapTrackedEquipmentParams {
  serialNumber: string;
  productKind: EquipmentProductKind;
}
export interface SelectedEquipmentParams {
  selectedProductKind: EquipmentProductKind;
}
export enum QoiStepStateEnum {
  GREEN = 'GREEN',
  RED = 'RED',
  GRAY = 'GRAY',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
}

export type QoiStepState = 'GREEN' | 'RED' | 'GRAY' | 'WHITE' | 'YELLOW';
export type QoiStatus = 'SUCCEEDED' | 'RETRY' | 'FAILED';
/**
 * Some of the below enum values (VCI_SWAP, RES_SWAP,...)
 * are modified in StoreUtils
 * before being submitted to the backend
 *
 */
export enum InstallationSubType {
  VOIP = 'VOIP',
  WHOLESALE = 'WHOLESALE',
  SATELITE_INTERNET = 'SAT',
  VCI_SATELITE_INTERNET = 'VCI_SAT',
  PARTNER_VCI = 'PARTNER_VCI',
  UPGRADE = 'UPGRADE',
  VCI_SWAP = 'VCI_SWAP',
  RES_SWAP = 'RES_SWAP',
  WHOLESALE_SWAP = 'WHOLESALE_SWAP',
}

export enum InstallationTypesCodes {
  RES = 'RES',
  VCI = 'VCI',
  GBS = 'GBS',
  WHOLESALE = 'WHOLESALE',
}
export interface IFormattedMessage {
  defaultMessage: string;
  id: string;
}
export interface IInstallationSubType {
  name: string;
  code: InstallationSubType;
  key: string;
}
export interface IInstallationTypes {
  name: string;
  code: InstallationTypesCodes;
  key: string;
  subtypes: Array<IInstallationSubType>;
}
export interface ICountries {
  name: string;
  code: string;
  key: string;
  installationCodes: Array<InstallationTypesCodes>;
  installationSubtypeCodes: Array<InstallationSubType>;
  needsCreditCardValidation?: boolean;
  needsECA: Array<InstallationSubType>;
}

export enum CountryCodes {
  BR = 'BR',
  GT = 'GT',
  MX = 'MX',
  MXCFE = 'MXCFE',
  US = 'US',
  NG = 'NG',
}

export interface IContractDetails {
  signerUrl?: string;
  signedStatus?: boolean;
  isBusyCheckingStatus?: boolean;
  isContractUnsignedAfterCheck?: boolean;
}
