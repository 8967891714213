import {interval, Observable, of, race, timer} from 'rxjs';
import {Action} from 'redux';
import {combineEpics, ofType, StateObservable} from 'redux-observable';
import {catchError, filter, flatMap, map, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import {checkContractStatusAction, getContractInstanceAction, restartAction} from '../../actions';
import {createContract, fetchContract} from '../../services';
import {generateMessage} from '../../../../shared-components/ErrorMessage';
import {path} from 'ramda';
import env from '../../../../utils/env';
import {handleActivationError} from '../appUtility/appUtilityEpic';

export const fetchContractActionEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(getContractInstanceAction.started.match),
    switchMap(() =>
      createContract().pipe(
        map((response: any) =>
          getContractInstanceAction.done({
            result: {contractInfo: response.response},
            params: {},
          })
        ),
        catchError((error) =>
          of(
            getContractInstanceAction.failed({
              error: generateMessage('Could not retrieve contract data!', 'contract.error.retrieving', error),
              params: {},
            })
          )
        )
      )
    )
  );
export const checkContractStatusEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(checkContractStatusAction.started.match),
    switchMap((action) =>
      fetchContractStatus().pipe(
        take(1),
        flatMap((response: any) => {
          let statusResponse;
          if (path(['result', 'contractInfo', 'signedStatus'], response)) {
            statusResponse = response;
          } else {
            statusResponse = {
              result: {
                contractInfo: {
                  signerUrl: state$.value.activation.contractDetails.signerUrl,
                  signedStatus: false,
                },
              },
              params: {},
            };
          }
          return of(checkContractStatusAction.done(statusResponse));
        }),
        takeUntil(action$.pipe(ofType(restartAction))),
        catchError((thrownError) =>
          of(
            checkContractStatusAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError.error),
              params: {},
            })
          )
        )
      )
    )
  );

function fetchContractStatus() {
  return race(
    timer(0, Number(env.pollingInterval)).pipe(
      switchMap(() => fetchContract().pipe(catchError((err) => handleActivationError(err)))),
      skipWhile((response: any) => response.response.signedStatus === false),
      take(1),
      map((response) => ({
        result: {
          contractInfo: {
            signerUrl: response.response.signerUrl,
            signedStatus: response.response.signedStatus,
          },
        },
        params: {},
      }))
    ),
    interval(Number(env.contractStatusDelay))
  );
}

export const ContractEpic = combineEpics(fetchContractActionEpic, checkContractStatusEpic);
