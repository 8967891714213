import * as React from 'react';
import styled from 'styled-components';
import {pathOr} from 'ramda';
import {color, ColorProps, fontSize, space, SpaceProps, TypographyProps} from 'styled-system';
import {globalIntl} from '../utils/IntlGlobalProvider';
import {FormattedMessage} from 'react-intl';
import RestartButton from '../components/common/RestartButton';
import ButtonContainer from '../components/common/ButtonContainer';
import {Button, Title} from '@viasat/components';
import {deleteCsrfCookie} from '../utils/StoreUtils';
import {ErrorAPIMessage} from '../definitions/types';

export interface InternalErrorAPIMessage {
  code: number;
  message: string;
}

export const generateMessage = (friendlyMessage: string, intlId: string, error?: any): ErrorAPIMessage => {
  if (!window.navigator.onLine) {
    return {
      friendlyMessage: 'Your device is no longer connected to any network. Please reconnect and try again.',
      internalError: undefined,
      status: undefined,
      xBEPExecutionId: undefined,
      intlId: 'system.offline',
    };
  } else {
    const errorMessage = pathOr(undefined, ['response', 'message'], error);
    const errorStatus = pathOr(undefined, ['status'], error);
    const xBEPExecutionId = pathOr(undefined, ['response', 'xBEPExecutionId'], error);
    let internalError = error ? {message: errorMessage ? errorMessage : error.message, code: error.status} : undefined;
    //TODO: In the future move this to an http interceptor
    if (errorStatus === 403) {
      //Authorization error occurred
      handleNotAuthorized(errorStatus);
    }
    return {
      friendlyMessage,
      internalError,
      status: errorStatus,
      xBEPExecutionId,
      intlId,
    };
  }
};
function handleNotAuthorized(status) {
  deleteCsrfCookie();
}

export const generateErrorDescription = (error: any) => {
  const errorMessage = pathOr('', ['response', 'message'], error);
  const errorStatus = pathOr(undefined, ['status'], error);
  const xBEPExecutionId = pathOr('', ['response', 'xBEPExecutionId'], error);
  if (errorStatus === 403) {
    handleNotAuthorized(errorStatus);
  }
  if (!errorMessage || !xBEPExecutionId) {
    return '';
  }

  return (
    globalIntl.formatMessage({id: 'error.id', defaultMessage: 'Error ID'}) + `: ${xBEPExecutionId}\n${errorMessage} \n`
  );
};

export interface OwnProps {
  message: ErrorAPIMessage;
  onRetry?: (search?: any) => void;
  enableReload?: boolean;
  onReload?: () => void;
  className?: string;
}

export interface LocalizableProps {
  localizedRetryText?: string | JSX.Element;
  localizedHeaderText?: string | JSX.Element;
  localizedReloadText?: string | JSX.Element;
}

export type ErrorProps = OwnProps & LocalizableProps & ColorProps & SpaceProps & TypographyProps;

const ErrorMessage: React.FC<ErrorProps> = (props) => {
  const {
    message,
    onRetry,
    enableReload,
    onReload,
    localizedRetryText,
    localizedHeaderText,
    localizedReloadText,
    className,
  } = props;
  const {internalError} = message;

  return (
    <div data-test-el="generic-error" className={className}>
      <div>
        <Title data-test-el="generic-error-title" variant={'h2'}>
          {localizedHeaderText}
        </Title>
        <MessageStyled data-test-el="generic-error-message">
          {message.intlId ? (
            <FormattedMessage defaultMessage={message.friendlyMessage} id={message.intlId} />
          ) : (
            message.friendlyMessage
          )}
          {internalError ? <MessageStyled>{internalError.message}</MessageStyled> : null}
          {message.xBEPExecutionId ? <MessageStyled>{message.xBEPExecutionId}</MessageStyled> : null}
        </MessageStyled>
        {!!message.status && (
          <StatusStyled>
            [{message.status} {internalError ? '- ' + internalError.code : null}]
          </StatusStyled>
        )}
        <ButtonContainer width={'270px'}>
          {onRetry ? (
            <Button mr={3} variant="primary" onClick={onRetry}>
              {localizedRetryText}
            </Button>
          ) : null}

          {enableReload ? (
            <Button
              mr={3}
              variant="primary"
              onClick={() => {
                if (onReload && typeof onReload === 'function') {
                  onReload();
                } else {
                  window.location.reload(); // To make sure we can revert back to this functionality if a reload on same page is not desired
                }
              }}
              data-test-el="generic-reload"
            >
              {localizedReloadText}
            </Button>
          ) : null}
          <RestartButton mr={0} />
        </ButtonContainer>
      </div>
    </div>
  );
};

const ErrorMessageStyled = styled(ErrorMessage)<ErrorProps & SpaceProps>`
  ${space}
  ${color}
  ${fontSize}
  display: flex;

  > div {
    flex: 0 1 auto;
    max-width: 270px;
    margin: 0 auto;
    align-self: center;
  }
`;

const MessageStyled = styled.div`
  word-wrap: break-word;
  margin: ${({theme}) => theme.space[2]}px 0;
  > div {
    opacity: 0.5;
    font-style: italic;
    word-wrap: break-word;
  }
`;

const StatusStyled = styled.div`
  text-align: right;
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  opacity: 0.5;
  margin: ${({theme}) => theme.space[3]}px 0 ${({theme}) => theme.space[3]}px 0;
`;

ErrorMessageStyled.defaultProps = {
  enableReload: false,
  localizedRetryText: 'Retry',
  localizedHeaderText: 'Error',
  localizedReloadText: 'Reload',
  color: 'text',
  m: 5,
};

export default ErrorMessageStyled;
