import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Text, Accordion} from '@viasat/components';
import {ErrorItemsByCategory, QoiStatus} from '../../../../definitions/types';
import {ListItem} from '../../../../shared-components/Styled';

interface QoiPageFooterProps {
  status?: QoiStatus;
  retryCount: number;
  isBusy: boolean;
  errors?: ErrorItemsByCategory[];
}

const QoiPageFooter: React.FC<QoiPageFooterProps> = ({status, retryCount, isBusy, errors}) => {
  const state = status ? status : 'DEFAULT';
  const [errorItems, setErrorItems] = useState(errors || []);
  useEffect(() => {
    if (errors) {
      setErrorItems(errors);
    }
  }, [errors]);
  const setItemExpanded = (errorItem: ErrorItemsByCategory): void => {
    const newErrorItems = errorItems.map((error) => {
      if (error.name === errorItem.name) {
        return {...error, isExpanded: errorItem.isExpanded};
      }
      return error;
    });
    setErrorItems(newErrorItems);
  };
  const getAccordionItems = () => {
    return (
      <>
        {errorItems &&
          errorItems.length &&
          errorItems.map((item, index) => (
            <Accordion
              isExpanded={item.isExpanded}
              title={item.name}
              key={index}
              onClick={() => {
                setItemExpanded({...item, isExpanded: !item.isExpanded});
              }}
            >
              <ListItem>
                {item.explanations.map((explanation, i) => (
                  <li key={i}>{explanation}</li>
                ))}
              </ListItem>
            </Accordion>
          ))}
      </>
    );
  };
  return (
    <>
      {
        {
          SUCCEEDED: (
            <Text
              textAlign="center"
              mt={6}
              mb={4}
              mr="auto"
              ml="auto"
              width={['auto', 'auto', '530px', '530px', '530px'] as any}
              display="block"
              fontWeight="bolder"
              fontSize={3}
              data-test-el="qoi-footer-text"
            >
              <FormattedMessage
                id="quality.validate.success.info1"
                defaultMessage="Congratulations, the Quality of Installation is complete. "
              />
              <br />
              <FormattedMessage
                id="quality.validate.success.info2"
                defaultMessage="Verify the Modem is online then click Continue."
              />
            </Text>
          ),
          FAILED: (
            <Text textAlign="center" mt={6} mb={4} display="block" data-test-el="qoi-footer-text">
              <FormattedMessage id="quality.validate.error" defaultMessage="The following errors occurred: " />
              <br />
              <br />
              {getAccordionItems()}
              <br />
              <FormattedMessage
                id="quality.validate.error.info"
                defaultMessage="Click Retry to initiate Quality of Installation check or Cancel to abort"
              />
            </Text>
          ),
          RETRY: (
            <Text textAlign="center" mt={6} mb={4} display="block" data-test-el="qoi-footer-text">
              {retryCount === 3 && !isBusy ? (
                <>
                  <FormattedMessage id="quality.validate.error" defaultMessage="The following errors occured: " />
                  {getAccordionItems()}
                  <br />
                  <FormattedMessage
                    id="quality.validate.retry.info"
                    defaultMessage="Click Continue to proceed with activation or click Cancel to abort"
                  />
                </>
              ) : (
                <>
                  <FormattedMessage id="quality.validate.retry.count" defaultMessage="Executing retry" />
                  {` ${retryCount}/3`}
                </>
              )}
            </Text>
          ),
          DEFAULT: <Text textAlign="center" mt={6} mb={4} display="block" data-test-el="qoi-footer-text" />,
        }[state]
      }
    </>
  );
};

export default QoiPageFooter;
