import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from '../../../store';
import ValidateEquipmentDetailsPage from './validateEquipmentDetailsPage';
import {getAllEquipmentByPIAction, resetErrorAction, restartAction} from '../../../store/activation/actions';
import {setApplicationFlowState} from '../../../store/activation/activationHelpers';
import Error from '../../common/Error';
import LoaderComponent from '../../common/LoaderComponent';
import {FlowState} from '../../../definitions/types';
import {UpgradeEquipmentDetails} from '../../../store/activation/actionInterfaces';

const ValidateEquipmentDetailsPageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    proceedToNextFlow: (flowState: FlowState) => dispatch(setApplicationFlowState(flowState) as any),
    resetError: () => dispatch(resetErrorAction()),
    getAllEquipment: () => dispatch(getAllEquipmentByPIAction.started({})),
    restartAction: () => dispatch(restartAction()),
  };
  const stateProps = useSelector((state: IRootState) => {
    return {
      isBusy: state.activation.isBusy,
      error: state.activation.error,
      country: state.system.country,
      upgradeEquipmentDetails: state.activation.upgradeEquipmentDetails as UpgradeEquipmentDetails[],
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };
  const proceedToNextStep = () => {
    props.proceedToNextFlow(FlowState.EquipmentDetailsValidated);
  };
  useEffect(() => {
    props.getAllEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const retry = () => {
    props.getAllEquipment();
    props.resetError();
  };
  if (props.isBusy || (!props.upgradeEquipmentDetails && !props.error)) {
    return <LoaderComponent />;
  }

  if (props.error) {
    return <Error message={props.error} enableReload={true} onReload={retry} />;
  }

  return <ValidateEquipmentDetailsPage {...props} proceedToNextStep={proceedToNextStep} />;
};

export default ValidateEquipmentDetailsPageContainer;
