import {interval, Observable, of, race, throwError, timer} from 'rxjs';
import {Action} from 'redux';
import {combineEpics, ofType, StateObservable} from 'redux-observable';
import {catchError, concatMap, filter, flatMap, map, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import {activateHotspotAction, checkHotspotActivationStatusAction, restartAction} from '../../actions';
import {persistActivationField} from '../../activationHelpers';
import {activateHotspot, checkHotspotActivationStatus} from '../../services';
import {generateMessage} from '../../../../shared-components/ErrorMessage';
import env from '../../../../utils/env';
import {FlowState} from '../../../../definitions/types';
import {handleActivationError} from '../appUtility/appUtilityEpic';
import {ActivationStatus} from '../../../../components/util/ActivationStatus';

export const activateHotspotActionEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(activateHotspotAction.started.match),
    switchMap((action) =>
      activateHotspot().pipe(
        concatMap((response) => {
          if (response.response.success) {
            return of(
              activateHotspotAction.done({
                result: {success: response.response.success},
                params: {},
              })
            );
          }

          return of(
            activateHotspotAction.failed({
              error: generateMessage('Could not activate hotspot!', 'error.hotspot.activation'),
              params: {},
            })
          );
        }),
        catchError((error) => {
          return of(
            activateHotspotAction.failed({
              error: generateMessage('Could not activate hotspot!', 'error.hotspot.activation', error),
              params: {},
            })
          );
        })
      )
    )
  );

function fetchHotspotStatus() {
  return race(
    timer(0, Number(env.pollingInterval)).pipe(
      switchMap(() => checkHotspotActivationStatus().pipe(catchError((err) => handleActivationError(err)))),
      skipWhile(
        (response: any) =>
          ![ActivationStatus.ACTIVE, ActivationStatus.ACTIVATING_ERROR].includes(response.response.status.toUpperCase())
      ),
      take(1),
      map((response) => ({result: {status: response.response.status}, params: {}}))
    ),
    interval(Number(env.modemActivationTimeout)).pipe(
      flatMap((err) =>
        throwError({error: err, friendlyMessage: 'Hotspot activation timeout', intlId: 'error.hotspot.timeout'})
      )
    )
  );
}

export const checkHotspotActivationStatusEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(checkHotspotActivationStatusAction.started.match),
    switchMap((action) => {
      return fetchHotspotStatus().pipe(
        map((response: any) => {
          if (response.result.status.toUpperCase() === ActivationStatus.ACTIVE) {
            persistActivationField('applicationFlowState', FlowState.ActivationFinished);
          }
          return checkHotspotActivationStatusAction.done(response);
        }),
        takeUntil(action$.pipe(ofType(restartAction))),
        catchError((thrownError) => {
          return of(
            checkHotspotActivationStatusAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError.error),
              params: {},
            })
          );
        })
      );
    })
  );
export const HotspotEpic = combineEpics(checkHotspotActivationStatusEpic, activateHotspotActionEpic);
