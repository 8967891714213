import ProgressBarComponent from '../../common/ProgressBarComponent';
import React from 'react';
import {PageContainer} from '../../common/AppLayout';
interface ResSwapComponentInterface {
  orderId?: string;
}
// Max duration in seconds that swap preparation and configuration take.
// If in future we find out that the services take more/less time we just need to update this value
const MAX_SWAP_DURATION = 30;

const MAX_PROGRESS_RANGE = 100; // percentage of completion
const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
const ResSwapProgressComponent: React.FunctionComponent<ResSwapComponentInterface> = (props) => {
  const [progress, setProgress] = React.useState(props.orderId ? 60 : 0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= MAX_PROGRESS_RANGE) {
          return MAX_PROGRESS_RANGE;
        }
        // every second the progress bar is incremented randomly to give the impression of a real time update
        const increment = getRandomNumber(0, Math.ceil(MAX_PROGRESS_RANGE / MAX_SWAP_DURATION));
        if (props.orderId) {
          return Math.min(oldProgress + increment, MAX_PROGRESS_RANGE);
        } else {
          // For 60% (3/5 percent) of the service duration, we assume the swap is in preparation
          return Math.min(oldProgress + increment, Math.ceil((3 / 5) * MAX_PROGRESS_RANGE));
        }
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <PageContainer data-test-el="swap-progress-bar-page" mt={5}>
      <ProgressBarComponent
        value={progress}
        variant={'determinate'}
        titleMessage={{
          id: 'res.swap.progress.bar.title',
          defaultMessage: 'Creating replacement product instance, this may take several minutes',
        }}
        descriptionMessage={{
          id: props.orderId
            ? 'res.swap.progress.bar.configuration.message'
            : 'res.swap.progress.bar.preparation.message',
          defaultMessage: props.orderId
            ? 'Configuring the replacement product instance...'
            : 'Preparing the replacement product instance...',
        }}
      />
    </PageContainer>
  );
};
export default ResSwapProgressComponent;
