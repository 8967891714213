import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../store';
import Error from '../../common/Error';
import ValidateQualityPage from './ValidateQualityPage';
import {
  activateQoiAction,
  activateQoiSwapAction,
  checkQoiStatusAction,
  logQoiBypassAction,
  resetErrorAction,
} from '../../../store/activation/actions';
import {setApplicationFlowState} from '../../../store/activation/activationHelpers';
import {
  CountryCodes,
  ErrorAPIMessage,
  ErrorItemsByCategory,
  FlowState,
  InstallationSubType,
  InstallationTypesCodes,
  QoiCategory,
  QoiDetails,
  QoiStepStateEnum,
} from '../../../definitions/types';
import env from '../../../utils/env';
import {UpgradeEquipmentDetails} from '../../../store/activation/actionInterfaces';
import {requiresSateliteChange} from '../../util/EquipmentUtility';
import {countryNeedsECA} from '../../util/appUtilities';

export interface StateProps {
  isQoiBusy: boolean;
  error?: ErrorAPIMessage;
  commandId: string;
  qoiDetails: QoiDetails;
  installationType: InstallationTypesCodes;
  country: CountryCodes;
  installationSubtype: InstallationSubType;
  upgradeEquipmentDetails: UpgradeEquipmentDetails[];
}
export interface DispatchProps {
  activateQoi: (checkQoiStatus?: boolean, isSwap?: boolean) => void;
  checkQoiStatus: (commandId: string, delay: number) => void;
  setQoiValidated: (flowState: FlowState) => void;
  logQoiBypass: () => void;
  resetError: () => void;
}
export type Props = StateProps & DispatchProps;

const isResidentialUpgrade = (
  installationSubType: InstallationSubType,
  installationType: InstallationTypesCodes
): boolean => {
  return installationType === InstallationTypesCodes.RES && installationSubType === InstallationSubType.UPGRADE;
};
const ValidateQualityPageContainer: React.FC<Props> = (props) => {
  const [retryCount, setRetryCount] = useState(0);
  const {commandId, qoiDetails, checkQoiStatus, logQoiBypass, error} = props;

  useEffect(() => {
    if (commandId && !qoiDetails) checkQoiStatus(commandId, 0);
    if (qoiDetails && qoiDetails.status === 'RETRY') {
      if (retryCount < 3) {
        checkQoiStatus(commandId, Number(env.qoiRetryDelay));
        setRetryCount(retryCount + 1);
      } else {
        logQoiBypass();
      }
    } // eslint-disable-next-line
  }, [commandId, qoiDetails]);

  const proceedToNextStep = () => {
    const countryNeedsEca = countryNeedsECA(props.country, props.installationSubtype);
    const isResUpgrade = isResidentialUpgrade(props.installationSubtype, props.installationType);
    const isNewSatellite = requiresSateliteChange(props.upgradeEquipmentDetails);
    const isECANeeded = countryNeedsEca || (isResUpgrade && isNewSatellite);
    props.setQoiValidated(isECANeeded ? FlowState.ECANeeded : FlowState.QoiValidated);
  };
  const performQoiActivation = (checkQoiStatus?: boolean) => {
    props.activateQoi(
      checkQoiStatus,
      props.installationSubtype === InstallationSubType.RES_SWAP ||
        props.installationSubtype === InstallationSubType.WHOLESALE_SWAP
    );
  };
  const retryAndPollForQoi = () => {
    performQoiActivation(true);
  };

  const getErrors = (categories: QoiCategory[]): ErrorItemsByCategory[] => {
    return categories
      .filter((cat) => cat.color !== QoiStepStateEnum.GREEN)
      .map((cat) => {
        return {
          name: cat.name,
          explanations: cat.explanations,
          isExpanded: true,
        };
      });
  };

  // Error unhandled by QoI UI
  return error ? (
    <Error message={error} enableReload={true} onReload={props.resetError} />
  ) : (
    <ValidateQualityPage
      {...props}
      qoiErrors={qoiDetails ? getErrors(qoiDetails.categories) : []}
      retryCount={retryCount}
      proceedToNextStep={proceedToNextStep}
      retry={retryAndPollForQoi}
      performQoiActivation={performQoiActivation}
    />
  );
};

export default connect(
  (state: IRootState) => ({
    isQoiBusy: state.activation.isModemOperationBusy,
    error: state.activation.error,
    commandId: state.activation.commandId,
    qoiDetails: state.activation.qoiDetails,
    installationType: state.system.installationType,
    installationSubtype: state.system.installationSubtype,
    country: state.system.country as CountryCodes,
    upgradeEquipmentDetails: state.activation.upgradeEquipmentDetails,
  }),
  (dispatch) => ({
    activateQoi: (checkQoiStatus = false, isSwap = false) => {
      return dispatch(
        isSwap ? activateQoiSwapAction.started({checkQoiStatus}) : activateQoiAction.started({checkQoiStatus})
      );
    },
    checkQoiStatus: (commandId: string, delay: number) =>
      dispatch(checkQoiStatusAction.started({commandId, delayInMs: delay})),
    setQoiValidated: (flowState: FlowState) => {
      dispatch(setApplicationFlowState(flowState) as any);
    },
    logQoiBypass: () => dispatch(logQoiBypassAction.started({})),
    resetError: () => dispatch(resetErrorAction()),
  })
)(ValidateQualityPageContainer as any) as React.ComponentType<Partial<Props>>; // TODO drop any
