import * as SystemEpics from './system/epics';
import {combineReducers, Reducer} from 'redux';
import {History} from 'history';
import {combineEpics} from 'redux-observable';
import {default as system, ISystemState} from './system/reducer';
import {default as activation} from './activation/reducer';
import {values} from 'ramda';
import {connectRouter} from 'connected-react-router';
import {ActivationEpics} from './activation/epics';
import {IActivationState} from '../definitions/types';

export interface IRootState {
  router: any;
  system: ISystemState;
  activation: IActivationState;
}

export const rootEpic = combineEpics(...values(SystemEpics), ActivationEpics);

// reducer name maps to state tree its responsible for
export default (history: History): Reducer<IRootState> =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    system,
    activation,
  });
