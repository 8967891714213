import {Observable, of} from 'rxjs';
import {Action} from 'redux';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {fetchPartyAction} from '../../actions';
import {fetchParty} from '../../services';
import {generateMessage} from '../../../../shared-components/ErrorMessage';
import {combineEpics} from 'redux-observable';

export const fetchPartyEpic = (action$: Observable<Action>) => {
  return action$.pipe(
    filter(fetchPartyAction.started.match),
    switchMap(() =>
      fetchParty().pipe(
        map((response: any) =>
          fetchPartyAction.done({
            result: {party: response.response},
            params: {},
          })
        ),
        catchError((error) =>
          of(
            fetchPartyAction.failed({
              error: generateMessage('Could not retrieve Customer data!', 'error.customer.data', error),
              params: {},
            })
          )
        )
      )
    )
  );
};

export const CustomerEpic = combineEpics(fetchPartyEpic);
