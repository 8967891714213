import {FlowState, IActivationState} from '../../definitions/types';
import ServiceActivatePageContainer from './serviceActivate/ServiceActivatePageContainer';
import WifiPageContainer from './wifiPage/WifiPageContainer';
import HotspotActivationPageContainer from './hotspotActivate/HotspotActivatePageContainer';
import EquipmentTrackingPageContainer from './equipmentTracking/EquipmentTrackingPageContainer';
import ValidateDetailsPageContainer from './validateDetails/ValidateDetailsPageContainer';
import ValidateContactPageContainer from './validateContact/ValidateContactPageContainer';
import ValidateQualityPageContainer from './validateQuality/ValidateQualityPageContainer';
import ModemActivatePageContainer from './modemActivate/ModemActivatePageContainer';
import VoIPStatusUpdatePageContainer from './voIPStatusUpdate/voIPActivationPageContainer';
import GbsVoipActivationPageContainer from './gbsVoipActivation/gbsVoipActivationPageContainer';
import EquipmentSelectionPageContainer from './swapEquipmentSelection/swapEquipmentSelectionPageContainer';
import ResSwapEquipmentPageContainer from './resSwapEquipment/resSwapEquipmentPageContainer';
import ServiceUpgradeDetailsPageContainer from './validateEquipmentDetails/validateEquipmentDetailsPageContainer';
import SwapSuccessContainer from './swapEquipmentSuccess/swapEquipmentSuccessContainer';
import UpdateEquipmentSerialNumberContainer from './swapTrackedEquipment/swapTrackedEquipmentContainer';
import ContractPageContainer from './contractPage/ContractPageContainer';
import AlianzaAgreementPageContainer from './alianzaAgreement/AlianzaAgreementPageContainer';

const stateEquals =
  (...args) =>
  (state: IActivationState) => {
    return args.includes(state.applicationFlowState);
  };

export const ApplicationFlow = {
  RES: {
    SAT: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      ContactValidation: {
        component: ValidateContactPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      QoiValidation: {
        component: ValidateQualityPageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
      ECA: {
        component: ContractPageContainer,
        guard: stateEquals(FlowState.ECANeeded),
      },
      Activation: {
        component: ModemActivatePageContainer,
        guard: stateEquals(FlowState.QoiValidated),
      },
    },
    VOIP: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      ContactValidation: {
        component: ValidateContactPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      UpdateVoIPStatus: {
        component: VoIPStatusUpdatePageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
      AlianzaIdentity: {
        component: AlianzaAgreementPageContainer,
        guard: stateEquals(FlowState.VoIPActivated),
      },
    },
    WHOLESALE: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      QoiValidation: {
        component: ValidateQualityPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      Activation: {
        component: ModemActivatePageContainer,
        guard: stateEquals(FlowState.QoiValidated),
      },
    },
    RES_SWAP: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      ContactValidation: {
        component: ValidateContactPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      EquipmentSelection: {
        component: ResSwapEquipmentPageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
      QoiValidation: {
        component: ValidateQualityPageContainer,
        guard: stateEquals(FlowState.SwapCreated),
      },
      Activation: {
        component: ModemActivatePageContainer,
        guard: stateEquals(FlowState.QoiValidated),
      },
    },
    UPGRADE: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      ContactValidation: {
        component: ValidateContactPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      EquipmentDetailsValidation: {
        component: ServiceUpgradeDetailsPageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
      QoiValidation: {
        component: ValidateQualityPageContainer,
        guard: stateEquals(FlowState.EquipmentDetailsValidated),
      },
      Activation: {
        component: ModemActivatePageContainer,
        guard: stateEquals(FlowState.EquipmentDetailsValidated, FlowState.QoiValidated),
      },
    },
  },
  VCI: {
    VCI_SAT: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      ContactValidation: {
        component: ValidateContactPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      QoiValidation: {
        component: ValidateQualityPageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
      Activation: {
        component: ModemActivatePageContainer,
        guard: stateEquals(FlowState.QoiValidated),
      },
      EquipmentTracking: {
        component: EquipmentTrackingPageContainer,
        guard: stateEquals(FlowState.ModemActivated),
      },
      AccessPointActivation: {
        component: WifiPageContainer,
        guard: stateEquals(FlowState.EquipmentTrackingSubmitted, FlowState.TwoPointFourGHzSubmitted),
      },
      HotspotActivation: {
        component: HotspotActivationPageContainer,
        guard: stateEquals(FlowState.FiveGHzSubmitted, FlowState.WifiActivated),
      },
    },
    PARTNER_VCI: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      activation: {
        component: ModemActivatePageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
      EquipmentTracking: {
        component: EquipmentTrackingPageContainer,
        guard: stateEquals(FlowState.ModemActivated),
      },
      AccessPointActivation: {
        component: WifiPageContainer,
        guard: stateEquals(FlowState.EquipmentTrackingSubmitted, FlowState.TwoPointFourGHzSubmitted),
      },
      HotspotActivation: {
        component: HotspotActivationPageContainer,
        guard: stateEquals(FlowState.FiveGHzSubmitted, FlowState.WifiActivated),
      },
    },
    VCI_SWAP: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      ContactValidation: {
        component: ValidateContactPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      EquipmentSelection: {
        component: EquipmentSelectionPageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
      UpdateSerialNumber: {
        component: UpdateEquipmentSerialNumberContainer,
        guard: stateEquals(FlowState.SwapUPS, FlowState.SwapPOS),
      },
      SwapWifiEquipment: {
        component: WifiPageContainer,
        guard: stateEquals(FlowState.SwapAccessPoint, FlowState.SwapNetworkController),
      },
      SwapSuccessPage: {
        component: SwapSuccessContainer,
        guard: stateEquals(FlowState.SwapDone),
      },
    },
  },
  WHOLESALE: {
    SAT: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      ContactValidation: {
        component: ValidateContactPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      QoiValidation: {
        component: ValidateQualityPageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
      ECA: {
        component: ContractPageContainer,
        guard: stateEquals(FlowState.ECANeeded),
      },
      Activation: {
        component: ModemActivatePageContainer,
        guard: stateEquals(FlowState.QoiValidated),
      },
    },
    WHOLESALE_SWAP: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      EquipmentSelection: {
        component: ResSwapEquipmentPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      QoiValidation: {
        component: ValidateQualityPageContainer,
        guard: stateEquals(FlowState.SwapCreated),
      },
      Activation: {
        component: ModemActivatePageContainer,
        guard: stateEquals(FlowState.QoiValidated),
      },
    },
  },
  GBS: {
    VOIP: {
      Authorization: {
        component: ServiceActivatePageContainer,
        guard: stateEquals(FlowState.NotAuthorized),
      },
      DetailsValidation: {
        component: ValidateDetailsPageContainer,
        guard: stateEquals(FlowState.Authorized),
      },
      ContactValidation: {
        component: ValidateContactPageContainer,
        guard: stateEquals(FlowState.DetailsValidated),
      },
      GetGbsVoipStatus: {
        component: GbsVoipActivationPageContainer,
        guard: stateEquals(FlowState.ContactValidated),
      },
    },
  },
};
