import React from 'react';
import ConnectedLocaleSwitcher from './components/common/ConnectedLocaleSwitcher';
import SystemNotifier from './components/common/SystemNotifier';
import {AppContainer, BodyContainer} from './components/common/AppLayout';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import InvalidRoute from './components/pages/InvalidRouteStyled';
import logoImage from './images/viasat-logo.svg';
import logoImageWhite from './images/viasat-white-logo.svg';
import PageFactoryContainer from './components/pages/PageFactoryContainer';
import {FormattedMessage} from 'react-intl.macro';
import {connect} from 'react-redux';
import {IRootState} from './store';
import env from './utils/env';
import {FlowState} from './definitions/types';
import {ExternalLink, Text, Header, Footer} from '@viasat/components';

export interface Props {
  flowState?: FlowState;
}

const App: React.FC<Props> = ({flowState}) => {
  return (
    <AppContainer>
      <BodyContainer>
        <Header logoImage={logoImage} actions={flowState === FlowState.NotAuthorized && <ConnectedLocaleSwitcher />} />

        <BrowserRouter basename={env.contextPath ? env.contextPath : undefined}>
          <Switch>
            <Route path="*" component={(props: any) => <PageFactoryContainer {...props} />} />
          </Switch>
        </BrowserRouter>

        <Footer
          logoImage={logoImageWhite}
          content={
            <React.Fragment>
              <Text ml={0} mr={3} variant="secondary" fontSize={[0, 1, 2, 2, 2]}>
                <FormattedMessage id="footer.copyright" defaultMessage="©2020 All rights reserved" />
              </Text>
              |
              <ExternalLink
                to="https://www.viasat.com/legal"
                variant="secondary"
                ml={3}
                mr={3}
                fontSize={[0, 1, 2, 2, 2]}
              >
                <FormattedMessage id="footer.legal" defaultMessage="Legal" />
              </ExternalLink>
            </React.Fragment>
          }
          infoMessage={<FormattedMessage id="footer.infomessage" defaultMessage="Service Activation" />}
        />
      </BodyContainer>
      <SystemNotifier />
    </AppContainer>
  );
};

export default connect(
  (state: IRootState): Props => ({
    flowState: state.activation.applicationFlowState,
  }),
  () => ({})
)(App as any);
