import styled from 'styled-components';

import {space, layout, LayoutProps, SpaceProps} from 'styled-system';

export type OwnProps = SpaceProps & LayoutProps & React.HTMLProps<HTMLDivElement>;

const ButtonContainer = styled.div<OwnProps>`
  display: flex;
  justify-content: flex-end;
  ${space}
  ${layout}
`;

ButtonContainer.defaultProps = {
  maxWidth: '530px',
  mt: 3,
  mb: 0,
  ml: 'auto',
  mr: 'auto',
};

export default ButtonContainer;
