import React, {useEffect} from 'react';
import Error from '../../common/Error';
import {connect} from 'react-redux';
import {IRootState} from '../../../store';
import {
  getGbsVoIPAction,
  resetErrorAction,
  restartAction,
  startGbsVoIPActivationAction,
} from '../../../store/activation/actions';
import {GbsVoipActivationPage} from './gbsVoipActivationPage';
import {ErrorAPIMessage, GbsVoipDetails} from '../../../definitions/types';
import LoaderComponent from '../../common/LoaderComponent';
import {PageState} from '../../util/PageState';

interface StateProps {
  isBusy: boolean;
  isInstallationBusy: boolean;
  error?: ErrorAPIMessage;
  gbsVoipDetails: GbsVoipDetails[];
}

interface DispatchProps {
  resetError: () => void;
  reload: () => void;
  getGbsVoip: () => void;
  startGbsVoipActivation: (voipPi: string, macAddress: string, isActivating: boolean) => void;
}

type GbsVoipActivationPageContainerProps = StateProps & DispatchProps;

const GbsVoipActivationPageContainer: React.FC<GbsVoipActivationPageContainerProps> = (props) => {
  const [pageState, setPageState] = React.useState(PageState.DEFAULT);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const handleRetry = () => {
    props.resetError();
    props.getGbsVoip();
  };
  const finish = () => {
    props.reload();
  };
  const openConfirmationDialog = () => {
    setIsDialogOpen(true);
  };
  const proceedToSuccess = (proceed: boolean) => {
    if (proceed) {
      setPageState(PageState.SUCCESS);
    }
    setIsDialogOpen(false);
  };
  useEffect(() => {
    if (!props.isBusy) {
      props.getGbsVoip();
    } // eslint-disable-next-line
  }, [1]);
  const {error} = props;
  if ((props.isBusy || !props.gbsVoipDetails) && !error) {
    return <LoaderComponent />;
  }
  return error ? (
    <Error
      message={error}
      enableReload={true}
      onReload={() => {
        props.resetError();
        handleRetry();
      }}
    />
  ) : (
    <GbsVoipActivationPage
      voipDetails={props.gbsVoipDetails}
      startGbsVoipActivation={props.startGbsVoipActivation}
      pageState={pageState}
      proceedToSuccess={proceedToSuccess}
      openConfirmationDialog={openConfirmationDialog}
      isDialogOpen={isDialogOpen}
      onFinish={finish}
    />
  );
};
export default connect(
  (state: IRootState) => ({
    isBusy: state.activation.isBusy,
    isInstallationBusy: state.activation.isModemOperationBusy,
    error: state.activation.error,
    gbsVoipDetails: state.activation.gbsVoipDetails,
  }),
  (dispatch) => ({
    resetError: () => dispatch(resetErrorAction()),
    reload: () => dispatch(restartAction()),
    startGbsVoipActivation: (voipPi: string, macAddress: string, isActivating: boolean) =>
      dispatch(startGbsVoIPActivationAction.started({macAddress, voipPi, isActivating})),
    getGbsVoip: () => dispatch(getGbsVoIPAction.started({})),
  })
)(GbsVoipActivationPageContainer as any) as React.ComponentType<Partial<GbsVoipActivationPageContainerProps>>;
