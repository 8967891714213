import {
  CountryCodes,
  ICountries,
  IInstallationSubType,
  IInstallationTypes,
  InstallationSubType,
  InstallationTypesCodes,
} from '../../../definitions/types';
import {countries, installationSubTypes, installationTypes} from '../../../definitions/constants';
import {globalIntl} from '../../../utils/IntlGlobalProvider';

export const useInstallationFlowType = (
  installationType: InstallationTypesCodes,
  installationSubtype: InstallationSubType,
  countryCode: CountryCodes
): string => {
  const installationTypeCaption = installationTypes.find((item) => {
    return item.code === installationType;
  }) as IInstallationTypes;
  const installationSubTypeCaption = installationSubTypes.find((item) => {
    return item.code === installationSubtype;
  }) as IInstallationSubType;
  const country = countries.find((item) => {
    return item.code === countryCode;
  }) as ICountries;
  return `${globalIntl.formatMessage({
    id: installationTypeCaption.key,
    defaultMessage: installationTypeCaption.name,
  })} - ${globalIntl.formatMessage({
    id: installationSubTypeCaption.key,
    defaultMessage: installationSubTypeCaption.name,
  })} - ${globalIntl.formatMessage({
    id: country.key,
    defaultMessage: country.name,
  })}`;
};
