import * as React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../store';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {Button, ButtonProps} from '@viasat/components';
import {FormattedMessage} from 'react-intl';
import {restartAction} from '../../store/activation/actions';

export interface DispatchProps {
  restart: () => void;
}

type Props = DispatchProps & ButtonProps;

const RestartButton: React.FC<Props> = ({restart, ...rest}) => (
  <Button variant="secondary" onClick={restart} data-test-el="cancel-button" {...rest}>
    <FormattedMessage id="page.cancel.button" defaultMessage="Cancel" />
  </Button>
);

RestartButton.defaultProps = {
  mr: 3,
};

export default connect(
  (state: any) => ({}),
  (dispatch: ThunkDispatch<IRootState, {}, AnyAction>): DispatchProps => ({
    restart: () => dispatch(restartAction()),
  })
)(RestartButton);
