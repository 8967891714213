import ButtonContainer from '../../common/ButtonContainer';
import MacAddressValidationError from '../../../components-lib/form/MacAddressValidationError';
import React, {useState} from 'react';
import RestartButton from '../../common/RestartButton';
import {AccessPoint} from '../../../definitions/types';
import {apSerialFormat, macAddressRegex, ncSerialFormat} from '../../util/regex';
import {Button, Card, TextField, Title, ValidatorForm} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import {PageContainer, TextContainer} from '../../common/AppLayout';

export interface Props {
  isBusy: boolean;
  isFiveGHz?: boolean;
  onSubmit: (accessPoint: AccessPoint) => void;
}

const WifiPage: React.FC<Props> = ({isBusy, onSubmit, isFiveGHz}) => {
  const [validationErrors, setValidationError] = useState([]);
  const [serialNumber, setSerialNumber] = useState('');
  const [macAddress, setMacAddress] = useState('');

  const hasError = (fieldId: string): boolean =>
    validationErrors.filter((e: {props: {id: string}}) => e.props.id === fieldId).length > 0;

  return (
    <PageContainer data-test-el="wifi-page">
      <Title data-test-el="wifi-page-heading">
        <FormattedMessage
          id={`wifi.title.${isFiveGHz ? '1' : '2'}`}
          defaultMessage={`Add ${isFiveGHz ? 'Network Controller (NC)' : 'Access Point (AP)'}`}
        />
      </Title>
      <TextContainer>
        <FormattedMessage
          id={`wifi.text.paragraph`}
          defaultMessage={`Please enter the Serial Number and MAC Address. 
          The serial number for the Access Point and Network Controller are similar. Please ensure the correct information is input.`}
        />
      </TextContainer>
      <ValidatorForm
        onSubmit={() => onSubmit({serialNumber, macAddress})}
        onError={(error) => {
          setValidationError(error);
        }}
      >
        <Card>
          <TextField
            id="serialnumber"
            fullWidth={true}
            label={<FormattedMessage id="wifi.serial.number.label" defaultMessage="Serial Number" />}
            onChange={({target}) => {
              setValidationError([]);
              setSerialNumber(target.value.trim());
            }}
            value={serialNumber}
            mt={0}
            mb={2}
            useValidator={true}
            validators={[`matchRegexp:${isFiveGHz ? ncSerialFormat : apSerialFormat}`, 'required']}
            error={hasError('serialnumber')}
            errorMessages={[
              <FormattedMessage
                id={isFiveGHz ? 'serial.number.nc.validation.error' : 'serial.number.ap.validation.error'}
                defaultMessage="The serial number must match the following format: at least 12 alpha numeric characters optionally containing a forward slash. Eg: 1234567890sn/123"
              />,
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
            ]}
          />
          <TextField
            id="macaddress"
            fullWidth={true}
            label={<FormattedMessage id="wifi.mac.address.label" defaultMessage="MAC Address" />}
            value={macAddress}
            onChange={({target}) => {
              setValidationError([]);
              setMacAddress(target.value.trim());
            }}
            mt={2}
            mb={0}
            useValidator={true}
            validators={[`matchRegexp:${macAddressRegex}`, 'required']}
            error={hasError('macaddress')}
            errorMessages={[
              <MacAddressValidationError />,
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
            ]}
          />
        </Card>
        <ButtonContainer>
          <RestartButton />
          <Button type="submit" disabled={isBusy} data-test-el="wifi-continue-button">
            <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
          </Button>
        </ButtonContainer>
      </ValidatorForm>
    </PageContainer>
  );
};

export default WifiPage;
