import React from 'react';
import {FormattedMessage} from 'react-intl';

import CheckCircle from '@material-ui/icons/CheckCircle';
import {withTheme} from 'styled-components';
import {StyledProps} from '../../../../theme/theme';
import {StatusCard} from '@viasat/components';

const HotspotActivationSuccess: React.FunctionComponent<StyledProps> = ({theme}) => {
  return (
    <StatusCard
      body={
        <>
          <FormattedMessage id="hotspot.activation.success.header" defaultMessage="Congratulations!" />
          <br />
          <FormattedMessage id="hotspot.activation.success.body" defaultMessage="Your Hotspot is now activated." />
        </>
      }
      icon={<CheckCircle style={{height: '140px', width: '140px', color: theme.colors.TEAL_500}} />}
    />
  );
};

export default withTheme(HotspotActivationSuccess);
