import React, {useEffect} from 'react';
import SwapEquipmentSuccessPage from './swapEquipmentSuccessPage';
import {useDispatch} from 'react-redux';
import {resetSelectedSwapEquipmentAction, restartAction} from '../../../store/activation/actions';

const SwapEquipmentSuccessContainer: React.FC<any> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSelectedSwapEquipmentAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [1]);
  const finish = () => {
    dispatch(restartAction());
  };
  return <SwapEquipmentSuccessPage finish={finish} />;
};

export default SwapEquipmentSuccessContainer;
