import {PageContainer, TextContainer} from '../../common/AppLayout';
import {Button, Card, Text, Title} from '@viasat/components';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl.macro';
import React from 'react';
import ButtonContainer from '../../common/ButtonContainer';

interface VoipDisclaimerComponentProps {
  onContinue: () => void;
}

const VoipDisclaimerComponent: React.FC<VoipDisclaimerComponentProps> = ({onContinue}) => {
  return (
    <PageContainer data-test-el="voip-disclaimer-component">
      <Title data-test-el="voip-disclaimer-component-heading">
        <FormattedMessage id="voip.disclaimer.title" defaultMessage={'9-1-1 Provisioning'} />
      </Title>
      <TextContainer>
        <FormattedMessage
          id="voip.disclaimer.info"
          defaultMessage="You need to inform the customer of the following regarding accessing 9-1-1 emergency services through Viasat Voice."
        />
      </TextContainer>
      <Card data-test-el="voip-disclaimer-card">
        <Text marginY={2} display="block">
          <FormattedHTMLMessage
            id="voip.disclaimer.content"
            defaultMessage="Your Viasat Voice service includes emergency 9-1-1 calling. Because Viasat Voice is provided through satellite technology, 9-1-1 calling availability through Viasat Voice may be limited in comparison to 9-1-1 calling that is available through."
          />
        </Text>
      </Card>
      <ButtonContainer>
        <Button onClick={onContinue} data-test-el="voip-disclaimer-continue-button">
          <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
        </Button>
      </ButtonContainer>
    </PageContainer>
  );
};
export default VoipDisclaimerComponent;
