import {queueSnackMessage} from '../store/system/actions';
import {globalIntl} from './IntlGlobalProvider';
import {FormattedMessage} from 'react-intl';

// See for params: https://iamhosseindhv.com/notistack

export interface LocalizedMessage {
  id: string;
  defaultMessage: string;
}

export const userSnackbarAction = (
  message: FormattedMessage.MessageDescriptor,
  options: any = {},
  details?: string
) => {
  if (!window.navigator.onLine) {
    return {
      type: queueSnackMessage.type,
      payload: {
        message: globalIntl.formatMessage({
          defaultMessage: 'Your device is no longer connected to any network. Please reconnect and try again.',
          id: 'system.offline',
        }),
        options,
      },
    };
  } else {
    return {
      type: queueSnackMessage.type,
      payload: {
        message: details ? details + globalIntl.formatMessage(message) : globalIntl.formatMessage(message),
        options,
      },
    };
  }
};
