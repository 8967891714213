const prodHost = 'install.viasat.com';
const localHost = 'localhost';

let env = {
  defaultLocale: 'en',
  activationUrl: 'https://www.viasat.com/home-internet/welcome/',
  apiEndpoint: getApiEndpoint(),
  isMock: false,
  qoiTimeout: 1800000,
  qoiRetryDelay: 60000,
  modemActivationTimeout: 1800000,
  installationType: 'RES',
  installationSubtype: 'SAT',
  country: 'US',
  contractStatusDelay: 30000,
  voipPollingDelay: 15000,
  pollingInterval: 3000,
  swapPollingCount: 4,
  sentryDNS: 'https://5eea777eaa5b4ab680b3b833c6a04079@o448560.ingest.sentry.io/5614813',
};

// This function was added to remove .env necessary at build time.
function getApiEndpoint(): string {
  const envAPI = process.env.REACT_APP_API_ENDPOINT;
  if (envAPI) {
    return envAPI;
  }

  const origin = window.location.origin;
  if (origin.indexOf(prodHost) >= 0) {
    return `${origin}/backend/api`;
  } else if (origin.indexOf(localHost) >= 0) {
    return `${origin}:8000/api`;
  }
  return `${origin}/installer-portal-api/api`;
}

export function loadConfig(config: any) {
  env = {
    ...env,
    ...config,
  };
}

export default env;
