import * as React from 'react';
import {Box, Loading} from '@viasat/components';

interface LoaderComponentInterface {
  loadingText?: string;
}
const LoaderComponent: React.FC<LoaderComponentInterface> = (props) => {
  return (
    <Box display="flex" justifyContent="center" mt={4} pt={4} alignItems="center" flexDirection="column">
      {props.loadingText && <Box mb={2}>{props.loadingText}</Box>}
      <Loading p={1} size={100} />
    </Box>
  );
};

export default LoaderComponent;
