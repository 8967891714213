import React from 'react';
import {Dialog, DialogActions} from '@material-ui/core';
import {IFormattedMessage} from '../definitions/types';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormattedMessage} from 'react-intl.macro';
import {Button} from '@viasat/components';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from 'styled-components';

export interface ConfirmationDialogProps {
  open: boolean;
  handleClose: (value: boolean) => void;
  title: IFormattedMessage;
  body: IFormattedMessage;
  disableBackdropClick?: boolean;
}
const StyledDialogActions = styled(DialogActions)`
  padding: 0;
  @media screen and (max-width: 600px) {
    display: flex;
    padding: 0;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    height: 90px;
  }
`;
const styles = makeStyles({
  paperWidthSm: {
    maxWidth: '340px',
    borderRadius: '8px',
    padding: '24px',
    margin: '24px',
  },
  title: {
    textAlign: 'center',
    padding: '0 0 12px 0',
    '&  h2': {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px',
    },
    '& $MuiDialogContent-root': {
      padding: '0 0 24px 0',
    },
  },
  actions: {
    padding: '0',
  },
  content: {
    padding: '0 0 24px 0',
  },

  contentText: {
    textAlign: 'center',
    margin: '0',
    '& span': {
      lineHeight: '20px',
    },
  },
});

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  handleClose,
  title,
  body,
  disableBackdropClick,
}) => {
  const classes = styles();
  return (
    <Dialog
      maxWidth="sm"
      data-test-el="confirmation-dialog"
      open={open}
      disableBackdropClick={disableBackdropClick}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      classes={{
        paperWidthSm: classes.paperWidthSm,
      }}
    >
      <DialogTitle data-test-el="confirmation-dialog-title" className={classes.title}>
        <FormattedMessage id={title.id} defaultMessage={title.defaultMessage} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText data-test-el="confirmation-dialog-description" className={classes.contentText}>
          <FormattedMessage id={body.id} defaultMessage={body.defaultMessage} />
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <Button
          fullWidth={true}
          variant="secondary"
          onClick={() => {
            handleClose(false);
          }}
          data-test-el="dialog-cancel-button"
        >
          <FormattedMessage id="page.cancel.button" defaultMessage="Cancel" />
        </Button>
        <Button
          fullWidth={true}
          variant="primary"
          onClick={() => {
            handleClose(true);
          }}
          data-test-el="dialog-confirm-button"
          autoFocus
        >
          <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};
