import {concat, Observable, of} from 'rxjs';
import {Action} from 'redux';
import {combineEpics, StateObservable} from 'redux-observable';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {
  authorizeInstallerAction,
  validateFlowSelectionAndAuthoriseAction,
  promptForLegacyRedirectAction,
} from '../../actions';
import {persistActivationField} from '../../activationHelpers';
import {authorizeInstaller, validateFlow} from '../../services';
import {CountryCodes, FlowState} from '../../../../definitions/types';
import {generateErrorDescription, generateMessage} from '../../../../shared-components/ErrorMessage';
import {userSnackbarAction} from '../../../../utils/CommonActions';

export const authorizeInstallerEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(authorizeInstallerAction.started.match),
    switchMap((action) => {
      const {installerId, serviceActivationCode, macAddress, siteId, dealerId} = action.payload;
      return authorizeInstaller(serviceActivationCode, installerId, macAddress, siteId, dealerId).pipe(
        mergeMap((response: any) => {
          if (response.response.status) {
            const {status} = response.response;
            const currentCountry = state$.value.system.country;
            persistActivationField(
              'applicationFlowState',
              currentCountry === CountryCodes.NG ? FlowState.ContactValidated : FlowState.Authorized
            );
            persistActivationField('productInstanceStatus', status);

            return of(
              authorizeInstallerAction.done({
                result: {productInstanceStatus: status, currentCountry},
                params: {installerId, serviceActivationCode, macAddress},
              })
            );
          } else {
            return of(
              authorizeInstallerAction.failed({
                error: generateMessage(
                  `Could not find Product Instance for service activation code: ${serviceActivationCode}`,
                  'error.authorize'
                ),
                params: {installerId, serviceActivationCode, macAddress},
              }),
              promptForLegacyRedirectAction({
                shouldPromptForLegacyRedirect: true,
                redirectUrl: response.response.redirectUrl,
              })
            );
          }
        }),
        catchError((error) =>
          concat(
            of(
              authorizeInstallerAction.failed({
                error: generateMessage('Could not authorize.', 'error.authorize', error),
                params: {installerId, serviceActivationCode, macAddress},
              })
            ),
            of(
              userSnackbarAction(
                {defaultMessage: 'Could not authorize.', id: 'error.authorize'},
                {variant: 'error'},
                generateErrorDescription(error)
              )
            )
          )
        )
      );
    })
  );

export const validateFlowSelectionEpic = (action$: Observable<Action>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter(validateFlowSelectionAndAuthoriseAction.started.match),
    switchMap((action) => {
      const {serviceActivationCode, installerId, macAddress, siteId, dealerId} = action.payload;
      const installationSubType = state$.value.system.installationSubtype;
      return validateFlow(serviceActivationCode, installationSubType).pipe(
        mergeMap((response) => {
          const isSwapValid = response.response;
          if (isSwapValid) {
            return concat(
              of(
                validateFlowSelectionAndAuthoriseAction.done({
                  result: isSwapValid,
                  params: {serviceActivationCode},
                })
              ),
              of(authorizeInstallerAction.started({serviceActivationCode, installerId, macAddress, siteId, dealerId}))
            );
          } else {
            return concat(
              of(
                validateFlowSelectionAndAuthoriseAction.failed({
                  error: generateMessage('Incorrect activation option selected', 'error.validateFlow'),
                  params: {serviceActivationCode},
                })
              ),
              of(
                userSnackbarAction(
                  {
                    defaultMessage: 'Incorrect activation option selected',
                    id: 'error.validateFlow',
                  },
                  {variant: 'error'}
                )
              )
            );
          }
        }),
        catchError((error) => {
          return of(
            validateFlowSelectionAndAuthoriseAction.failed({
              error: generateMessage('Validate if the correct flow was selected', 'error.', error),
              params: {serviceActivationCode},
            })
          );
        })
      );
    })
  );
};

export const InstallerEpic = combineEpics(authorizeInstallerEpic, validateFlowSelectionEpic);
