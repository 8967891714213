import * as React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {ThemeProvider} from 'styled-components';
import {theme} from '../../theme/theme';
import IntlGlobalProvider from '../../utils/IntlGlobalProvider';
import ConnectedIntlProvider from './ConnectedIntlProvider';
import {StylesProvider} from '@material-ui/styles';
import GlobalStyle from './GlobalStyle';
import {SnackbarProvider} from 'notistack';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {closeSnackMessage} from '../../store/system/actions';
import {PointerIcon} from '@viasat/components';

interface AppProvidersProps {
  store: any;
  history: any;
  children: any;
}

const AppProviders: React.FC<AppProvidersProps> = (props: AppProvidersProps) => {
  const muiTheme = createMuiTheme({
    typography: {
      fontFamily: (theme as any).fonts.body,
    },
    overrides: {
      MuiBackdrop: theme.muiBackdrop,
    },
  });

  function handleCloseSnackbar(key: string) {
    return (
      <PointerIcon onClick={() => props.store.dispatch(closeSnackMessage(key))}>
        <CancelOutlinedIcon />
      </PointerIcon>
    );
  }

  return (
    <Provider store={props.store}>
      <ConnectedRouter history={props.history}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={muiTheme}>
            <SnackbarProvider
              maxSnack={3}
              hideIconVariant={true}
              action={handleCloseSnackbar}
              style={{whiteSpace: 'pre-line'}}
            >
              <ThemeProvider theme={theme}>
                <React.Fragment>
                  <GlobalStyle />
                  <ConnectedIntlProvider>
                    <IntlGlobalProvider>{props.children}</IntlGlobalProvider>
                  </ConnectedIntlProvider>
                </React.Fragment>
              </ThemeProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default AppProviders;
