import React, {useState} from 'react';
import {StyledProps} from '../../../theme/theme';
import {ErrorMessageStyled} from '../../../shared-components/Styled';
import {FormattedMessage} from 'react-intl';
import {ActivationStatus} from '../../util/ActivationStatus';
import {Box, Button, Progress, TextField, ValidatorForm} from '@viasat/components';
import {macAddressRegex} from '../../util/regex';
import MacAddressValidationError from '../../../components-lib/form/MacAddressValidationError';
import ButtonContainer from '../../common/ButtonContainer';
import {ActivationStatusCardProps} from '../../common/ActivationStatus/ActivationStatusCard';
import {ConfirmationDialog} from '../../../shared-components/ConfirmationDialog';

export const GbsLineForm: React.FC<ActivationStatusCardProps & StyledProps> = (props) => {
  const [validationErrors, setValidationError] = useState([]);
  const [isActivateDialogOpen, setActivateDialogOpen] = useState(false);
  const [macAddress, setMacAddress] = useState('');
  const fieldId = `macaddress${props.index}`;
  const getActivationDialog = () => (
    <ConfirmationDialog
      disableBackdropClick={true}
      handleClose={(proceed: boolean) => {
        if (proceed) {
          submit();
        }
        setActivateDialogOpen(false);
      }}
      open={isActivateDialogOpen}
      title={{
        id: 'voip.line.activation.prompt.title',
        defaultMessage: 'Are you sure you want to activate this line?',
      }}
      body={{
        id: 'voip.line.activation.prompt.content',
        defaultMessage: 'You should only do this after configuring the handset.',
      }}
    />
  );
  const submit = () => {
    const isActivating =
      props.voipDetail.status === ActivationStatus.ACTIVATING ||
      (props.voipDetail.previousState && props.voipDetail.previousState === ActivationStatus.ACTIVATING);
    props.startGbsVoipActivation(props.voipDetail.voipPi, macAddress, isActivating);
  };
  const hasError = (id: string): boolean =>
    validationErrors.filter((e: {props: {id: string}}) => e.props.id === id).length > 0;
  return (
    <ValidatorForm onSubmit={submit} onError={(errors) => setValidationError(errors)}>
      <TextField
        id={fieldId}
        disabled={
          props.voipDetail.status === ActivationStatus.ACTIVE || props.voipDetail.status === ActivationStatus.ACTIVATING
        }
        fullWidth={true}
        label={<FormattedMessage id="service.activate.macaddress.label" defaultMessage="Modem MAC Address" />}
        value={macAddress}
        onChange={({target}) => {
          setValidationError([]);
          setMacAddress(target.value.trim());
        }}
        useValidator={true}
        validators={[`matchRegexp:${macAddressRegex}`, 'required']}
        error={hasError(fieldId)}
        errorMessages={[<MacAddressValidationError />]}
        mt={0}
        mb={1}
      />

      {props.voipDetail.error && (
        <ErrorMessageStyled data-test-el="error-message-container">{props.voipDetail.error}</ErrorMessageStyled>
      )}

      <ButtonContainer>
        {props.voipDetail.isLoading && (
          <Box alignSelf={'center'} width={'100%'} mr={'24px'}>
            <Progress width="100%" />
          </Box>
        )}
        {
          {
            [ActivationStatus.ACTIVATING_ERROR]: (
              <Button
                data-test-el={`voip-retry-button${props.index}`}
                type="submit"
                variant="secondary"
                disabled={!macAddress || props.voipDetail.isLoading}
              >
                <FormattedMessage id="page.retry.button" defaultMessage="Retry" />
              </Button>
            ),
            [ActivationStatus.ACCEPTED]: (
              <Button
                data-test-el={`voip-start-button${props.index}`}
                type="submit"
                disabled={!macAddress || props.voipDetail.isLoading}
              >
                <FormattedMessage id="voip.line.action.start" defaultMessage="START" />
              </Button>
            ),
            [ActivationStatus.ACTIVATING]: (
              <Button
                data-test-el={`voip-activate-button${props.index}`}
                disabled={props.voipDetail.isLoading}
                onClick={() => {
                  setActivateDialogOpen(true);
                }}
              >
                <FormattedMessage id="voip.line.action.activate" defaultMessage="ACTIVATE" />
              </Button>
            ),
          }[props.voipDetail.status]
        }
      </ButtonContainer>
      {isActivateDialogOpen && getActivationDialog()}
    </ValidatorForm>
  );
};
