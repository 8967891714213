import React from 'react';
import {Card, Text} from '@viasat/components';
import Done from '@material-ui/icons/Done';
import {FormattedMessage} from 'react-intl.macro';
import {StyledProps} from '../../../../theme/theme';
import {withTheme} from 'styled-components';

interface OwnProps {
  responsiveWidths: any;
}

const ContractContentSuccess: React.FC<StyledProps & OwnProps> = ({
  theme,
  responsiveWidths,
}: StyledProps & OwnProps) => {
  return (
    <Card minHeight="350px" mt={5} {...responsiveWidths} pt={'80px'} pb={'80px'} textAlign="center">
      <Done
        style={{
          height: '117px',
          width: '117px',
          margin: '13px',
          borderRadius: '100px',
          background: theme.colors.primary,
          color: theme.colors.WHITE,
        }}
      />
      <Text
        fontWeight="bold"
        fontSize={3}
        width="auto"
        display="block"
        mr="auto"
        ml="auto"
        mt={3}
        textAlign="center"
        data-test-el="status-card-text"
      >
        <FormattedMessage id="contract.success" defaultMessage="ECA Successfully signed" />
      </Text>
    </Card>
  );
};

export default withTheme(ContractContentSuccess);
