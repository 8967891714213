import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from '../../../store';
import Error from '../../common/Error';
import HotspotActivationPage from './HotspotActivatePage';
import {
  activateHotspotAction,
  activateWifiAction,
  checkHotspotActivationStatusAction,
  checkWifiActivationStatusAction,
  resetErrorAction,
} from '../../../store/activation/actions';
import {FlowState} from '../../../definitions/types';
import {asPromise, callCaptivePortal} from '../../../store/activation/services';

const HotspotActivationPageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    activateHotspot: () => dispatch(activateHotspotAction.started({})),
    checkHotspotActivationStatus: () => dispatch(checkHotspotActivationStatusAction.started({})),
    activateWifi: () => dispatch(activateWifiAction.started({})),
    checkWifiActivationStatus: () => dispatch(checkWifiActivationStatusAction.started({})),
    resetError: () => dispatch(resetErrorAction()),
  };
  const stateProps = useSelector((state: IRootState) => {
    return {
      isBusy: state.activation.isBusy,
      error: state.activation.error,
      flowState: state.activation.applicationFlowState,
      hotspotActivationStarted: state.activation.hotspotActivationStarted,
      hotspotActivationStatus: state.activation.hotspotActivationStatus,
      wifiActivationStarted: state.activation.wifiActivationStarted,
      wifiActivationStatus: state.activation.wifiActivationStatus,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };
  const {error} = props;
  useEffect(() => {
    if (props.wifiActivationStarted) {
      props.checkWifiActivationStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wifiActivationStarted]);
  useEffect(() => {
    if (props.flowState === FlowState.WifiActivated) {
      props.activateHotspot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.flowState]);
  useEffect(() => {
    if (props.hotspotActivationStarted) {
      props.checkHotspotActivationStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hotspotActivationStarted]);
  useEffect(() => {
    async function callCaptivePortalAsync() {
      await asPromise(callCaptivePortal());
    }
    if (props.hotspotActivationStatus === 'ACTIVE') {
      callCaptivePortalAsync();
    }
  }, [props.hotspotActivationStatus]);
  const handleRestart = () => {
    if (props.flowState === FlowState.WifiActivated) {
      props.activateHotspot();
    } else {
      props.activateWifi();
    }
  };
  return error ? (
    <Error message={error} enableReload={true} onReload={props.resetError} />
  ) : (
    <HotspotActivationPage onRestart={handleRestart} {...props} />
  );
};
export default HotspotActivationPageContainer;
