import actionCreatorFactory from 'typescript-fsa';
import {
  Equipment,
  GbsVoipDetails,
  SwapTrackedEquipmentParams,
  SelectedEquipmentParams,
  ActivateVciEquipmentParams,
  ErrorAPIMessage,
} from '../../definitions/types';
import {
  applicationFlowChangedParams,
  SubmitAccessPointParams,
  AuthorizeInstallerParams,
  AuthorizeInstallerResult,
  FetchPartyResult,
  ActivateQoiResult,
  CheckQoiParams,
  CheckQoiResult,
  UpdateVoipStatusResult,
  ValidateCreditCardParams,
  ValidateCreditCardResult,
  ICreditCardValidationError,
  IInitActivationData,
  ActivateResult,
  CheckStatusResult,
  ContractInstanceResult,
  PromptForLegacyRedirectParams,
  UpdateGBSVoipParams,
  UpgradeEquipmentDetails,
  ActivateQoiParams,
  SwapResReplacementOption,
  ActivateModemSwappedResult,
  ModemMacAddressPayload,
  GetSwapResEquipmentResult,
  ProvisionSwapResult,
  SwapReplacementCheckResponse,
} from './actionInterfaces';

export const ACTION_NAMESPACE = 'ACTIVATION';

const actionCreator = actionCreatorFactory(ACTION_NAMESPACE);

export const FETCH_PARTY_ACTION = 'FETCH_PARTY';
export const FETCH_CONTRACT_ACTION = 'FETCH_CONTRACT';
export const ACTIVATE_QOI_ACTION = 'ACTIVATE_QOI';

export const resetErrorAction = actionCreator('RESET_ERROR');
export const restartAction = actionCreator('RESTART');

export const receiveInitActivation = actionCreator<IInitActivationData>('receiveInitActivation');
export const applicationFlowChangedAction = actionCreator<applicationFlowChangedParams>('APP_STATE_CHANGED');
export const validateSwapMacAddressAction = actionCreator.async<{}, ActivateResult, ErrorAPIMessage>(
  'VALIDATE_SWAP_MAC_ADDRESS'
);
export const promptForLegacyRedirectAction = actionCreator<PromptForLegacyRedirectParams>(
  'SET_PROMPT_FOR_LEGACY_REDIRECT'
);
export const setModemMacAddressAction = actionCreator<ModemMacAddressPayload>('SET_MODEM_MAC_ADDRESS');
export const validateExistingSwapEquipmentAction = actionCreator.async<
  SwapResReplacementOption,
  SwapReplacementCheckResponse,
  ErrorAPIMessage
>('VALIDATE_EXISTING_SWAP_EQUIPMENT');

export const getAllEquipmentByPIAction = actionCreator.async<{}, UpgradeEquipmentDetails[], ErrorAPIMessage>(
  'GET_ALL_EQUIPMENT_BY_PI'
);

export const activateVciEquipmentAction = actionCreator.async<
  ActivateVciEquipmentParams,
  ActivateResult,
  ErrorAPIMessage
>('ACTIVATE_VCI_EQUIPMENT');
export const getAllVCIEquipmentAction = actionCreator.async<{}, UpgradeEquipmentDetails[], ErrorAPIMessage>(
  'GET_ALL_VCI_EQUIPMENT'
);
export const getAllResEquipmentAction = actionCreator.async<{}, GetSwapResEquipmentResult, ErrorAPIMessage>(
  'GET_ALL_RES_EQUIPMENT'
);
export const provisionResSwapEquipmentAction = actionCreator.async<
  SwapResReplacementOption,
  ProvisionSwapResult,
  ErrorAPIMessage
>('PROVISION_RES_SWAP_EQUIPMENT');
export const getResSwapOrderStatusChangeAction = actionCreator.async<
  {orderId: string},
  ActivateResult,
  ErrorAPIMessage
>('GET_ORDER_STATUS_CHANGE');

export const submitTwoPointFourGHzDataAction = actionCreator.async<SubmitAccessPointParams, {}, ErrorAPIMessage>(
  'WIFI_TWOPOINTFOURGHZ_DATA_SUBMITTED'
);
export const submitFiveGHzDataAction = actionCreator.async<SubmitAccessPointParams, {}, ErrorAPIMessage>(
  'WIFI_FIVEGHZ_DATA_SUBMITTED'
);
export const setInstallationTypeSelectedAction = actionCreator('INSTALLATION_TYPE_SELECTED');
export const authorizeInstallerAction = actionCreator.async<
  AuthorizeInstallerParams,
  AuthorizeInstallerResult,
  ErrorAPIMessage
>('AUTHORIZE_INSTALLER');
export const fetchPartyAction = actionCreator.async<{}, FetchPartyResult, ErrorAPIMessage>(FETCH_PARTY_ACTION);
export const activateQoiAction = actionCreator.async<ActivateQoiParams, ActivateQoiResult, ErrorAPIMessage>(
  ACTIVATE_QOI_ACTION
);
export const activateQoiSwapAction = actionCreator.async<ActivateQoiParams, ActivateQoiResult, ErrorAPIMessage>(
  'ACTIVATE_QOI_SWAP'
);
export const logQoiBypassAction = actionCreator.async<{}, {}, ErrorAPIMessage>('LOG_QOI_BYPASS');
export const checkQoiStatusAction = actionCreator.async<CheckQoiParams, CheckQoiResult, ErrorAPIMessage>(
  'CHECK_QOI_STATUS'
);
export const updateVoIPStatusAction = actionCreator.async<{}, UpdateVoipStatusResult, ErrorAPIMessage>('ACTIVATE_VOIP');
export const getGbsVoIPAction = actionCreator.async<{}, GbsVoipDetails[], ErrorAPIMessage>('GET_GBS__VOIP');
export const startGbsVoIPActivationAction = actionCreator.async<
  UpdateGBSVoipParams,
  CheckStatusResult,
  ErrorAPIMessage
>('UPDATE_GBS__VOIP');
export const activateModemAction = actionCreator.async<{}, ActivateResult, ErrorAPIMessage>('ACTIVATE_MODEM');
export const activateModemSwapAction = actionCreator.async<{}, ActivateModemSwappedResult, ErrorAPIMessage>(
  'ACTIVATE_MODEM_SWAP'
);
export const checkModemActivationStatusAction = actionCreator.async<{}, CheckStatusResult, ErrorAPIMessage>(
  'CHECK_MODEM_STATUS'
);
export const checkSwappedModemActivationStatusAction = actionCreator.async<{}, CheckStatusResult, ErrorAPIMessage>(
  'CHECK_SWAPPED_MODEM_STATUS'
);
export const trackEquipmentAction = actionCreator.async<Equipment, {}, ErrorAPIMessage>('TRACK_EQUIPMENT');
export const swapTrackedEquipmentAction = actionCreator.async<SwapTrackedEquipmentParams, {}, ErrorAPIMessage>(
  'SWAP_TRACKED_EQUIPMENT'
);
export const setSelectedSwapEquipmentAction = actionCreator<SelectedEquipmentParams>('SELECT_SWAP_VCI_EQUIPMENT');
export const setSelectedReplacementOptionAction = actionCreator<SwapResReplacementOption>('SELECT_SWAP_RES_EQUIPMENT');
export const getVoipSacAction = actionCreator.async<{}, string, ErrorAPIMessage>('GET_VOIP_SAC');
export const resetSelectedSwapEquipmentAction = actionCreator('RESET_SELECTED_SWAP_EQUIPMENT');

export const validateCreditCardAction = actionCreator.async<
  ValidateCreditCardParams,
  ValidateCreditCardResult,
  ICreditCardValidationError
>('VALIDATE_CREDIT_CARD');
export const activateHotspotAction = actionCreator.async<{}, ActivateResult, ErrorAPIMessage>('ACTIVATE_HOTSPOT');
export const checkHotspotActivationStatusAction = actionCreator.async<{}, CheckStatusResult, ErrorAPIMessage>(
  'CHECK_HOTSPOT_STATUS'
);
export const activateWifiAction = actionCreator.async<{}, ActivateResult, ErrorAPIMessage>('ACTIVATE_WIFI');
export const checkWifiActivationStatusAction = actionCreator.async<{}, CheckStatusResult, ErrorAPIMessage>(
  'CHECK_WIFI_STATUS'
);
export const getContractInstanceAction = actionCreator.async<{}, ContractInstanceResult, ErrorAPIMessage>(
  FETCH_CONTRACT_ACTION
);
export const checkContractStatusAction = actionCreator.async<{}, ContractInstanceResult, ErrorAPIMessage>(
  'CHECK_CONTRACT_STATUS'
);

export const validateFlowSelectionAndAuthoriseAction = actionCreator.async<
  AuthorizeInstallerParams,
  AuthorizeInstallerResult,
  ErrorAPIMessage
>('VALIDATE_FLOW_SELECTION_AND_AUTHORISE');
