import React from 'react';
import {path} from 'ramda';
import {PageContainer} from '../../common/AppLayout';

import ButtonContainer from '../../common/ButtonContainer';

import {FormattedMessage} from 'react-intl.macro';

import {CustomerDetails, InstallationTypesCodes} from '../../../definitions/types';
import RestartButton from '../../common/RestartButton';
import {Card, Text, TextField, Loading, Button, Title} from '@viasat/components';

export interface Props {
  party: CustomerDetails;
  isBusy: boolean;
  proceedToNextStep: () => void;
  installationType: InstallationTypesCodes;
}

const ValidateContactPage: React.FC<Props> = ({party, isBusy, proceedToNextStep, installationType}) => {
  return (
    <PageContainer data-test-el="validate-contact-page">
      <Title>
        {installationType === InstallationTypesCodes.GBS ? (
          <FormattedMessage id="details.validate.title.gbs" defaultMessage="Business Details" />
        ) : (
          <FormattedMessage id="contact.validate.title" defaultMessage="Customer Contact Details" />
        )}
      </Title>
      <Card>
        <Text textAlign="center" mt={3} mb={4} display="block">
          <FormattedMessage
            id="contact.validate.intro"
            defaultMessage="Please validate the Customer Contact details below, if incorrect contact Installer Relations."
          />
        </Text>
        <TextField
          id="emailaddress"
          fullWidth={true}
          label={<FormattedMessage id="contact.validate.email.label" defaultMessage="Email" />}
          mt={1}
          mb={3}
          value={path(['pii', 'contactDetails', 'emailAddress'], party)}
          disabled={true}
        />

        <TextField
          id="phonenumber"
          fullWidth={true}
          label={<FormattedMessage id="contact.validate.primaryphone.label" defaultMessage="Primary phone number" />}
          mt={1}
          mb={3}
          value={path(['pii', 'contactDetails', 'phoneNumber'], party)}
          disabled={true}
        />
        <TextField
          id="mobilenumber"
          fullWidth={true}
          label={<FormattedMessage id="contact.validate.whatsapp.label" defaultMessage="WhatsApp number" />}
          value={path(['pii', 'contactDetails', 'mobileNumber'], party)}
          mt={0}
          mb={1}
          disabled={true}
        />
      </Card>
      <ButtonContainer>
        {isBusy ? <Loading variant="large" p={1} /> : null}
        <RestartButton />
        <Button
          disabled={isBusy}
          onClick={() => {
            proceedToNextStep();
          }}
          data-test-el="validate-contact-continue-button"
        >
          <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
        </Button>
      </ButtonContainer>
    </PageContainer>
  );
};

export default ValidateContactPage;
