export const macAddressRegex =
  '^((([a-fA-F0-9][a-fA-F0-9][-]){5}|([a-fA-F0-9][a-fA-F0-9][:]){5})([a-fA-F0-9][a-fA-F0-9])$)|(^([a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][.]){2}([a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9]))$';
export const serviceActivationCodeRegex = '^[0-9a-fA-F]{8,9}$';
export const apSerialFormat = '^[0-9a-zA-Z/]{12,}$';
export const ncSerialFormat = '^[0-9a-zA-Z]{12}/[0-9]{3}$';
export const serialNumber8AlphaNumeric = '^[a-zA-Z0-9]{8}$';
export const serialNumber12AlphaNumeric = '^[0-9a-zA-Z]{12}$';
export const serialNumber10AlphaNumeric = '^[0-9a-zA-Z]{10}$';
export const serialNumber16AlphaNumeric = '^[a-zA-Z0-9]{16}$';
export const siteIdRegex = '^[0-9]{1,9}$';
export const normaliseMac = (mac: string): string =>
  mac
    .replace(/[-:.]/g, '')
    .match(/.{1,2}/g)!
    .join(':');
