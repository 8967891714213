import React from 'react';
import {Button} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import ActivationStatusCard from '../../common/ActivationStatus/ActivationStatusCard';
import ButtonContainer from '../../common/ButtonContainer';
import {ConfirmationDialog} from '../../../shared-components/ConfirmationDialog';
import {GbsVoipActivationPageProps} from './gbsVoipActivationPage';
import {GbsVoipDetails} from '../../../definitions/types';
import {ActivationStatus} from '../../util/ActivationStatus';
import RestartButton from '../../common/RestartButton';

const canProceed = (voipDetails: GbsVoipDetails[]) => {
  if (voipDetails && voipDetails.length) {
    return (
      voipDetails.some((item) => {
        return item.status === ActivationStatus.ACTIVE;
      }) &&
      voipDetails.every((item) => {
        return !item.isLoading;
      })
    );
  }
  return false;
};
export const GbsVoipActivationDefaultState: React.FC<GbsVoipActivationPageProps> = ({
  voipDetails,
  openConfirmationDialog,
  startGbsVoipActivation,
  proceedToSuccess,
  isDialogOpen,
}) => {
  return (
    <>
      {voipDetails &&
        voipDetails.map((gbsVoip, index) => (
          <ActivationStatusCard
            voipDetail={gbsVoip}
            status={gbsVoip.status}
            errorMessage={gbsVoip.error}
            lineNumber={gbsVoip.lineNumber}
            index={index + 1}
            voipPi={gbsVoip.voipPi}
            key={gbsVoip.voipPi}
            isLoading={gbsVoip.isLoading}
            startGbsVoipActivation={startGbsVoipActivation}
          />
        ))}
      <ButtonContainer>
        <RestartButton />
        <Button onClick={openConfirmationDialog} disabled={!canProceed(voipDetails)} data-test-el="voip-finish-button">
          <FormattedMessage id="button.finish" defaultMessage="Finish" />
        </Button>
      </ButtonContainer>
      {isDialogOpen && (
        <ConfirmationDialog
          disableBackdropClick={true}
          handleClose={(proceed: boolean) => {
            proceedToSuccess(proceed);
          }}
          open={isDialogOpen}
          title={{
            id: 'voip.confirmation.dialog.title',
            defaultMessage: 'Are you sure you want to finish this service activation?',
          }}
          body={{
            id: 'voip.confirmation.dialog.body',
            defaultMessage: "You won't be able to restart or resume it afterwards",
          }}
        />
      )}
    </>
  );
};
