import React from 'react';
import styled from 'styled-components';
import {SpaceProps, space, LayoutProps, layout, color, ColorProps, TypographyProps, typography} from 'styled-system';

export const AppContainer = styled.div<SpaceProps>`
  ${space}
`;

export const BodyContainer = styled.div<SpaceProps & LayoutProps>`
  ${space}
  ${layout}
`;

BodyContainer.defaultProps = {
  p: 0,
};

export const PageContainerInner = styled.div<SpaceProps>`
  ${space}
`;

PageContainerInner.defaultProps = {
  pl: 3,
  pr: 3,
};

export const PageContainer = styled(({className, children, ...rest}) => (
  <div className={className}>
    <PageContainerInner {...rest}>{children}</PageContainerInner>
  </div>
))<SpaceProps & LayoutProps>`
  ${space}
  ${layout}
`;

PageContainer.defaultProps = {
  p: 0,
  pt: 4,
  pb: 4,
  mt: 1,
  mb: '71px', // sticky footer height
  ml: 'auto',
  mr: 'auto',
  maxWidth: '748px',
};

export const TextContainer = styled.div<SpaceProps & TypographyProps & ColorProps>`
  ${space}
  ${typography}
  ${color}
`;

TextContainer.defaultProps = {
  mb: 3,
  lineHeight: '28px',
  color: 'text',
};
