import {SnackMessage} from './reducer';
import actionCreatorFactory from 'typescript-fsa';
import {ChangeLocaleParams, ReceiveInitAppParams, SetInstallationTypeParams} from './actionInterfaces';
import {ErrorAPIMessage, UiConfigResult} from '../../definitions/types';

const actionCreator = actionCreatorFactory('SYSTEM');

export const INSTALLATION_TYPE_COUNTRY_KEY = 'installationTypeAndCountryConfiguration';

export const receiveInitApp = actionCreator<ReceiveInitAppParams>('ReceiveInitApp');
export const changeLocale = actionCreator.async<ChangeLocaleParams, any, any>('ChangeLocale');
export const queueSnackMessage = actionCreator<SnackMessage>('QueueSnackMessage');
export const clearSnackMessage = actionCreator<string>('ClearSnackMessage');
export const closeSnackMessage = actionCreator<string>('CloseSnackMessage');
export const fetchConfigAction = actionCreator.async<{}, UiConfigResult, ErrorAPIMessage>('FETCH_CONFIG');
export const setInstallationTypeAction = actionCreator.async<SetInstallationTypeParams, {}, ErrorAPIMessage>(
  'INSTALLATION_TYPE_SET'
);

export interface SystemInitParams {
  locale: string;
}
