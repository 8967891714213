import React from 'react';
import {FormattedMessage} from 'react-intl';

import CheckCircle from '@material-ui/icons/CheckCircle';
import {withTheme} from 'styled-components';
import {StyledProps} from '../../theme/theme';
import {Button, StatusCard} from '@viasat/components';
import ButtonContainer from './ButtonContainer';

export interface GenericSuccessComponentProps {
  onContinue: () => void;
  descriptionMessage: {
    id: string;
    defaultMessage: string;
  };
  buttonActionMessage?: {
    id: string;
    defaultMessage: string;
  };
}

const GenericSuccessComponent: React.FC<GenericSuccessComponentProps & StyledProps> = ({
  theme,
  descriptionMessage,
  buttonActionMessage,
  onContinue,
}) => {
  return (
    <>
      <StatusCard
        body={<FormattedMessage id={descriptionMessage.id} defaultMessage={descriptionMessage.defaultMessage} />}
        icon={<CheckCircle style={{height: '140px', width: '140px', color: theme.colors.primary}} />}
      />
      <ButtonContainer>
        <Button onClick={onContinue} data-test-el="generic-success-component-continue-button">
          <FormattedMessage
            id={buttonActionMessage ? buttonActionMessage.id : 'page.continue.button'}
            defaultMessage={buttonActionMessage ? buttonActionMessage.defaultMessage : 'Continue'}
          />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default withTheme(GenericSuccessComponent);
