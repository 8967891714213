import * as React from 'react';
import {clearSnackMessage} from '../../store/system/actions';
import {IRootState} from '../../store';
import * as Redux from 'react-redux';
import {withSnackbar} from 'notistack';
import {SnackMessage} from '../../store/system/reducer';
export interface StateProps {
  snackMessages: SnackMessage[];
}

export interface DispatchProps {
  clearSnackMessage(key: string): void;
  enqueueSnackbar(message: string, options: any): void;
  closeSnackbar(key?: string): void;
}

export interface OwnProps {}

class SystemNotififier extends React.Component<StateProps & DispatchProps> {
  displayed: string[] = [];

  private _persistDisplayed = (id: string) => {
    this.displayed = [...this.displayed, id];
  };

  public shouldComponentUpdate({snackMessages: newSnacks = [] as SnackMessage[]}) {
    const {snackMessages: currentSnacks} = this.props;

    for (const newSnack of newSnacks) {
      // Checks if snack should be closed and removed from state
      if (newSnack.dismissed && newSnack.key) {
        this.props.closeSnackbar(newSnack.key);
        this.props.clearSnackMessage(newSnack.key);
      }

      // Checks if there is any new snack to show
      let notExists = !currentSnacks.find(({key}) => newSnack.key === key);
      if (notExists) {
        return true;
      }
    }
    return false;
  }

  public componentDidUpdate() {
    const {snackMessages = []} = this.props;

    snackMessages.forEach((message: any) => {
      if (this.displayed.indexOf(message.key) === -1) {
        this.props.enqueueSnackbar(message.message, {
          ...message.options,
          key: message.key,
          onClose: (event, reason, key) => {
            if (message.options.onClose) {
              message.options.onClose(event, reason, key);
            }
            this.props.clearSnackMessage(message.key);
          },
        });
        this._persistDisplayed(message.key);
      }
    });
  }

  public render() {
    return null;
  }
}

const SystemNotififierConnected = Redux.connect(
  (state: IRootState): StateProps => ({
    snackMessages: state.system.snackMessages,
  }),
  (dispatch: any): Partial<DispatchProps> => ({
    clearSnackMessage: (key: string) => dispatch(clearSnackMessage(key)),
  })
)(SystemNotififier as any);

export default withSnackbar(SystemNotififierConnected as any);
