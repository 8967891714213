import * as React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../store';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import LocaleSwitcher from '../../shared-components/LocaleSwitcher';
import {changeLocale} from '../../store/system/actions';

export interface StateProps {
  currentLocale: string;
}
export interface DispatchProps {
  changeLocale: (locale: string) => void;
}
export type Props = StateProps & DispatchProps;

const ConnectedLocaleSwitcher: React.FC<Props> = (props) => (
  <LocaleSwitcher currentLocale={props.currentLocale} onSelect={props.changeLocale} />
);

export default connect<StateProps, DispatchProps>(
  (state: any) => ({
    currentLocale: state.system.locale,
  }),
  (dispatch: ThunkDispatch<IRootState, {}, AnyAction>): DispatchProps => ({
    changeLocale: (locale: string) => dispatch(changeLocale.started({locale})),
  })
)(ConnectedLocaleSwitcher);
