import React from 'react';
import {FormattedMessage} from 'react-intl';

import {withTheme} from 'styled-components';
import {StyledProps} from '../../../../theme/theme';
import Error from '@material-ui/icons/Error';
import {StatusCard} from '@viasat/components';

const HotspotActivationError: React.FunctionComponent<StyledProps> = ({theme}) => {
  return (
    <StatusCard
      body={
        <FormattedMessage
          id="hotspot.activation.error"
          defaultMessage="Unfortunately your hotspot was not activated."
        />
      }
      icon={<Error style={{height: '140px', width: '140px', color: theme.colors.RED}} />}
    />
  );
};

export default withTheme(HotspotActivationError);
