import React from 'react';
import {PageContainer} from '../../common/AppLayout';
import ButtonContainer from '../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl';
import RestartButton from '../../common/RestartButton';
import HotspotActivationDefault from './hotspotActivatePageComponents/HotspotActivationDefault';
import HotspotActivationLoading from './hotspotActivatePageComponents/HotspotActivationLoading';
import HotspotActivationSuccess from './hotspotActivatePageComponents/HotspotActivationSuccess';
import HotspotActivationError from './hotspotActivatePageComponents/HotspotActivationError';
import {Button, Title} from '@viasat/components';

export interface Props {
  isBusy: boolean;
  hotspotActivationStatus?: string;
  wifiActivationStatus?: string;
  activateWifi: () => void;
  checkWifiActivationStatus: () => void;
  onRestart: () => void;
}

const HotspotActivationPage: React.FC<Props> = ({
  isBusy,
  hotspotActivationStatus,
  activateWifi,
  onRestart,
  wifiActivationStatus,
}) => {
  let state = 'DEFAULT';

  if (isBusy) {
    state = 'LOADING';
  }

  if (wifiActivationStatus === 'ACTIVATING_ERROR') {
    state = wifiActivationStatus;
  }

  if (hotspotActivationStatus) {
    state = hotspotActivationStatus.toUpperCase();
  }

  return (
    <PageContainer data-test-el="modem-activate-page">
      <Title>
        <FormattedMessage id="hotspot.title" defaultMessage="Hotspot Activation" />
      </Title>
      {
        {
          DEFAULT: <HotspotActivationDefault />,
          LOADING: <HotspotActivationLoading />,
          ACTIVE: <HotspotActivationSuccess />,
          ACTIVATING_ERROR: <HotspotActivationError />,
        }[state]
      }
      <ButtonContainer>
        {state !== 'ACTIVE' && <RestartButton />}
        {state === 'DEFAULT' && (
          <Button data-test-el="hotspot-activate-button" onClick={activateWifi}>
            <FormattedMessage id="service.activation.activate.button.vci" defaultMessage="Activate" />
          </Button>
        )}
        {state === 'ACTIVATING_ERROR' && (
          <Button data-test-el="hotspot-activate-retry-button" onClick={onRestart}>
            <FormattedMessage id="page.retry.button" defaultMessage="Retry" />
          </Button>
        )}
      </ButtonContainer>
    </PageContainer>
  );
};

export default HotspotActivationPage;
