import React from 'react';
import {PageContainer} from '../../common/AppLayout';
import GenericErrorComponent, {GenericErrorComponentProps} from '../../common/GenericErrorComponent';

const ResSwapValidateErrorComponent: React.FunctionComponent = () => {
  const errorProps: GenericErrorComponentProps = {
    headerDescriptionMessage: {
      id: 'failure.validate.equipment.replacement.title',
      defaultMessage: 'There are open orders being processed',
    },
    bodyMessage: {
      id: 'failure.validate.equipment.replacement.body',
      defaultMessage:
        'The replacement product cannot be submitted until the open orders are processed. Please click cancel to reattempt the swap.',
    },
  };
  return (
    <PageContainer data-test-el="swap-validate-check-error-page" mt={5}>
      <GenericErrorComponent {...errorProps} />
    </PageContainer>
  );
};

export default ResSwapValidateErrorComponent;
