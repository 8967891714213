import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ResSwapEquipmentPage from './resSwapEquipmentPage';
import {
  getAllResEquipmentAction,
  getResSwapOrderStatusChangeAction,
  provisionResSwapEquipmentAction,
  resetErrorAction,
  validateExistingSwapEquipmentAction,
  validateSwapMacAddressAction,
} from '../../../store/activation/actions';
import {setModemMacAddress, setSelectedReplacementOption} from '../../../store/activation/activationHelpers';
import Error from '../../common/Error';
import LoaderComponent from '../../common/LoaderComponent';
import {SwapResReplacementOption} from '../../../store/activation/actionInterfaces';
import {EquipmentTypeEnum} from '../../../definitions/types';
import ResSwapMacAddressForm from './resSwapMacAddressForm';
import {IRootState} from '../../../store';
import ResSwapProgressComponent from './resSwapProgressComponent';
import ResSwapValidateErrorComponent from './resSwapValidateErrorComponent';
import {globalIntl} from '../../../utils/IntlGlobalProvider';
import {normaliseMac} from '../../util/regex';

enum SwapFormState {
  EquipmentSelectionState,
  MacAddressConfigState,
}

const ResSwapEquipmentPageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    getAllResEquipment: () => dispatch(getAllResEquipmentAction.started({})),
    resetError: () => dispatch(resetErrorAction()),
    provisionModem: (equipment: SwapResReplacementOption) => {
      dispatch(provisionResSwapEquipmentAction.started(equipment));
    },
    getSwapStatusChange: (orderId) => {
      dispatch(getResSwapOrderStatusChangeAction.started({orderId}));
    },
    setAndValidateMacAddress: (macAddress: string) => {
      const normalisedMac = normaliseMac(macAddress);
      dispatch(setModemMacAddress(normalisedMac));
      dispatch(validateSwapMacAddressAction.started({}));
    },
    validateEquipmentAndProvisionModem: (equipment: SwapResReplacementOption) => {
      dispatch(validateExistingSwapEquipmentAction.started(equipment));
    },
  };
  const stateProps = useSelector((state: IRootState) => {
    return {
      isBusy: state.activation.isBusy,
      shouldShowDeterminantProgress: state.activation.isModemOperationBusy,
      error: state.activation.error,
      resEquipmentDetails: state.activation.installedResEquipmentDetails as SwapResReplacementOption[],
      replacementProductExists: state.activation.replacementProductExists,
      modemMacAddress: state.activation.modemMacAddress,
      swapMacAddressValidated: state.activation.swapMacAddressValidated,
      orderId: state.activation.orderId,
      selectedReplacementOption: state.activation.selectedReplacementOption,
      openOrderExists: state.activation.openOrderExists,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };
  const [pageState, setPageState] = React.useState(SwapFormState.EquipmentSelectionState);
  const [equipmentDetails, setEquipmentDetails] = React.useState(props.selectedReplacementOption);
  const performInitialCalls = () => {
    if (props.orderId) {
      props.getSwapStatusChange(props.orderId);
    } else if (!equipmentDetails) {
      props.getAllResEquipment();
    }
  };
  const proceedToNextStep = (equipment: SwapResReplacementOption) => {
    if (
      equipment.replacementProductTypeName.indexOf(EquipmentTypeEnum.IDU) >= 0 &&
      (!props.modemMacAddress || !props.swapMacAddressValidated)
    ) {
      setEquipmentDetails(equipment);
      dispatch(setSelectedReplacementOption(equipment));
      setPageState(SwapFormState.MacAddressConfigState);
    } else {
      props.provisionModem(equipment);
    }
  };

  useEffect(() => {
    performInitialCalls();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.swapMacAddressValidated) {
      props.validateEquipmentAndProvisionModem(equipmentDetails as SwapResReplacementOption);
    }
  }, [props.swapMacAddressValidated]);
  const retry = () => {
    performInitialCalls();
    props.resetError();
  };
  if (props.shouldShowDeterminantProgress) {
    return <ResSwapProgressComponent orderId={props.orderId} />;
  }
  if (props.isBusy || (!props.resEquipmentDetails && !props.error && !equipmentDetails)) {
    return (
      <LoaderComponent
        loadingText={
          equipmentDetails && props.resEquipmentDetails
            ? globalIntl.formatMessage({
                id: 'swap.equipment.check.loading.text',
                defaultMessage: 'Submitting your order... This might take up to 2 minutes',
              })
            : undefined
        }
      />
    );
  }

  if (props.error) {
    return <Error message={props.error} enableReload={true} onReload={retry} />;
  }
  if (equipmentDetails && props.openOrderExists) {
    return <ResSwapValidateErrorComponent />;
  }
  return pageState === SwapFormState.MacAddressConfigState || equipmentDetails ? (
    <ResSwapMacAddressForm
      macAddressValidated={props.swapMacAddressValidated}
      setMacAddressAndProvisionModem={props.setAndValidateMacAddress}
    />
  ) : (
    <ResSwapEquipmentPage resEquipmentDetails={props.resEquipmentDetails} proceedToNextStep={proceedToNextStep} />
  );
};
export default ResSwapEquipmentPageContainer;
