import * as React from 'react';
import styled from 'styled-components';
import {space, SpaceProps} from 'styled-system';
import translations, {getLanguagesMap} from '../i18n/locales';
import {MenuItem, Select} from '@viasat/components';

interface Props extends SpaceProps {
  className?: string;
  children?: any;
  currentLocale: string;
  onSelect: (locale: string) => void;
}

const getLocaleNativeName = (localeCode: string) => {
  let languagesMap = getLanguagesMap();

  const subCode = localeCode.split('-')[1];
  const langData = languagesMap[subCode ? localeCode.replace('-' + subCode, '-' + subCode.toUpperCase()) : localeCode];
  return langData ? langData.nativeName : localeCode;
};

const LocaleSwitcher: React.FC<Props> = ({className, currentLocale, onSelect}: Props) => {
  return (
    <div className={className}>
      <Select
        data-test-el="language-select"
        value={currentLocale}
        mt={2}
        mb={2}
        border="none"
        height="40px"
        width="auto"
        lineHeight="8px"
        arrowPosition="8px"
        fontSize={1}
        label=""
        onChange={({target: {value}}) => onSelect(value)}
      >
        {Object.keys(translations).map((locale: string) => (
          <MenuItem value={locale} key={locale}>
            {getLocaleNativeName(locale)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const LocaleSwitcherStyled = styled(LocaleSwitcher)<Props>`
  ${space}
  display: flex;
  justify-content: flex-end;
`;

LocaleSwitcherStyled.defaultProps = {
  m: 0,
};

export default LocaleSwitcherStyled;
