import React from 'react';

import {FormattedMessage} from 'react-intl';
import {Progress, Text} from '@viasat/components';

const responsiveInfoHeights = {height: ['auto', 'auto', '80px', '80px', '80px']} as any;

interface QoiPageHeaderProps {
  status?: string;
  isBusy: boolean;
}

const QoiPageHeader: React.FunctionComponent<QoiPageHeaderProps> = ({status, isBusy}) => {
  const state = status ? (isBusy ? 'LOADING' : status) : isBusy ? 'LOADING' : 'DEFAULT';
  return (
    <Text textAlign="center" mt={3} mb={4} display="block" {...responsiveInfoHeights} data-test-el="qoi-header-text">
      {
        {
          DEFAULT: (
            <FormattedMessage
              id="quality.validate.intro"
              defaultMessage="Please continue to validate the Quality of Installation"
            />
          ),
          LOADING: (
            <>
              <FormattedMessage
                id="quality.validate.intro.validating"
                defaultMessage="Validating the Quality of Installation..."
              />
              <Progress mb={'230px'} mt={6} ml={'auto'} mr={'auto'} />
            </>
          ),
          FAILED: (
            <FormattedMessage
              id="quality.validate.intro.validating"
              defaultMessage="Validating the Quality of Installation..."
            />
          ),
          RETRY: (
            <FormattedMessage
              id="quality.validate.intro.validating"
              defaultMessage="Validating the Quality of Installation..."
            />
          ),
          SUCCEEDED: (
            <FormattedMessage id="quality.validate.intro.complete" defaultMessage="Quality of Installation complete" />
          ),
        }[state]
      }
    </Text>
  );
};

export default QoiPageHeader;
