import React from 'react';
import {InstallationTypesCodes, QoiStatus} from '../../../../definitions/types';

import ButtonContainer from '../../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl';
import RestartButton from '../../../common/RestartButton';
import {Button} from '@viasat/components';

export const responsiveWidthVals = ['auto', 'auto', 'auto', '760px', '760px'] as any;
export const responsiveWidths = {width: responsiveWidthVals, minWidth: responsiveWidthVals};

interface QoiPageButtonsProps {
  status?: QoiStatus;
  isBusy: boolean;
  installationType: string;
  onRetry?: () => void;
  onContinue?: () => void;
  onValidate?: () => void;
}

const QoiPageButtons: React.FunctionComponent<QoiPageButtonsProps> = ({
  status,
  onRetry,
  onValidate,
  onContinue,
  isBusy,
  installationType,
}) => {
  const state = status ? status : isBusy ? 'LOADING' : 'DEFAULT';
  const isCwf = installationType === InstallationTypesCodes.VCI;

  return (
    <>
      <ButtonContainer {...responsiveWidths}>
        {isCwf && (
          <Button onClick={onContinue} data-test-el="qoi-skip-validation-button" mr={3} variant="secondary">
            <FormattedMessage id="page.skip.button" defaultMessage="Skip" />
          </Button>
        )}
        <RestartButton />
        {
          {
            DEFAULT: (
              <Button onClick={onValidate} data-test-el="qoi-start-validation-button">
                <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
              </Button>
            ),
            LOADING: (
              <Button disabled={isBusy} data-test-el="qoi-start-validation-button">
                <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
              </Button>
            ),
            FAILED: (
              <Button mr={3} variant="secondary" onClick={onRetry} disabled={isBusy} data-test-el="qoi-retry-button">
                <FormattedMessage id="page.retry.button" defaultMessage="Retry" />
              </Button>
            ),
            SUCCEEDED: (
              <Button onClick={onContinue} data-test-el="qoi-continue-button">
                <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
              </Button>
            ),
            RETRY: (
              <Button onClick={onContinue} disabled={isBusy} data-test-el="qoi-continue-button">
                <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
              </Button>
            ),
          }[state]
        }
      </ButtonContainer>
    </>
  );
};

export default QoiPageButtons;
