import env from '../../utils/env';
import translations from '../../i18n/locales';
import {IRootState} from '../index';
import {initApp} from '../activation/activationHelpers';
import {getQueryParams} from '../../components/util/appUtilities';
import {InstallationTypesCodes} from '../../definitions/types';
import {fetchConfigAction, INSTALLATION_TYPE_COUNTRY_KEY, receiveInitApp, SystemInitParams} from './actions';

export const persistField = (fieldName: string, value: any) => {
  if (localStorage) {
    localStorage.setItem(fieldName, JSON.stringify(value));
  }
};
export const persistLocale = (locale: string) => {
  document.getElementsByTagName('html')[0].setAttribute('lang', locale);
  if (localStorage) {
    localStorage.setItem('locale', locale);
  }
};
export const getLocale = () => {
  const DEFAULT_LOCALE = env.defaultLocale as any;
  if (localStorage) {
    const locale = localStorage.getItem('locale') || DEFAULT_LOCALE;
    return Object.keys(translations).indexOf(locale) !== -1 ? locale : DEFAULT_LOCALE;
  } else {
    return DEFAULT_LOCALE;
  }
};
export const getInstallationTypeAndCountry = () => {
  const installationTypeConfiguration = localStorage && localStorage.getItem(INSTALLATION_TYPE_COUNTRY_KEY);
  const {installationSubtype} = installationTypeConfiguration
    ? JSON.parse(installationTypeConfiguration)
    : {installationSubtype: null};
  const {countryCode} = getQueryParams();
  if (installationTypeConfiguration && installationSubtype) {
    const {installationType, country} = JSON.parse(installationTypeConfiguration);
    return installationType === InstallationTypesCodes.RES && countryCode
      ? [installationType, countryCode, installationSubtype]
      : [installationType, country, installationSubtype];
  } else {
    return env.installationType === InstallationTypesCodes.RES && countryCode
      ? [env.installationType, countryCode, env.installationSubtype]
      : [env.installationType, env.country, env.installationSubtype];
  }
};

export function init() {
  return (dispatch: any, getState: () => IRootState) => {
    return new Promise<SystemInitParams | undefined>(async (resolve, reject) => {
      try {
        const locale = getLocale();
        const [installationType, country, installationSubtype] = getInstallationTypeAndCountry();
        persistLocale(locale);

        dispatch(initApp());
        dispatch(receiveInitApp({locale, installationType, country, installationSubtype}));
        dispatch(fetchConfigAction.started({}));
        resolve({locale});
      } catch (error) {
        reject({error});
      }
    });
  };
}
