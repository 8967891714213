import * as React from 'react';
import ErrorMessage, {ErrorProps} from '../../shared-components/ErrorMessage';
import {FormattedMessage} from 'react-intl';

export default (props: ErrorProps) => (
  <ErrorMessage
    {...props}
    localizedRetryText={<FormattedMessage id={'common.error.button.retry'} defaultMessage="Retry" />}
    localizedHeaderText={<FormattedMessage id={'common.error.header.title'} defaultMessage="An Error has occurred" />}
    localizedReloadText={<FormattedMessage id={'common.error.button.reload'} defaultMessage="Reload" />}
  />
);
