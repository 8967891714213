import ButtonContainer from '../../common/ButtonContainer';
import LoaderComponent from '../../common/LoaderComponent';
import React, {useState} from 'react';
import RestartButton from '../../common/RestartButton';
import {Button, Card, TextField, Title, ValidatorForm} from '@viasat/components';
import {CountryCodes, ErrorAPIMessage} from '../../../definitions/types';
import {FormattedMessage} from 'react-intl.macro';
import {PageContainer, TextContainer} from '../../common/AppLayout';
import {
  serialNumber10AlphaNumeric,
  serialNumber12AlphaNumeric,
  serialNumber16AlphaNumeric,
  serialNumber8AlphaNumeric,
} from '../../util/regex';

export interface Props {
  trackEquipment: (posSerialNumber: string, upsSerialNumber: string) => void;
  isBusy: boolean;
  error?: ErrorAPIMessage;
  country: string;
}

const EquipmentTrackingPage: React.FC<Props> = ({isBusy, trackEquipment, country}) => {
  const [validationErrors, setValidationError] = useState([]);
  const [posSerialNumber, setPOSSerialNumber] = useState('');
  const [upsSerialNumber, setUpsSerialNumber] = useState('');
  const isCountryBrazil = country === CountryCodes.BR;
  const hasError = (fieldId: string): boolean =>
    validationErrors.filter((e: {props: {id: string}}) => e.props.id === fieldId).length > 0;

  if (isBusy) {
    return <LoaderComponent />;
  }

  return (
    <PageContainer data-test-el="track-equipment-page">
      <Title data-test-el="track-equipment-heading">
        <FormattedMessage id="track.equipment.title" defaultMessage={'Add Tablet and UPS Tracking'} />
      </Title>
      <TextContainer>
        <FormattedMessage
          id="track.equipment.text"
          defaultMessage={'Please enter all the equipment serial numbers for tracking all the site assets.'}
        />
      </TextContainer>
      <ValidatorForm
        onSubmit={() => trackEquipment(posSerialNumber, upsSerialNumber)}
        onError={(error) => {
          setValidationError(error);
        }}
      >
        <Card>
          <TextField
            id="posSerialNumber"
            fullWidth={true}
            label={<FormattedMessage id="tablet.label" defaultMessage="Tablet Serial Number" />}
            onChange={({target}) => {
              setValidationError([]);
              setPOSSerialNumber(target.value.trim());
            }}
            value={posSerialNumber}
            mt={0}
            mb={2}
            useValidator={true}
            validators={[
              isCountryBrazil
                ? `matchRegexp:${serialNumber16AlphaNumeric}`
                : `matchRegexp:${serialNumber8AlphaNumeric}`,
              'required',
            ]}
            error={hasError('posSerialNumber')}
            errorMessages={[
              <FormattedMessage
                id={isCountryBrazil ? 'serial.number.16alpha.numeric.error' : 'serial.number.8alpha.numeric.error'}
                defaultMessage={`The serial number should be ${
                  isCountryBrazil ? '16' : '8'
                } alpha numeric characters long`}
              />,
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
            ]}
          />
          <TextField
            id="upsSerialNumber"
            fullWidth={true}
            label={<FormattedMessage id="ups.label" defaultMessage="UPS Serial Number" />}
            value={upsSerialNumber}
            onChange={({target}) => {
              setValidationError([]);
              setUpsSerialNumber(target.value.trim());
            }}
            mt={2}
            mb={0}
            useValidator={true}
            validators={[
              isCountryBrazil
                ? `matchRegexp:${serialNumber10AlphaNumeric}`
                : `matchRegexp:${serialNumber12AlphaNumeric}`,
              'required',
            ]}
            error={hasError('upsSerialNumber')}
            errorMessages={[
              <FormattedMessage
                id={isCountryBrazil ? 'serial.number.10alpha.numeric.error' : 'serial.number.12alpha.numeric.error'}
                defaultMessage={`The serial number should be ${
                  isCountryBrazil ? '10' : '12'
                } alpha numeric characters long`}
              />,
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
            ]}
          />
        </Card>
        <ButtonContainer>
          <RestartButton />
          <Button type="submit" disabled={isBusy} data-test-el="track-equipment-continue-button">
            <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
          </Button>
        </ButtonContainer>
      </ValidatorForm>
    </PageContainer>
  );
};

export default EquipmentTrackingPage;
