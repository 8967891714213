import {Observable, of} from 'rxjs';
import {Action} from 'redux';
import {combineEpics, StateObservable} from 'redux-observable';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {validateCreditCardAction} from '../../actions';
import {validateCreditCard} from '../../services';
import {generateMessage} from '../../../../shared-components/ErrorMessage';

export const validateCreditCardEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(validateCreditCardAction.started.match),
    switchMap((action) => {
      const {last4Digits} = action.payload;
      return validateCreditCard(last4Digits).pipe(
        map((response: any) => {
          return response.response
            ? validateCreditCardAction.done(response.response)
            : validateCreditCardAction.failed({
                error: {
                  ...generateMessage('The last 4 digits did not match those on file', 'credit.card.error.description', {
                    message:
                      'Please retry with a different card or contact Customer Care at  <b><a href=\\"tel:+1-855-810-1308\\">+1-855-810-1308</a></b>',
                  }),
                  creditCardMatched: false,
                },
                params: action.payload,
              });
        }),
        catchError((error) =>
          of(
            validateCreditCardAction.failed({
              error: {
                ...generateMessage(
                  'An error has occurred while validating the card',
                  'credit.card.generic.error.description',
                  error
                ),
                creditCardMatched: undefined,
              },
              params: action.payload,
            })
          )
        )
      );
    })
  );
export const PaymentEpic = combineEpics(validateCreditCardEpic);
