import React, {useState} from 'react';
import {PageContainer} from '../../common/AppLayout';

import {FormattedMessage} from 'react-intl';
import ButtonContainer from '../../common/ButtonContainer';
import {connect} from 'react-redux';
import {IRootState} from '../../../store';
import {countries, installationSubTypes, installationTypes} from '../../../definitions/constants';
import {changeLocale, setInstallationTypeAction} from '../../../store/system/actions';
import {setInstallationTypeSelected} from '../../../store/activation/activationHelpers';
import {globalIntl} from '../../../utils/IntlGlobalProvider';
import {Box, Button, Card, LoaderComponent, MenuItem, Select, Text, Title} from '@viasat/components';
import {ICountries, InstallationSubType} from '../../../definitions/types';
import {ListSubheader} from '@material-ui/core';

const CountryDefaultLocaleMap = {
  US: 'en',
  BR: 'pt-br',
  MX: 'es-mx',
  MXCFE: 'es-mx',
  GT: 'es-mx',
  NG: 'en',
};

interface Props {
  installationType: string;
  installationSubtype: InstallationSubType;
  country: string;
  onInstallationTypeSelected: (
    installationType: string,
    country: string,
    installationSubType: InstallationSubType
  ) => void;
  onLocaleChange: (locale: string) => void;
  onNextStep: () => void;
  isBusy: boolean;
}

const filterCountries = (installationTypeCode, installationSubType): ICountries[] => {
  return countries.filter((item) => {
    return (
      item.installationCodes.includes(installationTypeCode) &&
      item.installationSubtypeCodes.includes(installationSubType)
    );
  });
};
const LandingPage: React.FC<Props> = ({
  installationType,
  installationSubtype,
  country,
  onNextStep,
  onLocaleChange,
  onInstallationTypeSelected,
  isBusy,
}) => {
  const [selectedValues, setSelected] = useState([installationType, installationSubtype]);
  const handleContinue = () => {
    onNextStep();
  };
  if (isBusy) {
    return <LoaderComponent />;
  }

  return (
    <PageContainer data-test-el="landing-page">
      <Title>
        <FormattedMessage id="landingpage.title" defaultMessage="Welcome to Service Activation" />
      </Title>
      <Card>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Text textAlign="center" mt={1} mb={2} display="block" data-test-el="installation-type-header">
            <FormattedMessage id="landingpage.header1" defaultMessage="Please select installation type:" />
          </Text>

          <Select
            data-test-el="installation-type-select"
            value={selectedValues}
            mt={1}
            mb={2}
            border="none"
            height="40px"
            lineHeight="8px"
            arrowPosition="8px"
            fontSize={1}
            label=""
            renderValue={(value) => {
              if (value) {
                const installationTypeCaption = installationTypes.find((item) => {
                  return item.code === value[0];
                });
                const installationSubTypeCaption = installationSubTypes.find((item) => {
                  return item.code === value[1];
                });
                if (installationTypeCaption && installationSubTypeCaption) {
                  return `${globalIntl.formatMessage({
                    id: installationTypeCaption.key,
                    defaultMessage: installationTypeCaption.name,
                  })} -
                    ${globalIntl.formatMessage({
                      id: installationSubTypeCaption.key,
                      defaultMessage: installationSubTypeCaption.name,
                    })}`;
                }
                return `${value[0]} - ${value[1]}`;
              }
            }}
            onChange={({target: {value}}) => {
              if (value) {
                setSelected(value);
                country = filterCountries(value[0], value[1])[0].code;
                onLocaleChange(CountryDefaultLocaleMap[country]);
                onInstallationTypeSelected(value[0], country, value[1]);
              }
            }}
          >
            {installationTypes.map((businessUnit) => {
              let children: any = [];
              children.push(
                <ListSubheader key={businessUnit.name}>
                  {globalIntl.formatMessage({id: businessUnit.key, defaultMessage: businessUnit.name})}
                </ListSubheader>
              );
              const menuItems: any = businessUnit.subtypes.map((subtype) => {
                return (
                  subtype && (
                    <MenuItem
                      pl={6}
                      value={[businessUnit.code, subtype.code]}
                      key={`${businessUnit.code},${subtype.code}`}
                    >
                      {globalIntl.formatMessage({id: subtype.key, defaultMessage: subtype.name})}
                    </MenuItem>
                  )
                );
              });

              children.push(menuItems);
              return children;
            })}
          </Select>
          <Text textAlign="center" mt={3} mb={2} display="block">
            <FormattedMessage id="landingpage.header2" defaultMessage="Please select country:" />
          </Text>
          <Select
            data-test-el="country-select"
            value={country}
            mt={1}
            mb={2}
            border="none"
            height="40px"
            lineHeight="8px"
            arrowPosition="8px"
            fontSize={1}
            label=""
            onChange={({target: {value}}) => {
              onLocaleChange(CountryDefaultLocaleMap[value]);
              onInstallationTypeSelected(installationType, value, installationSubtype);
            }}
          >
            {filterCountries(installationType, installationSubtype).map((item) => (
              <MenuItem value={item.code} key={item.code}>
                {globalIntl.formatMessage({id: item.key, defaultMessage: item.name})}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Card>
      <ButtonContainer>
        <Button onClick={handleContinue} data-test-el="continue-button">
          <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
        </Button>
      </ButtonContainer>
    </PageContainer>
  );
};

export default connect(
  (state: IRootState) => ({
    installationType: state.system.installationType,
    country: state.system.country,
    installationSubtype: state.system.installationSubtype,
    isBusy: state.activation.isBusy,
  }),
  (dispatch) => ({
    onInstallationTypeSelected: (installationType: string, country: string, installationSubtype: InstallationSubType) =>
      dispatch(setInstallationTypeAction.started({installationType, country, installationSubtype})),
    onLocaleChange: (locale: string) => dispatch(changeLocale.started({locale})),
    onNextStep: () => dispatch(setInstallationTypeSelected() as any),
  })
)(LandingPage as any) as React.ComponentType<Partial<Props>>;
