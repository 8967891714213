import React from 'react';
import {FormattedMessage} from 'react-intl';

import {Card, Text} from '@viasat/components';

const HotspotActivationDefault: React.FunctionComponent = () => {
  return (
    <Card data-test-el="hotspot-default-card">
      <Text textAlign="center" mt={3} mb={6} display="block">
        <Text fontWeight="bolder" fontSize={3} textAlign="center" mt={5}>
          <FormattedMessage
            id="hotspot.activation.default.header"
            defaultMessage="The Wi-Fi equipment is ready to be activated."
          />
        </Text>
        <br />
        <Text fontWeight="bolder" fontSize={3} textAlign="center" mt={5}>
          <FormattedMessage
            id="hotspot.activation.default.text"
            defaultMessage="Select Activate to complete the Hotspot activation."
          />
        </Text>
      </Text>
    </Card>
  );
};

export default HotspotActivationDefault;
