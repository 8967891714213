import {qoiActivationSteps, QoiCategoryName} from '../../../../definitions/constants';
import {Action} from 'redux';
import {interval, Observable, of, race, throwError, timer} from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  flatMap,
  map,
  skipWhile,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  activateModemAction,
  activateQoiAction,
  checkModemActivationStatusAction,
  checkQoiStatusAction,
  logQoiBypassAction,
  restartAction,
} from '../../actions';
import {persistActivationField} from '../../activationHelpers';
import {generateMessage} from '../../../../shared-components/ErrorMessage';
import {
  activateModem,
  activateQoi,
  checkModemActivationStatus,
  checkQoiStatus,
  logQoiBypass,
  getVoipInfo,
} from '../../services';
import {combineEpics, ofType, StateObservable} from 'redux-observable';
import env from '../../../../utils/env';
import {FlowState, InstallationTypesCodes, QoiDetails, QoiStepStateEnum} from '../../../../definitions/types';
import {ActivationStatus} from '../../../../components/util/ActivationStatus';
import {handleActivationError} from '../appUtility/appUtilityEpic';
import {fetchVoIPActivationStatus} from '../voip/voipEpic';

export const fetchCommandIdEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(activateQoiAction.started.match),
    switchMap((action) => {
      return activateQoi().pipe(
        concatMap((response) => {
          const {checkQoiStatus} = action.payload;
          const {commandId} = response.response;
          if (!checkQoiStatus) {
            persistActivationField('commandId', commandId);
            return of(
              activateQoiAction.done({
                result: {commandId},
                params: {},
              })
            );
          } else {
            return of(
              activateQoiAction.done({
                result: {commandId},
                params: {},
              }),
              checkQoiStatusAction.started({commandId, delayInMs: 0})
            );
          }
        }),
        catchError((error) =>
          of(
            activateQoiAction.failed({
              error: generateMessage('Could not start activation', 'error.activation.start', error),
              params: {},
            })
          )
        )
      );
    })
  );

function fetchQoiStatus(commandId: string, delayInMs: number) {
  return race(
    timer(0, Number(env.pollingInterval)).pipe(
      delay(delayInMs),
      flatMap(() => checkQoiStatus(commandId)),
      catchError((err) =>
        throwError({
          error: err,
          friendlyMessage: 'Could not activate',
          intlId: 'error.activation',
        })
      ),
      skipWhile((response: any) => response.response.status === 'RUNNING' || response.response.status === 'CREATED'),
      take(1),
      map((response) => {
        return {result: {qoiDetails: mapQoiResponse(response.response)}, params: {commandId, delayInMs}};
      })
    ),
    interval(Number(env.qoiTimeout)).pipe(
      flatMap((err) => throwError({error: err, friendlyMessage: 'Timeout', intlId: 'error.qoi.timeout'}))
    )
  );
}

export const activateQoiEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(checkQoiStatusAction.started.match),
    switchMap((action) => {
      const {commandId, delayInMs} = action.payload;
      return fetchQoiStatus(commandId, delayInMs).pipe(
        map((response: any) => {
          return checkQoiStatusAction.done(response);
        }),
        takeUntil(action$.pipe(ofType(restartAction))),
        catchError((thrownError) => {
          return of(
            checkQoiStatusAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError.error),
              params: {commandId, delayInMs},
            })
          );
        })
      );
    })
  );

export const logQoiBypassEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(logQoiBypassAction.started.match),
    switchMap(() => {
      return logQoiBypass().pipe(
        map(() => {
          return logQoiBypassAction.done({
            result: {},
            params: {},
          });
        }),
        catchError((thrownError) =>
          of(
            logQoiBypassAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError.error),
              params: {},
            })
          )
        )
      );
    })
  );

export const activateModemEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(activateModemAction.started.match),
    switchMap(() =>
      activateModem().pipe(
        map((response: any) => {
          persistActivationField('modemActivationStarted', true);
          return activateModemAction.done({
            result: {success: response.response.success},
            params: {},
          });
        }),
        catchError((error) =>
          of(
            activateModemAction.failed({
              error: generateMessage('Could not start activation!', 'error.activation.start', error),
              params: {},
            })
          )
        )
      )
    )
  );

function fetchModemStatus() {
  return race(
    timer(0, Number(env.pollingInterval)).pipe(
      switchMap(() =>
        checkModemActivationStatus().pipe(
          catchError((err) => {
            if (!window.navigator.onLine) {
              return of({response: {status: ActivationStatus.REBOOTING}});
            } else return handleActivationError(err);
          })
        )
      ),
      skipWhile(
        (response: any) =>
          ![ActivationStatus.ACTIVE, ActivationStatus.ACTIVATING_ERROR].includes(response.response.status.toUpperCase())
      ),
      take(1),
      map((response) => ({result: {status: response.response.status}, params: {}}))
    ),
    interval(Number(env.modemActivationTimeout)).pipe(
      flatMap((err) =>
        throwError({
          error: err,
          friendlyMessage: 'Modem Activation Timeout',
          intlId: 'error.activation.timeout',
        })
      )
    )
  );
}
async function attemptPollingForVoipActivation(response: {result: {status: string}; params: any}) {
  let voipActivationStatus;
  const voipPiId = await getVoipInfo()
    .pipe(
      flatMap(async (voipInfoResponse) => {
        return voipInfoResponse.response.voipPiId;
      }),
      catchError(() => {
        //For now I am ignoring the error as I assume that failing to get the voipPI will still allow
        // the modem to be working as expected. Try to recover from this error will mean implementing
        // a retry will be a bit cumbersome at this stage it should be smart enough to skip the modem activation (while this call is transparent to the UI)
        return of(null);
      })
    )
    .toPromise();
  if (voipPiId) {
    const pollStatusResponse = await fetchVoIPActivationStatus(0, voipPiId, true)
      .toPromise()
      .catch(() => {
        return {
          result: {
            status: ActivationStatus.ACTIVATING_ERROR,
          },
        };
      });
    voipActivationStatus = pollStatusResponse.result.status as ActivationStatus;
  }
  persistActivationField('applicationFlowState', FlowState.ActivationFinished);
  return checkModemActivationStatusAction.done({
    result: {
      status: response.result.status,
      voipActivationStatus,
    },
    params: response.params,
  });
}

export const checkModemActivationStatusEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(checkModemActivationStatusAction.started.match),
    switchMap((action) => {
      return fetchModemStatus().pipe(
        flatMap(async (response: any) => {
          const installationType = state$.value.system.installationType;
          if (
            response.result.status.toUpperCase() === ActivationStatus.ACTIVE &&
            installationType === InstallationTypesCodes.RES
          ) {
            return attemptPollingForVoipActivation(response);
          } else {
            return checkModemActivationStatusAction.done(response);
          }
        }),
        takeUntil(action$.pipe(ofType(restartAction))),
        catchError((thrownError) => {
          return of(
            checkModemActivationStatusAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError.error),
              params: {},
            })
          );
        })
      );
    })
  );
function mergePeakingWithAntennaPointing(qoiResponse: QoiDetails): QoiDetails {
  const peakingCategory = qoiResponse.categories.find(
    (category) => category.name === (QoiCategoryName.PEAKING as string)
  );
  if (peakingCategory) {
    return {
      ...qoiResponse,
      categories: qoiResponse.categories.map((category) => {
        if (category.name === QoiCategoryName.ANTENNA_AFTER_BURNER) {
          return {
            ...category,
            color: peakingCategory.color === QoiStepStateEnum.RED ? peakingCategory.color : category.color,
            //explanations can be null in which case spread an empty array
            explanations: [...(category.explanations || []), ...(peakingCategory.explanations || [])],
          };
        }
        return category;
      }),
    };
  } else {
    return qoiResponse;
  }
}

function mapQoiResponse(r: any) {
  const qoiResponse = mergePeakingWithAntennaPointing(r);
  const categoryArray = qoiResponse.categories.filter((category) =>
    qoiActivationSteps.some((arr) => arr.some((stepName) => stepName.toLowerCase() === category.name.toLowerCase()))
  );

  if (categoryArray.length < 4) {
    // eslint-disable-next-line
    throw {friendlyMessage: 'Could not activate', intlId: 'error.activation'};
  }

  const hasGray = categoryArray.find((category) => category.color === QoiStepStateEnum.GRAY);
  const hasFailed = categoryArray.find((category) =>
    [QoiStepStateEnum.RED, QoiStepStateEnum.YELLOW].includes(category.color as QoiStepStateEnum)
  );

  return {
    status: hasGray && !hasFailed ? 'RETRY' : hasFailed ? 'FAILED' : qoiResponse.status,
    categories: categoryArray.map((category) =>
      category.color === QoiStepStateEnum.YELLOW
        ? {
            ...category,
            color: QoiStepStateEnum.RED,
          }
        : category
    ),
  };
}
export const InstallationEpic = combineEpics(
  checkModemActivationStatusEpic,
  activateModemEpic,
  logQoiBypassEpic,
  activateQoiEpic,
  fetchCommandIdEpic
);
