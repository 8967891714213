import React from 'react';
import ServiceActivatePage from './ServiceActivatePage';
import {
  authorizeInstallerAction,
  promptForLegacyRedirectAction,
  validateFlowSelectionAndAuthoriseAction,
} from '../../../store/activation/actions';
import {CountryCodes, InstallationTypesCodes} from '../../../definitions/types';
import {IRootState} from '../../../store';
import {LoaderComponent} from '@viasat/components';
import {normaliseMac} from '../../util/regex';
import {padWithZeros} from '../../util/appUtilities';
import {useDispatch, useSelector} from 'react-redux';
import {useInstallationFlowType} from '../../util/customHooks/useInstallationFlowType';
import {useInstallationType} from './serviceActivateComponents/useInstallationType';

const ServiceActivePageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    authorizeInstaller: (
      serviceActivationCode: string,
      installerId?: string,
      macAddress?: string,
      siteId?: string,
      dealerId?: string
    ) => dispatch(authorizeInstallerAction.started({serviceActivationCode, installerId, macAddress, siteId, dealerId})),
    unsetRedirectPrompt: () =>
      dispatch(promptForLegacyRedirectAction({shouldPromptForLegacyRedirect: false, redirectUrl: undefined})),
    validateFlowSelectionAndAuthorise: (
      serviceActivationCode: string,
      installerId?: string,
      macAddress?: string,
      siteId?: string,
      dealerId?: string
    ) =>
      dispatch(
        validateFlowSelectionAndAuthoriseAction.started({
          serviceActivationCode,
          installerId,
          macAddress,
          siteId,
          dealerId,
        })
      ),
  };
  const stateProps = useSelector((state: IRootState) => {
    return {
      isBusy: state.activation.isBusy,
      error: state.activation.error,
      installationType: state.system.installationType as InstallationTypesCodes,
      installationSubtype: state.system.installationSubtype,
      promptForLegacyRedirect: state.activation.promptForLegacyRedirect,
      redirectUrl: state.activation.redirectUrl,
      country: state.system.country as CountryCodes,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };
  const title = useInstallationFlowType(props.installationType, props.installationSubtype, props.country);
  const {isVci, isWholeSaleSwap, isResidentialNewInstall, isResidentialUpgrade} = useInstallationType(
    props.installationSubtype,
    props.installationType
  );

  const handleSubmit = (
    serviceActivationCode: string,
    installerId?: string,
    macAddress?: string,
    siteId?: string,
    dealerId?: string
  ) => {
    const paddedSiteId = isVci && siteId ? padWithZeros(siteId, 9) : undefined;
    const paddedDealerId = isWholeSaleSwap && dealerId ? padWithZeros(dealerId, 9) : undefined;
    const normalisedMac = macAddress ? normaliseMac(macAddress) : undefined;
    const installerIdInput = installerId ? installerId : undefined;

    if (props.country === CountryCodes.US && (isResidentialNewInstall || isResidentialUpgrade)) {
      props.validateFlowSelectionAndAuthorise(
        serviceActivationCode,
        installerIdInput,
        normalisedMac,
        paddedSiteId,
        paddedDealerId
      );
    } else {
      props.authorizeInstaller(serviceActivationCode, installerIdInput, normalisedMac, paddedSiteId, paddedDealerId);
    }
  };
  const redirectToLegacyPortal = (proceed: boolean) => {
    if (proceed) {
      window.location.href = props.redirectUrl!;
    }
    props.unsetRedirectPrompt();
  };

  if (props.isBusy) {
    return <LoaderComponent />;
  }

  return (
    <ServiceActivatePage
      promptForLegacyRedirect={props.promptForLegacyRedirect}
      onSubmit={handleSubmit}
      redirectToLegacyPortal={redirectToLegacyPortal}
      isBusy={props.isBusy}
      installationSubtype={props.installationSubtype}
      installationType={props.installationType}
      title={title}
    />
  );
};

export default ServiceActivePageContainer;
