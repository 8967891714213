export enum ActivationStatus {
  ACTIVE = 'ACTIVE',
  ACTIVATING = 'ACTIVATING',
  ACTIVATING_ERROR = 'ACTIVATING_ERROR',
  CREATING = 'CREATING',
  ACCEPTED = 'ACCEPTED',
  PROCESSED = 'PROCESSED',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  REBOOTING = 'REBOOTING',
}
