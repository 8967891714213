import React from 'react';
import Error from '@material-ui/icons/Error';
import {FormattedMessage} from 'react-intl';
import {StyledProps} from '../../../../theme/theme';
import {withTheme} from 'styled-components';
import {StatusCard} from '@viasat/components';

const ModemActivationError: React.FunctionComponent<StyledProps> = ({theme}) => {
  return (
    <>
      <StatusCard
        body={
          <>
            <FormattedMessage
              id="service.activation.error.text1"
              defaultMessage="Unfortunately your modem was not activated."
            />
            <br />
            <FormattedMessage
              id="service.activation.error.text2"
              defaultMessage="Verify the Modem is online then click Retry."
            />
            <br />
            <FormattedMessage
              id="service.activation.error.text3"
              defaultMessage="If this is unsuccessful, please contact Support"
            />
          </>
        }
        icon={<Error style={{height: '140px', width: '140px', color: theme.colors.RED}} />}
      />
    </>
  );
};

export default withTheme(ModemActivationError as any);
