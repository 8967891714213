import {ajax} from '../../utils/StoreUtils';
import {map} from 'rxjs/operators';
import env from '../../utils/env';
import {SwapResReplacementOption, TrackedEquipmentRequest} from './actionInterfaces';
import {ActivateVciEquipmentParams} from '../../definitions/types';

const isMock = env.isMock;

export const asPromise = async (fetchStream: any) => fetchStream.pipe(map((e: any) => e.response)).toPromise();

export const fetchConfig = () => ajax.get(`/config/ui`, isMock);
export const authorizeInstaller = (
  activationCode: string,
  installerId?: string,
  macAddress?: string,
  siteId?: string,
  dealerId?: string
) => ajax.post(`/installer/authorize`, {activationCode, installerId, macAddress, siteId, dealerId}, isMock);
export const fetchParty = () => ajax.get(`/customer/details`, isMock);
export const activateQoi = () => ajax.post(`/installation/qoi`, {}, isMock);
export const activateQoiSwap = (macAddress?: string) =>
  ajax.post(
    `/swap/qoi`,
    {
      macAddress,
    },
    isMock
  );
export const checkQoiStatus = (commandId: string) => ajax.get(`/installation/qoi/${commandId}`, isMock);
export const logQoiBypass = () => ajax.post(`/installation/qoi/bypass/log`, {}, isMock);
export const activateModem = () => ajax.put(`/installation/activation`, {}, isMock);
export const activateModemSwap = (macAddress?: string) =>
  ajax.put(
    `/swap/activateResEquipment`,
    {
      macAddress,
    },
    isMock
  );
export const checkModemActivationStatus = () => ajax.get(`/installation/activation/status`, isMock);
export const checkSwappedModemActivationStatus = (productInstanceId: string) =>
  ajax.get(`/installation/activation/status/${productInstanceId}`, isMock);

export const checkTwoPointFourGHzAccessPoint = (serialNumber: string, macAddress: string) =>
  ajax.put('/wifi/accesspoint/info', {serialNumber, macAddress}, isMock);
export const checkFiveGHzAccessPoint = (serialNumber: string, macAddress: string) =>
  ajax.put('/wifi/networkcontroller/info', {serialNumber, macAddress}, isMock);

export const callCaptivePortal = () => ajax.put(`/wifi/captivePortal`, {}, isMock);

export const validateExistingSwapEquipment = () => ajax.get('/swap/validateExistingReplacementEquipment', isMock);
export const activateHotspot = () => ajax.put(`/hotspot/activation`, {}, isMock);
export const checkHotspotActivationStatus = () => ajax.get(`/hotspot/activation/status`, isMock);

export const activateWifi = () => ajax.put(`/wifi/activation`, {}, isMock);
export const checkWifiActivationStatus = () => ajax.get(`/wifi/activation/status`, isMock);
export const validateCreditCard = (last4Digits: string) => ajax.post(`/payment/validate`, {last4Digits}, isMock);
export const getVoIpPIStatus = (voipPi: string) => ajax.get(`/voip/status/${voipPi}`, isMock);
export const getVoipInfo = () => ajax.get(`/voip/info`, isMock);
export const activateVoIP = () => ajax.put(`/voip/activation`, {}, isMock);
export const createContract = () => ajax.post(`/contract/create`, {}, isMock);
export const fetchContract = () => ajax.get(`/contract/details`, isMock);
export const getGbsVoip = () => ajax.get(`/voip/gbs`, isMock);
export const getVoipServiceActivationCode = () => ajax.get('/voip/sac/get', isMock);
export const updateGbsVoip = (macAddress: string, voipPi: string) =>
  ajax.put(`/voip/gbs/update`, {voipPi, macAddress}, isMock);
export const getAllEquipmentFromPI = () => ajax.get(`/swap/getAllEquipmentFromPI`, isMock);
export const getAllVCIEquipment = () => ajax.get(`/swap/getAllVciEquipment`, isMock);
export const getAllResEquipment = () => ajax.get(`/swap/getSwapResEquipment`, isMock);
export const getResSwapOrderStatusChange = (orderId) => ajax.get(`/swap/orderStatusChange/${orderId}`, isMock);
export const provisionResSwapEquipment = (equipment: SwapResReplacementOption) =>
  ajax.put(`/swap/swapResEquipment`, equipment, isMock);
export const validateSwapMacAddress = (macAddress: string) =>
  ajax.get(`/swap/validateMacAddress/${macAddress}`, isMock);
export const trackEquipment = (trackedEquipments: TrackedEquipmentRequest[]) =>
  ajax.put(`/equipment/track`, trackedEquipments, isMock);
export const activateVciEquipment = ({productKind, accessPoint}: ActivateVciEquipmentParams) =>
  ajax.put(
    '/swap/activateVciEquipment',
    {productKind, serialNumber: accessPoint.serialNumber, macAddress: accessPoint.macAddress},
    isMock
  );
export const validateFlow = (activationCode: string, installationSubType: string) =>
  ajax.post(`/installer/validate/installation`, {activationCode, installationSubType}, isMock);
