import styled from 'styled-components';
import {Radio, RadioGroup} from '@material-ui/core';

export const RadioBox = styled(Radio)`
  &.Mui-checked {
    color: ${({theme}) => theme.colors.TEAL_500};
  }

  &:hover {
    background-color: transparent;
    svg {
      border-color: ${({theme}) => theme.colors.TEAL_500};
    }
  }
`;
export const RadioBoxGroup = styled(RadioGroup)`
  padding-left: 8px;
`;
