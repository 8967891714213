import React from 'react';
import {Card, Text} from '@viasat/components';
import Error from '@material-ui/icons/Error';
import {FormattedMessage} from 'react-intl.macro';
import {StyledProps} from '../../../../theme/theme';
import {withTheme} from 'styled-components';

interface OwnProps {
  responsiveWidths: any;
}

const ContractContentFailure: React.FC<StyledProps & OwnProps> = ({
  theme,
  responsiveWidths,
}: StyledProps & OwnProps) => {
  return (
    <Card minHeight="350px" mt={5} {...responsiveWidths} pt={'80px'} pb={'80px'} textAlign="center">
      <Error style={{height: '140px', width: '140px', color: theme.colors.RED}} />
      <Text
        fontWeight="bold"
        fontSize={3}
        width="auto"
        display="block"
        mr="auto"
        ml="auto"
        mt={3}
        textAlign="center"
        data-test-el="status-card-text"
      >
        <FormattedMessage
          id="contract.error.verifying1"
          defaultMessage="There seems to be a problem with the agreement."
        />
      </Text>
      <Text
        fontWeight="bold"
        fontSize={3}
        width="auto"
        display="block"
        mr="auto"
        ml="auto"
        textAlign="center"
        data-test-el="status-card-text"
      >
        <FormattedMessage id="contract.error.verifying2" defaultMessage="Please try to sign again." />
      </Text>
    </Card>
  );
};

export default withTheme(ContractContentFailure);
