import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Card, Progress, Text} from '@viasat/components';

const HotspotActivationLoading: React.FunctionComponent = () => {
  return (
    <>
      <Card data-test-el="hotspot-loading-card">
        <Text textAlign="center" mt={3} mb={6} display="block">
          <FormattedMessage
            id="hotspot.activation.loading.info1"
            defaultMessage="Activating hotspot, please wait while the equipment reboots."
          />
          <br />
          <FormattedMessage id="hotspot.activation.loading.info2" defaultMessage="This may take several minutes…" />
          <Progress mb={'230px'} mt={6} ml={'auto'} mr={'auto'} />
        </Text>
      </Card>
    </>
  );
};

export default HotspotActivationLoading;
