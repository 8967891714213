import {EquipmentProductKind} from '../../util/EquipmentUtility';
import {
  serialNumber10AlphaNumeric,
  serialNumber12AlphaNumeric,
  serialNumber16AlphaNumeric,
  serialNumber8AlphaNumeric,
} from '../../util/regex';

const trackedEquipmentPageConfig = {
  [EquipmentProductKind.POS]: {
    equipment_title: {
      id: 'swap.pos.equipment.title',
      defaultMessage: 'Replace Point of Sale equipment',
    },
    equipment_text: {
      id: 'swap.pos.equipment.text',
      defaultMessage: 'Please enter the POS detail',
    },
    equipment_label: {
      id: 'swap.pos.equipment.label',
      defaultMessage: 'New Tablet serial number',
    },
    validator: {
      BR: serialNumber16AlphaNumeric,
      default: serialNumber8AlphaNumeric,
    },
    error_message_serial_number: {
      BR: {
        id: 'serial.number.16alpha.numeric.error',
        defaultMessage: 'The serial number should be 16 alpha numeric characters long',
      },
      default: {
        id: 'serial.number.8alpha.numeric.error',
        defaultMessage: 'The serial number should be 8 alpha numeric characters long',
      },
    },
  },
  [EquipmentProductKind.UPS]: {
    equipment_title: {
      id: 'swap.ups.equipment.title',
      defaultMessage: 'Replace UPS equipment',
    },
    equipment_text: {
      id: 'swap.pos.equipment.text',
      defaultMessage: 'Please enter the UPS detail',
    },
    equipment_label: {
      id: 'swap.ups.equipment.label',
      defaultMessage: 'New UPS serial number',
    },
    validator: {
      BR: serialNumber10AlphaNumeric,
      default: serialNumber12AlphaNumeric,
    },
    error_message_serial_number: {
      BR: {
        id: 'serial.number.10alpha.numeric.error',
        defaultMessage: 'The serial number should be 10 alpha numeric characters long',
      },
      default: {
        id: 'serial.number.12alpha.numeric.error',
        defaultMessage: 'The serial number should be 12 alpha numeric characters long',
      },
    },
  },
};
export default trackedEquipmentPageConfig;
