import React from 'react';
import {PageContainer, TextContainer} from '../../common/AppLayout';

import ButtonContainer from '../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl.macro';
import RestartButton from '../../common/RestartButton';
import {Button, Card, Title} from '@viasat/components';
import {SwapResReplacementOption} from '../../../store/activation/actionInterfaces';
import {FormControl, FormControlLabel} from '@material-ui/core';
import {RadioBox, RadioBoxGroup} from '../../../shared-components/RadioBox';

export interface Props {
  resEquipmentDetails: SwapResReplacementOption[];
  proceedToNextStep: (equipment: SwapResReplacementOption) => void;
}

const ResSwapEquipmentPage: React.FC<Props> = ({resEquipmentDetails, proceedToNextStep}) => {
  const [selectedEquipment, setSelectedEquipment] = React.useState();
  const handleChange = (event) => {
    setSelectedEquipment(event.target.value.trim());
  };
  const EquipmentSelectionComponent = () => {
    return (
      <>
        <Card pt={3} pb={3}>
          <TextContainer textAlign={'center'}>
            <FormattedMessage
              id="res.swap.equipment.details.intro"
              defaultMessage="Please select the equipment to be swapped:"
            />
          </TextContainer>

          <FormControl component="fieldset">
            <RadioBoxGroup
              aria-label="installedResEquipment"
              name="installedResEquipment"
              value={selectedEquipment}
              onChange={handleChange}
            >
              {resEquipmentDetails &&
                resEquipmentDetails.map((equipment) => (
                  <FormControlLabel
                    key={equipment.replacementProductTypeId}
                    value={equipment.replacementProductTypeId}
                    control={<RadioBox />}
                    label={equipment.replacementProductTypeName}
                  />
                ))}
            </RadioBoxGroup>
          </FormControl>
        </Card>
        <ButtonContainer>
          <RestartButton />
          <Button
            disabled={!selectedEquipment}
            onClick={() => {
              const equipment = resEquipmentDetails.find(
                (item) => item.replacementProductTypeId === selectedEquipment
              ) as SwapResReplacementOption;
              proceedToNextStep(equipment);
            }}
            data-test-el="swap-equipment-selection-continue-button"
          >
            <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
          </Button>
        </ButtonContainer>
      </>
    );
  };

  return (
    <PageContainer data-test-el="swap-equipment-selection-page">
      <Title data-test-el="swap-equipment-selection-title">
        <FormattedMessage id="res.swap.equipment.details.title" defaultMessage="Equipment available on site" />
      </Title>
      <EquipmentSelectionComponent />
    </PageContainer>
  );
};

export default ResSwapEquipmentPage;
