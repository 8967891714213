import {UpgradeEquipmentDetails} from '../../store/activation/actionInterfaces';

export enum EquipmentDeviceModel {
  AB_SPOKE_MODEM = 'abSpockModem',
  SB2_MODEM = 'sb2Modem',
}

export enum EquipmentProductKind {
  SI_IDU = 'SI_IDU',
  SI_ODU = 'SI_ODU',
  POS = 'TABLET',
  UPS = 'UPS',
  ACCESS_POINT = 'ACCESS_POINT',
  NETWORK_CONTROLLER = 'NETWORK_CONTROLLER',
}

export const requiresSateliteChange = (upgradeEquipmentDetails: UpgradeEquipmentDetails[]): boolean => {
  return !!(
    upgradeEquipmentDetails &&
    upgradeEquipmentDetails
      .filter((item) => {
        return item.psmProductKind === EquipmentProductKind.SI_IDU;
      })
      .find((characteristic) => {
        return characteristic.deviceModel === EquipmentDeviceModel.AB_SPOKE_MODEM;
      })
  );
};
