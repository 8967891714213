import {concat, interval, Observable, of, race, throwError, timer} from 'rxjs';
import {Action} from 'redux';
import {combineEpics, ofType, StateObservable} from 'redux-observable';
import {catchError, concatMap, filter, flatMap, map, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import {
  activateWifiAction,
  checkWifiActivationStatusAction,
  restartAction,
  submitFiveGHzDataAction,
  submitTwoPointFourGHzDataAction,
} from '../../actions';
import {persistActivationField, setApplicationFlowState} from '../../activationHelpers';
import {
  activateWifi,
  checkFiveGHzAccessPoint,
  checkTwoPointFourGHzAccessPoint,
  checkWifiActivationStatus,
} from '../../services';
import {FlowState} from '../../../../definitions/types';
import {generateMessage} from '../../../../shared-components/ErrorMessage';
import env from '../../../../utils/env';
import {handleActivationError} from '../appUtility/appUtilityEpic';
import {ActivationStatus} from '../../../../components/util/ActivationStatus';

export const twoPointFourGHzDataSubmittedEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(submitTwoPointFourGHzDataAction.started.match),
    switchMap((action) => {
      const {serialNumber, macAddress} = action.payload.accessPoint;
      return checkTwoPointFourGHzAccessPoint(serialNumber, macAddress).pipe(
        flatMap(() =>
          concat(
            of(
              submitTwoPointFourGHzDataAction.done({
                result: {},
                params: {accessPoint: action.payload.accessPoint},
              })
            ),
            of(setApplicationFlowState(FlowState.TwoPointFourGHzSubmitted))
          )
        ),
        catchError((error) => {
          return of(
            submitTwoPointFourGHzDataAction.failed({
              error: generateMessage('Could not check 2.4GHz Access Point!', 'error.ap.twopointfour.ghz', error),
              params: {accessPoint: action.payload.accessPoint},
            })
          );
        })
      );
    })
  );
export const fiveGHzDataSubmittedEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(submitFiveGHzDataAction.started.match),
    switchMap((action) => {
      const {serialNumber, macAddress} = action.payload.accessPoint;
      return checkFiveGHzAccessPoint(serialNumber, macAddress).pipe(
        flatMap(() =>
          concat(
            of(
              submitFiveGHzDataAction.done({
                result: {},
                params: {accessPoint: action.payload.accessPoint},
              })
            ),
            of(setApplicationFlowState(FlowState.FiveGHzSubmitted))
          )
        ),
        catchError((error) => {
          return of(
            submitFiveGHzDataAction.failed({
              error: generateMessage('Could not check 5Hz Network Controller!', 'error.ap.five.ghz', error),
              params: {accessPoint: action.payload.accessPoint},
            })
          );
        })
      );
    })
  );

export const activateWifiActionEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(activateWifiAction.started.match),
    switchMap((action) =>
      activateWifi().pipe(
        concatMap((response) => {
          if (response.response.success) {
            return of(
              activateWifiAction.done({
                result: {success: response.response.success},
                params: {},
              })
            );
          }

          return of(
            activateWifiAction.failed({
              error: generateMessage('Could not activate wifi!', 'error.wifi.activation'),
              params: {},
            })
          );
        }),
        catchError((error) => {
          return of(
            activateWifiAction.failed({
              error: generateMessage('Could not wifi hotspot!', 'error.wifi.activation', error),
              params: {},
            })
          );
        })
      )
    )
  );
function fetchWifiStatus() {
  return race(
    timer(0, Number(env.pollingInterval)).pipe(
      switchMap(() => checkWifiActivationStatus().pipe(catchError((err) => handleActivationError(err)))),
      skipWhile(
        (response: any) =>
          ![ActivationStatus.ACTIVE, ActivationStatus.ACTIVATING_ERROR].includes(response.response.status.toUpperCase())
      ),
      take(1),
      map((response) => ({result: {status: response.response.status}, params: {}}))
    ),
    interval(Number(env.modemActivationTimeout)).pipe(
      flatMap((err) =>
        throwError({error: err, friendlyMessage: 'Wifi activation timeout', intlId: 'error.wifi.timeout'})
      )
    )
  );
}

export const checkWifiActivationStatusEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(checkWifiActivationStatusAction.started.match),
    switchMap((action) => {
      return fetchWifiStatus().pipe(
        flatMap((response: any) => {
          if (
            response.result.status.toUpperCase() === ActivationStatus.ACTIVE ||
            response.result.status.toUpperCase() === ActivationStatus.ACTIVATING_ERROR
          ) {
            persistActivationField('applicationFlowState', FlowState.WifiActivated);
            return concat(
              of(checkWifiActivationStatusAction.done(response)),
              of(setApplicationFlowState(FlowState.WifiActivated))
            );
          }
          return of(checkWifiActivationStatusAction.done(response));
        }),
        takeUntil(action$.pipe(ofType(restartAction))),
        catchError((thrownError) => {
          return of(
            checkWifiActivationStatusAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError.error),
              params: {},
            })
          );
        })
      );
    })
  );

export const WifiEpic = combineEpics(
  activateWifiActionEpic,
  fiveGHzDataSubmittedEpic,
  twoPointFourGHzDataSubmittedEpic,
  checkWifiActivationStatusEpic
);
