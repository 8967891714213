import {Action} from 'redux';
import {concat, interval, Observable, of, race, throwError, timer} from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  flatMap,
  map,
  retryWhen,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  activateModemSwapAction,
  activateQoiAction,
  activateQoiSwapAction,
  activateVciEquipmentAction,
  checkQoiStatusAction,
  checkSwappedModemActivationStatusAction,
  getAllEquipmentByPIAction,
  getAllResEquipmentAction,
  getAllVCIEquipmentAction,
  getResSwapOrderStatusChangeAction,
  provisionResSwapEquipmentAction,
  restartAction,
  swapTrackedEquipmentAction,
  validateExistingSwapEquipmentAction,
  validateSwapMacAddressAction,
} from '../../actions';

import {
  activateModemSwap,
  activateQoiSwap,
  activateVciEquipment,
  checkSwappedModemActivationStatus,
  getAllEquipmentFromPI,
  getAllResEquipment,
  getAllVCIEquipment,
  getResSwapOrderStatusChange,
  provisionResSwapEquipment,
  trackEquipment,
  validateExistingSwapEquipment,
  validateSwapMacAddress,
} from '../../services';
import {combineEpics, ofType, StateObservable} from 'redux-observable';
import {userSnackbarAction} from '../../../../utils/CommonActions';
import env from '../../../../utils/env';
import {FlowState, IPersistedFlowState} from '../../../../definitions/types';
import {ActivationStatus} from '../../../../components/util/ActivationStatus';

import {generateErrorDescription, generateMessage} from '../../../../shared-components/ErrorMessage';
import {handleActivationError} from '../appUtility/appUtilityEpic';
import {
  storeFlowState,
  setApplicationFlowState,
  persistActivationField,
  getOngoingActivation,
} from '../../activationHelpers';
import {SwapReplacementCheckResponse} from '../../actionInterfaces';

export const activateQoiSwapEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(activateQoiSwapAction.started.match),
    switchMap((action) => {
      const macAddress = state$.value.activation.modemMacAddress;
      return activateQoiSwap(macAddress).pipe(
        concatMap((response) => {
          const {checkQoiStatus} = action.payload;
          const {commandId} = response.response;
          if (!checkQoiStatus) {
            persistActivationField('commandId', commandId);
            return of(
              activateQoiSwapAction.done({
                result: {commandId},
                params: {},
              })
            );
          } else {
            return of(
              activateQoiSwapAction.done({
                result: {commandId},
                params: {},
              }),
              checkQoiStatusAction.started({commandId, delayInMs: 0})
            );
          }
        }),
        catchError((error) =>
          of(
            activateQoiAction.failed({
              error: generateMessage('Could not start activation', 'error.activation.start', error),
              params: {},
            })
          )
        )
      );
    })
  );

export const activateResSwapModemEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(activateModemSwapAction.started.match),
    switchMap(() => {
      const macAddress = state$.value.activation.modemMacAddress;
      return activateModemSwap(macAddress).pipe(
        map((response: any) => {
          persistActivationField('modemActivationStarted', true);
          persistActivationField('productInstanceId', response.response.productInstanceId);
          return activateModemSwapAction.done({
            result: response.response,
            params: {},
          });
        }),
        catchError((error) =>
          of(
            activateModemSwapAction.failed({
              error: generateMessage('Could not start activation!', 'error.activation.start', error),
              params: {},
            })
          )
        )
      );
    })
  );

export const checkSwappedModemActivationStatusEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(checkSwappedModemActivationStatusAction.started.match),
    switchMap((action) => {
      const productInstanceId = state$.value.activation.productInstanceId as string;
      return fetchSwappedModemStatus(productInstanceId).pipe(
        map((response: any) => {
          const activationStatus = response.result.status.toUpperCase() as ActivationStatus;
          if (activationStatus === ActivationStatus.PROCESSED) {
            storeFlowState({});
            persistActivationField('applicationFlowState', FlowState.SwapDone);
          }
          return checkSwappedModemActivationStatusAction.done(response);
        }),
        takeUntil(action$.pipe(ofType(restartAction))),
        catchError((thrownError) => {
          return of(
            checkSwappedModemActivationStatusAction.failed({
              error: generateMessage(thrownError.friendlyMessage, thrownError.intlId, thrownError.error),
              params: {},
            })
          );
        })
      );
    })
  );

function fetchSwappedModemStatus(productInstanceId: string) {
  return race(
    timer(0, Number(env.pollingInterval)).pipe(
      switchMap(() =>
        checkSwappedModemActivationStatus(productInstanceId).pipe(catchError((err) => handleActivationError(err)))
      ),
      skipWhile(
        (response: any) =>
          ![ActivationStatus.PROCESSED, ActivationStatus.PROCESSING_ERROR].includes(
            response.response.status.toUpperCase()
          )
      ),
      take(1),
      map((response) => ({result: {status: response.response.status}, params: {}}))
    ),
    interval(Number(env.modemActivationTimeout)).pipe(
      flatMap((err) =>
        throwError({
          error: err,
          friendlyMessage: 'Modem Activation Timeout',
          intlId: 'error.activation.timeout',
        })
      )
    )
  );
}

export const swapTrackedEquipmentEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(swapTrackedEquipmentAction.started.match),
    switchMap((action) => {
      const {serialNumber, productKind} = action.payload;
      return trackEquipment([{serialNumber, productKind}]).pipe(
        flatMap(() =>
          concat(
            of(
              swapTrackedEquipmentAction.done({
                result: {},
                params: action.payload,
              })
            ),
            of(setApplicationFlowState(FlowState.SwapDone))
          )
        ),
        catchError((error) =>
          concat(
            of(
              swapTrackedEquipmentAction.failed({
                error: generateMessage('Could not submit equipment for tracking!', 'error.track.equipment', error),
                params: action.payload,
              })
            ),
            of(
              userSnackbarAction(
                {
                  defaultMessage: 'Could not submit equipment for tracking',
                  id: 'error.track.equipment',
                },
                {variant: 'error'},
                generateErrorDescription(error)
              )
            )
          )
        )
      );
    })
  );

export const activateVciEquipmentEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(activateVciEquipmentAction.started.match),
    switchMap((action) =>
      activateVciEquipment(action.payload).pipe(
        flatMap((response) =>
          response.response.success
            ? concat(
                of(
                  activateVciEquipmentAction.done({
                    result: {success: response.response.success},
                    params: action.payload,
                  })
                ),
                of(setApplicationFlowState(FlowState.SwapDone))
              )
            : of(
                activateVciEquipmentAction.failed({
                  error: generateMessage(
                    'Could not activate the VCI equipment',
                    'swap.error.activate',
                    response.response
                  ),
                  params: action.payload,
                })
              )
        ),
        catchError((error) => {
          return of(
            activateVciEquipmentAction.failed({
              error: generateMessage('Could not activate the  equipment', 'swap.error.activate', error),
              params: action.payload,
            })
          );
        })
      )
    )
  );
export const validateSwapMacAddressEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(validateSwapMacAddressAction.started.match),
    switchMap((action) => {
      const macAddress = state$.value.activation.modemMacAddress;
      return validateSwapMacAddress(macAddress).pipe(
        flatMap((response) => {
          persistActivationField('swapMacAddressValidated', response.response.success);
          return of(
            validateSwapMacAddressAction.done({
              result: {success: response.response.success},
              params: {},
            })
          );
        }),
        catchError((error) => {
          return of(
            validateSwapMacAddressAction.failed({
              error: generateMessage('Could not proceed with the activation', 'res.swap.macAddress.error.title', error),
              params: action.payload,
            })
          );
        })
      );
    })
  );

export const getResSwapOrderStatusChangeEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(getResSwapOrderStatusChangeAction.started.match),
    switchMap((action) =>
      getResSwapOrderStatusChange(action.payload.orderId).pipe(
        flatMap((response) =>
          response.response.success
            ? concat(
                of(
                  getResSwapOrderStatusChangeAction.done({
                    result: response.response,
                    params: action.payload,
                  })
                ),
                of(activateQoiSwapAction.started({})),
                of(setApplicationFlowState(FlowState.SwapCreated))
              )
            : of(
                getResSwapOrderStatusChangeAction.failed({
                  error: generateMessage('Could not activate the equipment', 'swap.error.activate', response.response),
                  params: action.payload,
                })
              )
        ),
        catchError((error) => {
          return of(
            getResSwapOrderStatusChangeAction.failed({
              error: generateMessage('Could not activate the equipment', 'swap.error.activate', error),
              params: action.payload,
            })
          );
        })
      )
    )
  );

export const activateResSwapEquipmentEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(provisionResSwapEquipmentAction.started.match),
    switchMap((action) =>
      provisionResSwapEquipment(action.payload).pipe(
        flatMap((response) => {
          const {orderId, isSwapCreated} = response.response;

          persistActivationField('orderId', response.response.orderId);
          if (isSwapCreated) {
            return concat(
              of(
                provisionResSwapEquipmentAction.done({
                  result: {orderId},
                  params: action.payload,
                })
              ),
              of(activateQoiSwapAction.started({})),
              of(setApplicationFlowState(FlowState.SwapCreated))
            );
          }
          return orderId
            ? concat(
                of(
                  provisionResSwapEquipmentAction.done({
                    result: {orderId},
                    params: action.payload,
                  })
                ),
                of(getResSwapOrderStatusChangeAction.started({orderId: orderId}))
              )
            : of(
                provisionResSwapEquipmentAction.failed({
                  error: generateMessage('Could not activate the equipment', 'swap.error.activate', response.response),
                  params: action.payload,
                })
              );
        }),
        catchError((error) => {
          return of(
            provisionResSwapEquipmentAction.failed({
              error: generateMessage('Could not activate the equipment', 'swap.error.activate', error),
              params: action.payload,
            })
          );
        })
      )
    )
  );

function pollForValidateSwapEquipmentCheck() {
  let count = 0;
  return validateExistingSwapEquipment().pipe(
    tap((_) => {
      count += 1;
    }),
    map((response: {response: SwapReplacementCheckResponse}) => {
      if (response.response.openOrderExists && count < Number(env.swapPollingCount)) {
        throw response.response;
      }
      return response;
    }),
    retryWhen((errors) =>
      errors.pipe(
        delay(2000),
        map((error) => {
          if (error.openOrderExists) {
            return error;
          } else {
            throw error;
          }
        })
      )
    )
  );
}

const canPerformSwapProvisioning = (ongoingActivation: IPersistedFlowState): boolean => {
  const allowedDelayTwoMinutes = 2 * 60 * 1000;
  const now = Date.now();
  const previousTime = ongoingActivation.swapOrderGenerationTimestamp || 0;
  return now - previousTime > allowedDelayTwoMinutes;
};

export const validateExistingSwapEquipmentEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(validateExistingSwapEquipmentAction.started.match),
    switchMap((action) =>
      pollForValidateSwapEquipmentCheck().pipe(
        flatMap((response) => {
          const ongoingActivation = getOngoingActivation();
          const replacementCheckResponse = response.response as SwapReplacementCheckResponse;

          if (
            !ongoingActivation.orderId &&
            !replacementCheckResponse.openOrderExists &&
            replacementCheckResponse.replacementProductExists === false
          ) {
            if (canPerformSwapProvisioning(ongoingActivation)) {
              persistActivationField('swapOrderGenerationTimestamp', Date.now());
              return concat(
                of(validateExistingSwapEquipmentAction.done({result: response.response, params: action.payload})),
                of(provisionResSwapEquipmentAction.started(action.payload))
              );
            } else {
              return of(
                validateExistingSwapEquipmentAction.failed({
                  error: generateMessage(
                    'An order has already been generated. If you wish to retry the swap provisioning please wait 2 minutes.',
                    'error.failure.validate.equipment.replacement.body'
                  ),
                  params: action.payload,
                })
              );
            }
          } else if (!replacementCheckResponse.openOrderExists && replacementCheckResponse.replacementProductExists) {
            return concat(
              of(validateExistingSwapEquipmentAction.done({result: response.response, params: action.payload})),
              of(activateQoiSwapAction.started({})),
              of(setApplicationFlowState(FlowState.SwapCreated))
            );
          } else {
            return of(validateExistingSwapEquipmentAction.done({result: response.response, params: action.payload}));
          }
        }),
        catchError((error) => {
          return of(
            validateExistingSwapEquipmentAction.failed({
              error: generateMessage(
                'Could not find open order for the selected equipment yet. Please retry later.',
                'error.validate.equipment.replacement',
                error
              ),
              params: action.payload,
            })
          );
        })
      )
    )
  );

export const getAllEquipmentByPIEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(getAllEquipmentByPIAction.started.match),
    switchMap((action) =>
      getAllEquipmentFromPI().pipe(
        map((response: any) => {
          return getAllEquipmentByPIAction.done({result: response.response, params: {}});
        }),
        catchError((error) => {
          return of(
            getAllEquipmentByPIAction.failed({
              error: generateMessage('Could not retrieve installed devices details', 'error.equipment.details', error),
              params: action.payload,
            })
          );
        })
      )
    )
  );

export const getAllVCIEquipmentEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(getAllVCIEquipmentAction.started.match),
    switchMap((action) =>
      getAllVCIEquipment().pipe(
        map((response: any) => {
          return getAllVCIEquipmentAction.done({result: response.response, params: {}});
        }),
        catchError((error) => {
          return of(
            getAllVCIEquipmentAction.failed({
              error: generateMessage('Could not retrieve installed devices details', 'error.equipment.details', error),
              params: action.payload,
            })
          );
        })
      )
    )
  );

export const getAllResEquipmentEpic = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(getAllResEquipmentAction.started.match),
    switchMap((action) =>
      getAllResEquipment().pipe(
        map((response: any) => {
          return getAllResEquipmentAction.done({result: response.response, params: {}});
        }),
        catchError((error) => {
          return of(
            getAllResEquipmentAction.failed({
              error: generateMessage('Could not retrieve installed devices details', 'error.equipment.details', error),
              params: action.payload,
            })
          );
        })
      )
    )
  );
export const SwapEpic = combineEpics(
  getAllResEquipmentEpic,
  getAllVCIEquipmentEpic,
  getAllEquipmentByPIEpic,
  activateResSwapEquipmentEpic,
  validateSwapMacAddressEpic,
  activateVciEquipmentEpic,
  swapTrackedEquipmentEpic,
  checkSwappedModemActivationStatusEpic,
  activateResSwapModemEpic,
  activateQoiSwapEpic,
  getResSwapOrderStatusChangeEpic,
  validateExistingSwapEquipmentEpic
);
