import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import SwapEquipmentSelectionPage from './swapEquipmentSelectionPage';
import {getAllVCIEquipmentAction, resetErrorAction} from '../../../store/activation/actions';
import {setApplicationFlowState, setSelectedSwapEquipment} from '../../../store/activation/activationHelpers';
import Error from '../../common/Error';
import LoaderComponent from '../../common/LoaderComponent';
import {FlowState} from '../../../definitions/types';

import {EquipmentProductKind} from '../../util/EquipmentUtility';

const productKindByFlowState = {
  [EquipmentProductKind.POS]: FlowState.SwapPOS,
  [EquipmentProductKind.UPS]: FlowState.SwapUPS,
  [EquipmentProductKind.ACCESS_POINT]: FlowState.SwapAccessPoint,
  [EquipmentProductKind.NETWORK_CONTROLLER]: FlowState.SwapNetworkController,
};

const SwapEquipmentSelectionPageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    getAllVciEquipment: () => dispatch(getAllVCIEquipmentAction.started({})),
    resetError: () => dispatch(resetErrorAction()),
    proceedToNextStep: (selectedProductKind: EquipmentProductKind) => {
      const flowState = productKindByFlowState[selectedProductKind];
      dispatch(setSelectedSwapEquipment({selectedProductKind}));
      return dispatch(setApplicationFlowState(flowState) as any);
    },
  };
  const stateProps = useSelector((state: any) => {
    return {
      isBusy: state.activation.isBusy,
      error: state.activation.error,
      vciEquipmentDetails: state.activation.installedVCIEquipmentDetails,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };

  useEffect(() => {
    props.getAllVciEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [1]);
  const retry = () => {
    props.getAllVciEquipment();
    props.resetError();
  };
  if (props.isBusy || (!props.vciEquipmentDetails && !props.error)) {
    return <LoaderComponent />;
  }

  if (props.error) {
    return <Error message={props.error} enableReload={true} onReload={retry} />;
  }

  return <SwapEquipmentSelectionPage {...props} />;
};
export default SwapEquipmentSelectionPageContainer;
