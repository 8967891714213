// based on https://system-ui.com/theme/
// and https://styled-system.com/theme-specification/

/*************************************************************
 * IMPORTANT: theme should always contain *abstract* theme related tokens,
 * for example do not create a token like ERROR, BACKGROUND_GREEN, or LABEL etc,
 * instead LARGE, SMALL, BOLD, BLUE, RED etc
 * ************************************************************/

export interface StyledProps {
  theme: Theme;
  [key: string]: any;
}

export interface Theme {
  breakpoints: string[];
  space: Space;
  fonts: Fonts;
  fontSizes: number[];
  fontWeights: FontWeights;
  lineHeights: LineHeights;
  letterSpacings: LetterSpacings;
  borders: Array<number | string>;
  radii: Array<number | string>;
  sizes: number[];
  maxWidths: number[];
  colors: Colors;
  muiBackdrop: any;
}

export interface FontWeights {
  [index: number]: number;
  // aliases
  body?: number;
  heading?: number;
  bold?: number;
}

export interface Space {
  [index: number]: number;
  // aliases
  small?: number;
  medium?: number;
  large?: number;
  default?: number;
}

export interface Fonts {
  sansSerif: string;
  serif: string;
  mono: string;

  // aliases
  body?: string;
  heading?: string;
  monospace?: string;
}

export interface LineHeights {
  solid: number;
  title: number;
  copy: number;
  // aliases
  body?: number;
  heading?: number;
}

export interface LetterSpacings {
  normal: string;
  tracked: string;
  tight: string;
  mega: string;
}

export interface Colors {
  GRAY_100: string;
  GRAY_200: string;
  GRAY_300: string;
  GRAY_400: string;
  GRAY_500: string;
  GRAY_600: string;
  GRAY_700: string;
  GRAY_800: string;
  BLUE_100: string;
  BLUE_200: string;
  BLUE_300: string;
  BLUE_400: string;
  BLUE_500: string;
  BLUE_600: string;
  BLUE_700: string;
  BLUE_800: string;
  TEAL_100: string;
  TEAL_200: string;
  TEAL_300: string;
  TEAL_400: string;
  TEAL_500: string;
  TEAL_600: string;
  TEAL_700: string;
  TEAL_800: string;
  GREEN_100: string;
  GREEN_200: string;
  GREEN_300: string;
  GREEN_400: string;
  GREEN_500: string;
  GREEN_600: string;
  GREEN_700: string;

  // custom non-pallete colors
  LIGHT_GREEN: string;
  LIGHTEST_GREEN: string;
  LIGHT_RED: string;
  LIGHT_GRAY: string;
  RED: string;
  WHITE: string;
  BLACK: string;
  GRAY: string;
  MID_GRAY: string;
  DARK_GRAY: string;
  TRANSPARENT: string;
  BLACKS: string[];
  WHITES: string[];

  // aliases
  text?: string;
  background?: string;
  primary?: string;
  secondary?: string;
  accent?: string;
  muted?: string;
}

/*
  Primary global theme design tokens
 */

export const theme: Theme = {
  breakpoints: ['360px', '600px', '960px', '1280px', '1920px'],

  space: [0, 4, 8, 16, 24, 32, 40, 64, 128, 256, 512],

  fonts: {
    serif: '"PT Sans", georgia, times, serif',
    mono: 'monospace',
    sansSerif: '"Source Sans Pro", sans-serif',
  },
  muiBackdrop: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 64],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },

  borders: [0, '1px solid', '2px solid', '3px solid', '4px solid', '8px solid', '16px solid', '32px solid'],

  radii: [0, 2, 4, 6, 8, 16, 20, 9999, '100%'],
  sizes: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],

  /* 
    Color design tokens, based on:
    https://viasat.invisionapp.com/d/main#/console/14688782/305457119/preview
  */

  colors: {
    GRAY_100: '#F2F5F8',
    GRAY_200: '#DEE4E8',
    GRAY_300: '#C3CDD5',
    GRAY_400: '#9FAFBC',
    GRAY_500: '#677A89',
    GRAY_600: '#465967',
    GRAY_700: '#32424E',
    GRAY_800: '#202E39',

    BLUE_100: '#CCF0FF',
    BLUE_200: '#9BE1FF',
    BLUE_300: '#64CEFB',
    BLUE_400: '#14B9FF',
    BLUE_500: '#009DE0',
    BLUE_600: '#006EAD',
    BLUE_700: '#00528C',
    BLUE_800: '#003F73',

    TEAL_100: '#B6FBF8',
    TEAL_200: '#8DF2ED',
    TEAL_300: '#61DED8',
    TEAL_400: '#3EC1BE',
    TEAL_500: '#0AA5AB',
    TEAL_600: '#008085',
    TEAL_700: '#006366',
    TEAL_800: '#014656',

    GREEN_100: '#E5F395',
    GREEN_200: '#D8EC74',
    GREEN_300: '#BED733',
    GREEN_400: '#8DC002',
    GREEN_500: '#5EA215',
    GREEN_600: '#358000',
    GREEN_700: '#1D5700',

    LIGHTEST_GREEN: '#7DC300',
    LIGHT_GREEN: '#EBF5F5',
    LIGHT_RED: '#FCEDEC',
    RED: '#FF4747',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    GRAY: '#DFE4E7',
    MID_GRAY: '#637a8b',
    DARK_GRAY: '#5B7789',
    LIGHT_GRAY: '#C1CDD6',

    TRANSPARENT: 'transparent',

    BLACKS: [
      'rgba(0,0,0,.0125)',
      'rgba(0,0,0,.025)',
      'rgba(0,0,0,.05)',
      'rgba(0,0,0,.1)',
      'rgba(0,0,0,.2)',
      'rgba(0,0,0,.3)',
      'rgba(0,0,0,.4)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.6)',
      'rgba(0,0,0,.7)',
      'rgba(0,0,0,.8)',
      'rgba(0,0,0,.9)',
    ],
    WHITES: [
      'rgba(255,255,255,.0125)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.9)',
    ],
  },
};

/*
  Aliases - i.e. useful alias tokens to refer to primary tokens (for component convenience/readability)
*/

const {fonts} = theme;
fonts.body = fonts.sansSerif; // default body font family
fonts.heading = fonts.serif; // default heading font family
fonts.monospace = fonts.mono; // default monospace font family for <pre>, <code>, etc.

const {fontWeights} = theme;
fontWeights.body = fontWeights[4]; // body font weight
fontWeights.heading = fontWeights[8]; // default heading font weight
fontWeights.bold = fontWeights[8]; // default bold font weight

const {lineHeights} = theme;
lineHeights.body = lineHeights.solid; // body line height
lineHeights.heading = lineHeights.title; // default heading line height

const {space} = theme;
space.small = space[1];
space.medium = space[2];
space.large = space[3];

const {colors} = theme;
colors.text = colors.GRAY_700; // Body foreground color - e.g. <MyComponent color="text" />
colors.background = colors.WHITE; // Body background color
colors.primary = colors.TEAL_500; // Primary brand color for links, buttons, etc.
colors.secondary = colors.GRAY_100; // A secondary brand color for alternative styling
colors.accent = colors.TEAL_500; // A contrast color for emphasizing UI
colors.muted = colors.GRAY_100; // 	A faint color for backgrounds, borders, and accents that do not require high contrast with the background color
