import React from 'react';
import {Button, Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import styled from 'styled-components';
import {SpaceProps, ColorProps, TypographyProps, space, color, fontSize} from 'styled-system';
import {connect} from 'react-redux';
import {restartAction} from '../../store/activation/actions';

interface OwnProps {
  className?: string;
}
interface DispatchProps {
  restart: () => void;
}

type Props = OwnProps & ColorProps & SpaceProps & TypographyProps & DispatchProps;

const AuthorizationErrorPage: React.FC<Props> = ({className, restart}) => {
  return (
    <div className={className} data-test-el="authorization-error">
      <div>
        <Title variant={'h2'}>
          <FormattedMessage id="authorization.token.expired.title" defaultMessage="Session expired" />
        </Title>
        <ErrorMessage>
          <FormattedMessage
            id="authorization.token.expired.text"
            defaultMessage=" Your session has expired, you will be redirected to the login page."
          />
        </ErrorMessage>
        <Button variant="primary" onClick={restart}>
          <FormattedMessage id="authorization.token.expired.button" defaultMessage="OK" />
        </Button>
      </div>
    </div>
  );
};

const AuthorizationErrorPageStyled = styled(AuthorizationErrorPage)<Props & SpaceProps>`
  ${space}
  ${color}
  ${fontSize}
  display: flex;
  align-items: center;
  justify-content: center;
`;

AuthorizationErrorPageStyled.defaultProps = {
  m: 5,
};

const ErrorMessage = styled.div`
  word-wrap: break-word;
  margin: ${({theme}) => theme.space[3]}px 0;
`;

export default connect(
  null,
  (dispatch): DispatchProps => ({
    restart: () => dispatch(restartAction()),
  })
)(AuthorizationErrorPageStyled as any);
