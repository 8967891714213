import {ContractEpic} from './contract/contractEpic';
import {InstallerEpic} from './installer/installerEpic';
import {WifiEpic} from './wifi/wifiEpic';
import {HotspotEpic} from './hotspot/hotspotEpic';
import {combineEpics} from 'redux-observable';
import {CustomerEpic} from './customer/customerEpic';
import {EquipmentTrackingEpic} from './equipment/equipmentEpic';
import {SwapEpic} from './swap/swapEpic';
import {VoipEpic} from './voip/voipEpic';
import {PaymentEpic} from './payment/paymentEpic';
import {AppUtilityEpic} from './appUtility/appUtilityEpic';
import {InstallationEpic} from './installation/installationEpic';

export const ActivationEpics = combineEpics(
  ContractEpic,
  CustomerEpic,
  InstallerEpic,
  WifiEpic,
  HotspotEpic,
  EquipmentTrackingEpic,
  SwapEpic,
  VoipEpic,
  PaymentEpic,
  AppUtilityEpic,
  InstallationEpic
);
