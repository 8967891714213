import {Button, Text} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import React from 'react';

const responsiveDisplay = {display: ['none', 'none', 'inline-block', 'inline-block', 'inline-block']} as any;
export interface IframeModalHeaderProps {
  footerItems?: React.FC<any>;
  closeModal: () => void;
  modalInfoText: {
    id: string;
    defaultMessage: string;
  };
  closeButtonText: {
    id: string;
    defaultMessage: string;
  };
}

export const IframeModalHeader = ({closeModal, closeButtonText, modalInfoText}: IframeModalHeaderProps) => {
  return (
    <div>
      <Text {...responsiveDisplay} ml={0} mr={3} variant="secondary" fontSize={[0, 1, 2, 2, 2]}>
        <FormattedMessage id={modalInfoText.id} defaultMessage={modalInfoText.defaultMessage} />
      </Text>
      <Button onClick={closeModal} data-test-el="close-modal-button">
        <FormattedMessage id={closeButtonText.id} defaultMessage={closeButtonText.defaultMessage} />
      </Button>
    </div>
  );
};
