import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {swapTrackedEquipmentAction} from '../../../store/activation/actions';
import {EquipmentProductKind} from '../../util/EquipmentUtility';
import SwapTrackedEquipmentPage from './swapTrackedEquipmentPage';

const SwapTrackedEquipmentContainer: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchProps = {
    trackEquipment: (serialNumber: string, productKind: EquipmentProductKind) =>
      dispatch(swapTrackedEquipmentAction.started({serialNumber, productKind}) as any),
  };
  const stateProps = useSelector((state: any) => {
    return {
      isBusy: state.activation.isBusy,
      error: state.activation.error,
      selectedEquipment: state.activation.selectedProductKind,
      country: state.system.country,
    };
  });
  const props = {
    ...stateProps,
    ...dispatchProps,
  };
  return <SwapTrackedEquipmentPage {...props} />;
};

export default SwapTrackedEquipmentContainer;
