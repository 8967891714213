import {EMPTY, Observable, of, throwError} from 'rxjs';
import {Action} from 'redux';
import {combineEpics} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {receiveInitActivation, restartAction} from '../../actions';
import {FlowState} from '../../../../definitions/types';
import {generateErrorDescription} from '../../../../shared-components/ErrorMessage';
import {storeFlowState} from '../../activationHelpers';

export function handleActivationError(err: any) {
  if (!window.navigator.onLine) {
    return of(generateErrorDescription(err));
  } else if ([0, 503, 504].includes(err.status)) {
    return EMPTY;
  }

  return throwError({error: err, friendlyMessage: 'Could not activate', intlId: 'error.activation'});
}
export const restartEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(restartAction.match),
    map((action) => {
      storeFlowState({applicationFlowState: FlowState.NotAuthorized});
      return receiveInitActivation({applicationFlowState: FlowState.NotAuthorized});
    })
  );
export const AppUtilityEpic = combineEpics(restartEpic);
